import React from 'react';
import history from 'utils/history';
import { Button, Row, Text, LJLogo, Form } from 'components/';

const PasswordResetConfirm = ({ setCurrentView, clearEmail }) => {
  return (
    <Form className="center-xs">
      <Row className="password-reset__success-msg-container">
        <LJLogo className="logo center" height="44px" />
        <Text
          className="password-reset__success-msg-container__text"
          content="Your password has been sucessfully reset"
        />
      </Row>
      <Row className="password-reset__success-footer">
        <Button
          text="Back to Login "
          name="back-to-login"
          onClick={() => {
            history.push('/login');
            setCurrentView('');
            clearEmail();
          }}
          variant="primary-bright"
          full
        />
      </Row>
    </Form>
  );
};

export default PasswordResetConfirm;
