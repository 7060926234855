/* eslint-disable react/jsx-pascal-case */

import React, { Fragment, useEffect, useState, lazy } from 'react';
import { connect } from 'react-redux';

import { hasUserSeenAlert, setAlertViewedStatus } from 'utils/storage';
import { getAssetsRequest } from 'reducers/availability';

import {
  PageHeader,
  DocumentTitle,
  CenterWithMargin,
  Text,
  ExternalLink,
  LazyComponent
} from 'components';
import { Messages } from 'features';

import {
  AvailabilityErrorComponent,
  AvailabilitySkeleton,
  AvailabilityErrorBoundary
} from './ui-states';

import {
  ASSET_AVAILABLE,
  ASSET_UNAVAILABLE,
  ASSET_MUST_RUN
} from './constants';

const AssetControls = lazy(() => import('./asset-controls/AssetControls'));
const TimelineContainer = lazy(() => import('./timeline/TimelineContainer'));

const SubHeading = () => (
  <>
    <Text content="View a consolidated summary of your assets’ availability. For guidance read our" />
    &nbsp;
    <ExternalLink url="https://portal-resources.limejump.com/Help+Center+v2.pdf#page=6">
      Asset Availability Guide
    </ExternalLink>
    <Text content="." />
  </>
);

const AvailabilityWrapper = ({
  children,
  isNotificationShowing,
  setisNotificationShowing,
  setAlertViewedStatus,
  alert: Alert
}) => (
  <Fragment>
    <DocumentTitle title="Availability" />

    {isNotificationShowing && (
      <Alert.content
        close={() => {
          setisNotificationShowing(false);
          setAlertViewedStatus(true);
        }}
      />
    )}
    <PageHeader title="Asset Availability" subheading={<SubHeading />} />
    <CenterWithMargin>{children}</CenterWithMargin>
  </Fragment>
);

const AvailabilityViewOnly = ({
  errorFetchingAssetList,
  getsAssets,
  currentCompanyId
}) => {
  const [isNotificationShowing, setisNotificationShowing] = useState(false);
  const Alert = Messages.VIEW_ONLY_ASSET_LAUNCH;
  useEffect(() => {
    getsAssets();

    const shouldShowAlert = !hasUserSeenAlert(Alert.name);
    if (isNotificationShowing !== shouldShowAlert) {
      setisNotificationShowing(shouldShowAlert);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyId]);

  return (
    <AvailabilityWrapper
      isNotificationShowing={isNotificationShowing}
      setAlertViewedStatus={setAlertViewedStatus}
      setisNotificationShowing={setisNotificationShowing}
      alert={Alert}
    >
      {errorFetchingAssetList ? (
        <AvailabilityErrorComponent />
      ) : (
        <Fragment>
          <LazyComponent
            fallbackComp={AvailabilitySkeleton}
            errorBoundary={AvailabilityErrorBoundary}
          >
            <AssetControls
              statusList={Array.of(
                ASSET_AVAILABLE,
                ASSET_MUST_RUN,
                ASSET_UNAVAILABLE
              )}
            />
            <TimelineContainer />
          </LazyComponent>
        </Fragment>
      )}
    </AvailabilityWrapper>
  );
};

const mapStateToProps = state => {
  const { currentCompanyId } = state.auth;
  const { isLoadingAssetList, errorFetchingAssetList } = state.availability;

  return {
    errorFetchingAssetList,
    currentCompanyId,
    isLoadingAssetList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getsAssets: () => dispatch(getAssetsRequest())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailabilityViewOnly);
