import React from 'react';
import PropTypes from 'prop-types';

const TableHeader = ({ headerGroups }) => {
  return (
    <thead className="lj-table__head">
      {headerGroups.map(headerGroup => (
        <tr
          className="lj-table__head-row"
          {...headerGroup.getHeaderGroupProps()}
        >
          {headerGroup.headers.map(column => (
            <th className="lj-table__head-cell" {...column.getHeaderProps()}>
              {column.render('Header')}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

TableHeader.propTypes = {
  headerGroups: PropTypes.arrayOf(
    PropTypes.shape({
      getFooterGroupProps: PropTypes.func,
      getHeaderGroupProps: PropTypes.func,
      headers: PropTypes.arrayOf(
        PropTypes.shape({
          Cell: PropTypes.func,
          Footer: PropTypes.func,
          Header: PropTypes.string.isRequired,
          accessor: PropTypes.func,
          canResize: PropTypes.bool,
          depth: PropTypes.number,
          getFooterProps: PropTypes.func,
          getHeaderProps: PropTypes.func,
          getToggleHiddenProps: PropTypes.func,
          id: PropTypes.string.isRequired,
          isVisible: PropTypes.bool,
          maxWidth: PropTypes.number,
          minWidth: PropTypes.number,
          originalWidth: PropTypes.number,
          parent: PropTypes.string,
          render: PropTypes.func,
          sortDescFirst: PropTypes.bool,
          sortType: PropTypes.string,
          toggleHidden: PropTypes.func,
          totalFlexWidth: PropTypes.number,
          totalLeft: PropTypes.number,
          totalMaxWidth: PropTypes.number,
          totalMinWidth: PropTypes.number,
          totalVisibleHeaderCount: PropTypes.number,
          totalWidth: PropTypes.number,
          width: PropTypes.number
        })
      )
    })
  ).isRequired
};

export default TableHeader;
