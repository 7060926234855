/* eslint-disable react/jsx-pascal-case */

import React from 'react';
import { connect } from 'react-redux';

import { closeAlert } from 'reducers/notifications';

import { Alert } from 'components';
import Messages from './Messages';

const GlobalAlert = ({ show, close, alertName }) => {
  const AlertToDisplay = Messages[alertName];

  if (!alertName) return null;

  return (
    show && (
      <Alert variant="jazzy" close={close} floating={false}>
        <AlertToDisplay.content />
      </Alert>
    )
  );
};

const mapStateToProps = ({ notifications }) => ({
  show: notifications.showAlert,
  alertName: notifications.alertName
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(closeAlert())
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalAlert);
