import React from 'react';
import history from 'utils/history';
import { Button, Row, Text, LJLogo, Form } from 'components/';

const PasswordRequestSuccess = ({ email, setCurrentView, clearEmail }) => {
  return (
    <Form className=" center-xs">
      <Row className="password-reset__requestSuccess-msg-container">
        <LJLogo className="logo center" height="44px" />
        <Text
          content="Done!"
          className="password-reset__requestSuccess-msg-done"
        />
        <Text
          data-test-id="password-rest-link-msg"
          content="Password reset link sent"
        />
        <Text content={`to: ${email}`} />
      </Row>
      <Row className="password-reset__requestSuccess-footer">
        <Button
          text="Return to login "
          name="back-to-login"
          onClick={() => {
            history.push('/login');
            setCurrentView('');
            clearEmail();
          }}
          variant="primary-bright"
          full
        />
      </Row>
    </Form>
  );
};

export default PasswordRequestSuccess;
