import React, { Fragment } from 'react';

import {
  InterstitialError,
  Skeleton,
  Row,
  Col,
  ErrorBoundary
} from 'components';

import { createRange } from 'utils/helpers';

export const AvailabilityErrorComponent = () => {
  return (
    <InterstitialError
      title="Sorry, something went wrong on our end"
      message="We couldn’t fetch your assets."
      subMessage=" Please try again later"
      buttonText="Reload Page"
      buttonName="button-assets-list-unavailable"
      buttonOnClick={() => window.location.reload()}
    />
  );
};

export const TimelineSkeleton = () =>
  createRange(7).map(item => (
    <Row key={item} className="availability-timeline--loading">
      <Col modifier="col-xs-2">
        <Skeleton width="100%" height="20px" />
      </Col>
      <Col modifier="col-xs-10">
        <Skeleton width="100%" height="20px" />
      </Col>
    </Row>
  ));

export const AvailabilitySkeleton = () => (
  <Fragment>
    <section className="availability-controls">
      <Row style={{ marginBottom: '5px' }}>
        <Col modifier="col-xs-12 col-sm-12">
          <Skeleton width="25%" height="20px" />
        </Col>
      </Row>
      <Row style={{ marginBottom: '24px' }}>
        <Col modifier="col-xs-12 col-sm-12">
          <Skeleton width="50%" height="36px" />
        </Col>
      </Row>
      <Row>
        <Col modifier="col-xs-12 col-sm-8">
          <Skeleton width="50%" height="20px" />
        </Col>
        <Col modifier="col-xs-12 col-sm-4">
          <Skeleton width="100%" height="20px" />
        </Col>
      </Row>
    </section>
    <section className="availability-timeline">
      <TimelineSkeleton />
    </section>
  </Fragment>
);

export const AvailabilityErrorBoundary = ({ children }) => (
  <ErrorBoundary errorComponent={AvailabilityErrorComponent}>
    {children}
  </ErrorBoundary>
);
