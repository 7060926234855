import React, { Fragment } from 'react';

import { Helmet } from 'react-helmet';

import SelectCompanyPage from 'features/company-select';

function SelectCompany(props) {
  return (
    <Fragment>
      <Helmet>
        <title>Select Company</title>
      </Helmet>
      <SelectCompanyPage {...props} />
    </Fragment>
  );
}

export default SelectCompany;
