import React from 'react';
import { Redirect } from 'react-router-dom';

import { getAuthTokenCookie } from 'utils/storage';
import { validateAuthAndGetUserData, getPermittedRoutes } from 'reducers/auth';
import store from 'app-store';
import { getCurrentCompanyId, getCompany } from 'reducers/selectors';

import { ErrorPage } from 'components';

/**
 * This function creates a guard object
 * @param {boolean} - the result of a function which performs a boolean check
 * @param {function} - a function which performs a side effect such as storing data or calling an API
 * @param {React component } - the compontent to display in the event of an error
 * @return {object } - the guard object
 */
export const createGuard = (
  shouldCheckProceed,
  sideEffect,
  errorComp,
  name
) => ({
  shouldCheckProceed,
  sideEffect,
  errorComp,
  name: name || 'unknown guard'
});

/**
 * This function fetches the user's data and updates the Redux store
 * @return {function} The executed async function which updates the Redux store
 */
export const validateUserAuth = () => validateAuthAndGetUserData(store);

export const isAuthTokenPresent = () => !!getAuthTokenCookie();

//
// All guards have a boolean check, and can also fire actions. If all guards and actions pass the route is rendered.
//

/**
 * Checks if the current user is authenticated and they should be able to view the target route as that route's company
 * @param {Number} companyId
 * @param {*} store
 */
export function* isAuthAndPathValid(companyId, path, store) {
  // This guard authenticates the user
  yield createGuard(
    isAuthTokenPresent(),
    validateUserAuth,
    <Redirect to="/login" />,
    'is auth present and valid'
  );

  // This guard checks that the URL has a valid company ID
  yield createGuard(
    !!companyId,
    null,
    <Redirect to="/select" />,
    'does url have company id'
  );

  const targetCompany = getCompany(companyId, store.getState());

  if (!targetCompany) {
    yield createGuard(
      false,
      null,
      <Redirect to="/404" />,
      'fail and go to 404'
    );
    return;
  }

  const validatePermittedRoutes = async () => {
    const { permittedRoutes } = await getPermittedRoutes(store, companyId);
    const canViewRoute = permittedRoutes.some(r => r.path === path);
    if (!canViewRoute) {
      throw new Error('No explicit permissions to view this route');
    }
  };

  if (!targetCompany?.permittedRoutes) {
    yield createGuard(
      true,
      validatePermittedRoutes,
      <Redirect to={`/${targetCompany.id}/overview`} />,
      'Do permitted routes exist for this route'
    );
    return;
  }

  // This guard checks that company in the URL can view the selected page
  const guardSuccess =
    targetCompany &&
    targetCompany.permittedRoutes &&
    targetCompany.permittedRoutes.some(r => {
      return r.path === path;
    });

  if (!guardSuccess) {
    console.error(
      `Page guard isAuthAndPathValidGuard failed with companyId: ${companyId} and path ${path}`
    );
  }

  yield createGuard(
    guardSuccess,
    null,
    <Redirect to={`/${targetCompany.id}/overview`} />,
    'succeed and go to overview page'
  );
}

/**
 * Creates a guard which updates the store's user info if the user is authenticated
 */
export function* isAuth() {
  yield createGuard(
    isAuthTokenPresent(),
    validateUserAuth,
    <Redirect to="/login" />,
    'is auth present and valid'
  );
}

/**
 * Redirects the user to either a public 404 page if not authenticated or the current company has not been set, with a link to the login page.
 * Alternatively, if the current company has been set and the user is authenticated, it directs the user to the overview page.
 * @param {*} store
 * @returns A failing guard with error component
 */
export function* handleInvalidPage(store) {
  const state = store.getState();
  const currentCompanyId = getCurrentCompanyId(state);
  if (isAuthTokenPresent() && currentCompanyId) {
    yield createGuard(
      false,
      null,
      <ErrorPage type="404" redirectLink={`/${currentCompanyId}/overview`} />,
      'handle invalid page'
    );
    return;
  }

  yield createGuard(
    false,
    null,
    <ErrorPage type="404-public" />,
    'fail and go to 404-public'
  );
}
