import React, { Fragment, lazy } from 'react';

import {
  PageHeader,
  DocumentTitle,
  CenterWithMargin,
  LazyComponent
} from 'components';

import {
  AssetPropertiesSkeleton,
  AssetPropertiesErrorBoundary
} from './ui-states';

const AssetDetails = lazy(() => import('./AssetDetails'));
const AssetPropertiesDropdown = lazy(() => import('./AssetPropertiesDropdown'));

const AssetProperties = () => {
  return (
    <Fragment>
      <DocumentTitle title="Asset Properties" />

      <PageHeader
        title="Asset Properties"
        subheading="View the properties associated with your Dispatch assets."
      />
      <CenterWithMargin>
        <LazyComponent
          fallbackComp={AssetPropertiesSkeleton}
          errorBoundary={AssetPropertiesErrorBoundary}
        >
          <AssetPropertiesDropdown />
          <AssetDetails />
        </LazyComponent>
      </CenterWithMargin>
    </Fragment>
  );
};

export default AssetProperties;
