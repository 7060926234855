import React from 'react';
import { Text, RequestError } from 'components';

const NewsError = () => (
  <div className="col-xs-12">
    <div className="overview-error">
      <Text tag="h3" className="overview__news__item-category" content="News" />
      <RequestError message="Couldn't fetch your news" />
    </div>
  </div>
);

export default NewsError;
