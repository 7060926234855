import React, { useState } from 'react';
import history from 'utils/history';

import {
  Button,
  Row,
  Label,
  FormError,
  Input,
  Text,
  Form,
  Col,
  LJLogo,
  FormGroup
} from 'components';

const ChooseNewPassword = props => {
  const {
    handleChangePassword1,
    handleChangePassword2,
    password1,
    password2,
    resetPassword,
    passwordMatchError,
    error,
    uid,
    token,
    setCurrentView
  } = props;

  const [showLengthError, setShowLengthError] = useState(false);

  const checkPassword = (password1, password2, uid, token) => {
    if (password1.length < 8) return setShowLengthError(true);

    setShowLengthError(false);

    if (password1 === password2) {
      return resetPassword(password1, password2, uid, token);
    }

    return passwordMatchError();
  };

  return (
    <Form
      className="center-xs"
      onSubmit={event => {
        event.preventDefault();
        checkPassword(password1, password2, uid, token);
      }}
    >
      <Row className="password-reset__choose-msg-container">
        <LJLogo className="logo center" height="44px" />
        <Text
          className="password-reset__choose-msg-container__text"
          content="Reset your password below"
        />
        {error && <FormError variant="dark" error={error} />}
        {showLengthError && (
          <FormError
            variant="dark"
            error="Please choose a password longer than 8 characters"
          />
        )}
      </Row>
      <FormGroup>
        <Label
          htmlFor="password-1"
          text=" Password"
          id="password-label"
          variant="dark"
        />

        <Input
          id="password-1"
          value={password1}
          type="password"
          required
          onChange={event => handleChangePassword1(event.target.value)}
          variant="dark"
          autocomplete="new-password"
        />
      </FormGroup>
      <FormGroup>
        <Label
          htmlFor="password-2"
          text="Confirm your password"
          id="password-label"
          variant="dark"
        />
        <Input
          id="password-2"
          value={password2}
          type="password"
          onChange={event => handleChangePassword2(event.target.value)}
          required
          variant="dark"
          autocomplete="new-password"
        />
      </FormGroup>
      <Row
        modifier="between-xs middle-xs"
        className="password-reset__choose-footer"
      >
        <Col modifier="col-xs-6">
          <Button
            text="Back to Login"
            name="back-to-login"
            onClick={() => {
              history.push('login');
              setCurrentView('');
            }}
            variant="tertiary-light"
          />
        </Col>
        <Col modifier="col-xs-6">
          <Button
            text="Reset Password"
            name="reset-password"
            type="submit"
            variant="primary-bright"
            full
          />
        </Col>
      </Row>
    </Form>
  );
};

export default ChooseNewPassword;
