import React from 'react';
import { Skeleton, Row } from 'components';

const NewsLoading = () => (
  <Row tag="section">
    <Skeleton width="100%" height="192px" />
  </Row>
);

export default NewsLoading;
