import { Component } from 'react';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return this.props.errorComponent;
    }

    if (!this.props.children) {
      console.error('CX Error: ErrorBoundary must be passed children!');
    }

    return this.props.children || null;
  }
}
