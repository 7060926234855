import React from 'react';
import PropTypes from 'prop-types';

const Form = ({ children, ...props }) => {
  return (
    <form className="lj-form" {...props}>
      {children}
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
};
export default Form;
