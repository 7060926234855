import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'components';
import LoadingBar from 'components/loader';

const Button = props => {
  const {
    text,
    onClick,
    variant,
    name,
    type,
    full,
    disabled,
    icon,
    isLoading,
    iconLocation,
    iconWidth,
    iconHeight
  } = props;
  const isFull = full ? 'btn-full' : '';

  return (
    // disabling es-lint rule because of https://github.com/yannickcr/eslint-plugin-react/issues/1555
    /*  eslint-disable react/button-has-type */
    <>
      {isLoading ? <LoadingBar /> : null}
      <button
        className={`btn btn-${variant} ${isFull}`}
        onClick={onClick}
        name={name}
        /*  eslint-disable-next-line react/button-has-type */
        type={type}
        disabled={disabled}
      >
        {iconLocation === 'left' && icon && (
          <Icon
            icon={icon}
            width={iconWidth || 20}
            height={iconHeight || 12}
            color="#4C7B77"
          />
        )}
        <span className="btn-text">{text}</span>
        {iconLocation === 'right' && icon && (
          <Icon icon={icon} width={20} height={12} color="#4C7B77" />
        )}
      </button>
    </>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  variant: PropTypes.oneOf([
    'primary',
    'primary-bright',
    'secondary',
    'secondary-light',
    'tertiary',
    'tertiary-light',
    'tertiary-icon'
  ]),
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['reset', 'button', 'submit']),
  disabled: PropTypes.bool,
  full: PropTypes.bool,
  icon: PropTypes.string,
  iconLocation: PropTypes.string
};

Button.defaultProps = {
  variant: 'primary',
  type: 'button',
  onClick: () => {},
  disabled: false,
  full: false,
  icon: '',
  iconLocation: 'right'
};

export default Button;
