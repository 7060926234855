import api from 'utils/api';

export const getInvoices = currentCompanyId => {
  return api.get(`/v1/invoices?company=${currentCompanyId}&limit=1000`);
};

/**
 * Fetches the required data to render a specific invoice
 * @param {Number} currentCompanyId The id of the current company
 * @param {String} invoiceId The id of the invoice being viewed
 * @param {String} invoiceType The type of invoice being viewed
 */
export const getInvoiceDetail = (currentCompanyId, invoiceId, invoiceType) => {
  if (invoiceType !== 'ppaexport' && invoiceType !== 'ffrdynamic') {
    throw new Error('Not a known type of invoice');
  }
  return api.get(
    `/v1/invoice?company=${currentCompanyId}&invoiceId=${invoiceId}&invoicetype=${invoiceType}`
  );
};
