import React from 'react';
import PropTypes from 'prop-types';

import { Skeleton } from 'components';

const LoadingTable = ({ prepareRow, getTableBodyProps, rows }) => (
  <tbody className="lj-table__body" {...getTableBodyProps()}>
    {rows.map(row => {
      prepareRow(row);
      return (
        <tr className="lj-table__body-row">
          {row.cells.map(cell => {
            return (
              <td className="lj-table__body-cell" {...cell.getCellProps()}>
                {/* the height is taken from the design. we are using percentages
                instead of pixels for the width so the elements adjust to their
                container 
                */}
                <Skeleton width="70%" height="19px" />
              </td>
            );
          })}
          <td />
        </tr>
      );
    })}
  </tbody>
);

LoadingTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired
};

export default LoadingTable;
