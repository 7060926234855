import React from 'react';
import PropTypes from 'prop-types';

const Col = ({ modifier, children, style, className, tag: Element }) => {
  return (
    <Element className={`${modifier} ${className}`} style={style}>
      {children}
    </Element>
  );
};

Col.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  modifier: PropTypes.string,
  style: PropTypes.shape({}),
  className: PropTypes.string,
  tag: PropTypes.string
};

Col.defaultProps = {
  modifier: '',
  style: {},
  className: '',
  tag: 'div'
};

export default Col;
