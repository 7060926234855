import React from 'react';

import { Button } from 'components';

const OutageReporting = () => {
  return (
    <div className="outage-buttons">
      <a
        href="https://limejump.typeform.com/to/lqDaMjRb"
        target="_blank"
        rel="noreferrer"
        className="planned-anchor"
      >
        <Button
          variant="primary"
          text="Submit a planned PPA outage"
          name="submit-ppa-outage"
        />
      </a>

      <a
        href="https://limejump.typeform.com/to/RHn3vK6b"
        target="_blank"
        rel="noreferrer"
        className="unplanned-anchor"
      >
        <Button
          variant="primary"
          text="Report an unplanned PPA outage"
          name="report-ppa-outage"
        />
      </a>
    </div>
  );
};

export default OutageReporting;
