import { takeEvery } from 'redux-saga/effects';
import { API_ERROR_NAME } from 'utils/api';

function bubbleErrors({ error }) {
  if (process.env.NODE_ENV === 'development') {
    console.error(error);
  }

  // Failing API calls shouldn't crash the saga or be reported to sentry.
  // Network failures are built into the fabric of sagas anyway.
  if (error.name && error.name === API_ERROR_NAME) {
    return;
  }

  throw error;
}

export function* watchErrors() {
  yield takeEvery('ERROR', bubbleErrors);
}

export const ERROR = 'ERROR';
export function createError(error) {
  return { type: ERROR, error };
}
