import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ExternalLink = ({
  url,
  disabled,
  variant,
  children,
  className,
  ...props
}) => (
  <a
    className={classNames(
      {
        'lj-link': true,
        'lj-link--disabled': disabled
      },
      `lj-link--${variant}`,
      className
    )}
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    {...props}
  >
    {children}
  </a>
);

ExternalLink.propTypes = {
  /** the url for navigation */
  /** Custom prop function to allow for disabled links to have null or undefined links. No consistent return expected by proptypes library. */
  // eslint-disable-next-line consistent-return
  url(props, propertyName, componentName) {
    if (
      !props.disabled &&
      (typeof props[propertyName] !== 'string' || !props[propertyName])
    ) {
      return new Error(
        `Invalid prop ${propertyName} passed to component ${componentName}. The component is not disabled and ${propertyName} is either not a string or is falsy.`
      );
    }
  },
  /** any child elements to render or the link text  */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'dropdown'])
};

ExternalLink.defaultProps = {
  children: null,
  url: '',
  disabled: false,
  variant: 'default'
};

export default ExternalLink;
