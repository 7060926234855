import React from 'react';
import PropTypes from 'prop-types';

const Pill = ({ text, variant }) => (
  <span className={`lj-pill lj-pill--${variant}`}>{text}</span>
);

Pill.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string
};

Pill.defaultProps = {
  variant: 'jazzy'
};

export default Pill;
