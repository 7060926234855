import { ALL_OPTION } from './constants';

const createOptionsFromYear = billingPeriodsInYear => {
  return billingPeriodsInYear
    .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
    .map(period => ({ label: period.title, value: period }));
};

export const getInvoiceFilterOptions = billingPeriods => {
  const possibleYears = new Set();
  const optionsForYear = [ALL_OPTION];

  billingPeriods.forEach(period => {
    possibleYears.add(period.year);
  });

  Array.from(possibleYears)
    .sort()
    .reverse()
    .forEach(year => {
      optionsForYear.push({
        title: year,
        // options: billingPeriods.filter(period => period.year === year)
        options: createOptionsFromYear(
          billingPeriods.filter(period => period.year === year)
        )
      });
    });

  return optionsForYear;
};

export const filterVisible = (data, dropdownOption) => {
  if (dropdownOption.value === ALL_OPTION.value) {
    return data;
  }
  const { endDate, startDate } = dropdownOption.value;

  return data.filter(invoice => {
    const billingDate = new Date(invoice.billing_period.start);
    return (
      billingDate >= new Date(startDate) && billingDate <= new Date(endDate)
    );
  });
};
