import { spawn } from 'redux-saga/effects';

import auth, {
  watchLogin,
  watchCurrentCompanyId,
  watchGetUserSuccess
} from './auth';
import reset, { watchEmailReset, watchPasswordReset } from './reset';
import overview, { watchGetProducts, watchGetRevenue } from './overview';
import news, { watchGetNews } from './overview/news';
import notifications, { watchAlertClose } from './notifications';
import invoices, { watchShowInvoice } from './invoices';
import availability, {
  watchGetAssets,
  watchSetCurrentAsset,
  watchSetView,
  watchGetAssetsSuccess
} from './availability';
import { watchErrors } from './error';

// reducers
export { auth, overview, news, reset, invoices, notifications, availability };

// sagas
// The rootSagas use spawn to ensure that if an error bubbles up, it won't kill the other sagas.
// See 'Keeping the root alive', https://redux-saga.js.org/docs/advanced/RootSaga.html
export function* rootSaga() {
  // login
  yield spawn(watchLogin);
  yield spawn(watchEmailReset);
  yield spawn(watchPasswordReset);
  // overview
  yield spawn(watchGetProducts);
  yield spawn(watchGetRevenue);
  yield spawn(watchGetNews);

  yield spawn(watchCurrentCompanyId);
  yield spawn(watchGetUserSuccess);
  yield spawn(watchErrors);
  yield spawn(watchAlertClose);
  // invoices
  yield spawn(watchShowInvoice);

  yield spawn(watchGetAssets);
  yield spawn(watchSetCurrentAsset);
  yield spawn(watchSetView);
  yield spawn(watchGetAssetsSuccess);
}
