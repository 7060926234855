import React from 'react';

import { InterstitialError, ErrorPage } from 'components';
import history from 'utils/history';

export const NoInvoicesAvailable = ({ currentCompanyId }) => (
  <InterstitialError
    title="Nothing to show here just yet"
    message="Please contact your account manager if the problem persists."
    buttonText="Go to dashboard"
    buttonName="button-invoices-unavailable"
    buttonOnClick={() => history.push(`/${currentCompanyId}/overview`)}
  />
);

export const GeneralInvoicesError = ({ invoicesError }) => (
  <ErrorPage message={invoicesError} />
);
export const GeneralInvoicesErrorNoMessage = () => <ErrorPage />;
