import { call, put, takeEvery } from 'redux-saga/effects';
import { format, parseISO } from 'date-fns';
import { getNewsFeed } from 'services/overview';

const GET_NEWS_REQUEST = 'GET_NEWS_REQUEST';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAILURE = 'GET_NEWS_FAILURE';

export const formatDate = date => format(parseISO(date), 'MMM dd, yyy');

/**
 * STATE
 */

const initialState = {
  newsItems: [],
  loadingNews: false,
  newsError: ''
};

export const news = (state = initialState, action) => {
  switch (action.type) {
    case GET_NEWS_FAILURE: {
      return {
        ...state,
        ...{
          loadingNews: false,
          newsError: action.payload
        }
      };
    }

    case GET_NEWS_SUCCESS: {
      return {
        ...state,
        ...{
          loadingNews: false,
          newsItems: action.payload.newsItems
        }
      };
    }

    case GET_NEWS_REQUEST: {
      return {
        ...state,
        ...{
          loadingNews: true,
          newsError: ''
        }
      };
    }

    default:
      return state;
  }
};

/**
 * ACTIONS
 */

export function getNews() {
  return {
    type: GET_NEWS_REQUEST
  };
}

export function getNewsSuccess(response) {
  return {
    type: GET_NEWS_SUCCESS,
    payload: response
  };
}

function getNewsFailure(response) {
  return {
    type: GET_NEWS_FAILURE,
    payload: response
  };
}

/**
 * ASYNC ACTIONS
 */

export function* getNewsRequest() {
  const { error, response } = yield call(getNewsFeed);

  if (error) {
    yield put(getNewsFailure(error));
    return;
  }

  const formattedItems = response.map(item => ({
    ...item,
    ...{
      date: formatDate(item.date)
    }
  }));

  yield put(getNewsSuccess({ newsItems: formattedItems }));
}

export function* watchGetNews() {
  yield takeEvery(GET_NEWS_REQUEST, getNewsRequest);
}

export default news;
