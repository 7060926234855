import React from 'react';
import PropTypes from 'prop-types';

const Text = React.forwardRef(
  ({ tag: Element, content, className, ...props }, reference) => {
    return (
      <Element ref={reference} className={className} {...props}>
        {content}
        {props.children}
      </Element>
    );
  }
);

Text.propTypes = {
  /** the tag that the text will render as */
  tag: PropTypes.oneOf(['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  /** the class that the component will take */
  className: PropTypes.string,
  /** the content of the tag */
  content: PropTypes.string
};

Text.defaultProps = {
  tag: 'p',
  content: '',
  className: null
};

export default Text;
