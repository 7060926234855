import React from 'react';
import { Row, Text, ExternalLink } from 'components';

const Support = () => (
  <section className="overview-support">
    <Row modifier="between-sm">
      <Text tag="h3" content="Support" />
    </Row>
    <Row className="overview-support__contact-info">
      <Text tag="p" content="From 9am to 5pm, Mon to Fri" />
      <ExternalLink url="tel:+44 (0)20 8396 6848">
        +44 (0)20 8396 6848
      </ExternalLink>
      <ExternalLink url="mailto:support@limejump.com">
        support@limejump.com
      </ExternalLink>
      <ExternalLink url="https://portal-resources.limejump.com/Help+Center+v2.pdf">
        Help Center
      </ExternalLink>
    </Row>
  </section>
);

export default Support;
