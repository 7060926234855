import { clearCookies } from 'utils/storage';

/**
 * This function performs the necessary side effects for logging a user out.
 */
export const logout = () => {
  // This removes all auth tokens and local state
  clearCookies();
  // Force the application to reload
  window.location.reload();
};
