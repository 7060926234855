import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { PageHeader, Alert, DocumentTitle, Text, Icon } from 'components';
import ICONS from 'utils/icons';

import { useQuery } from 'react-query';
import { getInvoices } from 'services/invoices';
import InvoicesTable from './InvoicesTable';

const InvoicesWrapper = ({ invoiceAlert, children }) => {
  return (
    <Fragment>
      <DocumentTitle title="invoices" />
      {invoiceAlert}
      <PageHeader
        title="Invoices"
        subheading="View and download your invoices along with their backing data."
      />
      {children}
    </Fragment>
  );
};

const warningAlert = warning => {
  if (!warning) {
    return null;
  }
  const invoiceTypeMap = {
    'FFR_MISSING': 'FFR',
    'PPA_MISSING': 'PPA Export'
  };
  return (
    <Fragment>
      <Alert variant="warning">
        <Fragment>
          <Icon
            strokeWidth={1}
            icon={ICONS.ERROR}
            height={14}
            width={14}
            fill="#91191F"
          />
          &nbsp;
          <Text
            content={`We are having trouble accessing your ${invoiceTypeMap[warning]} invoices right
      now. Please try again later.`}
          />
        </Fragment>
      </Alert>
    </Fragment>
  );
};

const Invoices = () => {
  const { currentCompanyId } = useSelector(state => state.auth);

  const { data, isLoading, error } = useQuery(
    ['invoices', currentCompanyId],
    () => getInvoices(currentCompanyId),
    { retry: false }
  );

  // Possible alert components that can appear
  const InvoiceWarningAlert = warningAlert(data?.warning);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyId]);

  return (
    <InvoicesWrapper invoiceAlert={InvoiceWarningAlert}>
      <InvoicesTable error={error} isLoading={isLoading} data={data} />
    </InvoicesWrapper>
  );
};

export default Invoices;
