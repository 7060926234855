import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { getCurrentCompanyId } from 'reducers/selectors';

import { Helmet } from 'react-helmet';

import Iframe from '../components/iframe';

const Market = props => {
  const { currentCompanyId } = props;

  return (
    <Fragment>
      <Helmet>
        <title>Market</title>
      </Helmet>
      <Iframe
        url={`${process.env.REACT_APP_SIDEBARLESS_PORTAL_BASE_URL}/#/market/live?iframe=true`}
        title="Market"
        companyId={currentCompanyId}
      />
    </Fragment>
  );
};

const mapStateToProps = state => {
  const currentCompanyId = getCurrentCompanyId(state);
  return { currentCompanyId };
};

export default connect(mapStateToProps)(Market);
