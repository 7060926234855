import React from 'react';
import { Row, Skeleton } from 'components';

const ActiveContractsLoading = () => (
  <section className="overview-contracts">
    <Row modifier="between-xs">
      <Skeleton width="140px" height="20px" />
      <Skeleton width="140px" height="20px" />
    </Row>
    <Row modifier="between-xs">
      <Skeleton width="180px" height="20px" />
      <Skeleton width="180px" height="20px" />
    </Row>
    <Row modifier="between-xs">
      <Skeleton width="180px" height="20px" />
      <Skeleton width="180px" height="20px" />
    </Row>
  </section>
);

export default ActiveContractsLoading;
