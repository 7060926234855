import React from 'react';
import PropTypes from 'prop-types';

import { Text, Pill } from 'components';
import PageTitle from './PageTitle';

const PageHeader = ({ children, title, subheading, pillText }) => {
  if (children) return <section className="page-header">{children}</section>;
  let subheadingComponent;

  if (subheading) {
    if (typeof subheading === 'string') {
      subheadingComponent = (
        <Text data-test-id="subheading" content={subheading} />
      );
    } else {
      subheadingComponent = (
        <div className="subheading__container">{subheading}</div>
      );
    }
  }
  return (
    <section className="page-header">
      <div className="page-title-block">
        <PageTitle title={title} /> {pillText && <Pill text={pillText} />}
      </div>
      {subheading && subheadingComponent}
    </section>
  );
};

PageHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  title: PropTypes.string,
  /** If passing an array of nodes please wrap them in a fragment */
  subheading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  pillText: PropTypes.string
};

PageHeader.defaultProps = {
  children: null,
  title: 'Please enter a title',
  subheading: null,
  pillText: null
};

export default PageHeader;
