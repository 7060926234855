import React from 'react';
import { Link } from 'react-router-dom';

import { Text, Alert } from 'components';

export default {
  'VIEW_ONLY_ASSET_LAUNCH': {
    content: ({ close }) => (
      <Alert variant="jazzy" close={() => close()} floating={false}>
        <Text content="Asset Availability is currently in Beta." />
        &nbsp;
        <button
          className="lj-link"
          data-az-l="3bf5e2e9-a8ab-4730-93e1-ae93bd69f932"
          type="button"
        >
          Give your feedback.
        </button>
      </Alert>
    ),
    name: 'VIEW_ONLY_ASSET_LAUNCH',
    viewType: 'once'
  },
  'VIEW_ONLY_OVERVIEW': {
    content: ({ close }) => (
      <Alert variant="jazzy" close={() => close()} floating={false}>
        <Text content="Is your PPA site experiencing an outage? You can now report it to us through  your dashboard." />
        &nbsp;
      </Alert>
    ),
    name: 'VIEW_ONLY_OVERVIEW',
    viewType: 'once'
  },
  'VIEW_ONLY_FORECAST': {
    content: ({ close, company }) => (
      <Alert variant="jazzy" close={() => close()} floating={false}>
        <p>
          Want to amend your forecast? Do this by reporting PPA availability
          through the buttons at the top right of the{' '}
          <Link style={{ color: '#443570' }} to={`/${company}/overview`}>
            Dashboard
          </Link>{' '}
          screen.
        </p>
        &nbsp;
      </Alert>
    ),
    name: 'VIEW_ONLY_FOREACSAT',
    viewType: 'once'
  }
};
