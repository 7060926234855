import React from 'react';

const OverviewBanner = ({
  isNotificationShowing,
  setisNotificationShowing,
  setAlertViewedStatus,
  alert: Alert,
  company
}) => {
  return (
    <div style={{ zIndex: 20, position: 'relative' }}>
      {isNotificationShowing && (
        // eslint-disable-next-line react/jsx-pascal-case
        <Alert.content
          close={() => {
            setisNotificationShowing(false);
            setAlertViewedStatus(true);
          }}
          company={company}
        />
      )}
    </div>
  );
};

export default OverviewBanner;
