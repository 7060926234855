import React, { Component } from 'react';

import { InterstitialError, Skeleton, Row, Col, Panel } from 'components';

import { createRange } from 'utils/helpers';

export const AssetPropertiesErrorComponent = () => {
  return (
    <InterstitialError
      title="Sorry, something went wrong on our end"
      message="We couldn’t fetch your assets."
      subMessage=" Please try again later"
      buttonText="Reload Page"
      buttonName="button-assets-list-unavailable"
      buttonOnClick={() => window.location.reload()}
    />
  );
};

export const AssetPropertiesSkeleton = () => (
  <Panel className="asset-details">
    {createRange(3).map(rowItem => (
      <Row key={rowItem} className="asset-details__row" modifier="between-xs">
        {createRange(4).map(colItem => (
          <Col
            key={colItem}
            className="asset-details__col"
            modifier="col-xs-12 col-sm-6 col-md-3"
          >
            <Skeleton width="75%" height="20px" />
            <Skeleton width="25%" height="20px" />
          </Col>
        ))}
      </Row>
    ))}
    <Row className="asset-details__row">
      {createRange(2).map(colItem => (
        <Col
          key={colItem}
          className="asset-details__col"
          modifier="col-xs-12 col-sm-6 col-md-3"
        >
          <Skeleton width="75%" height="20px" />
          <Skeleton width="25%" height="20px" />
        </Col>
      ))}
    </Row>
  </Panel>
);

export class AssetPropertiesErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return <AssetPropertiesErrorComponent />;
    }

    return this.props.children;
  }
}
