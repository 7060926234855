import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { ErrorPage } from 'components';

const Page404 = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <ErrorPage type="404" />
    </Fragment>
  );
};

export default Page404;
