import React from 'react';
import { Switch, Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import history from 'utils/history';
import store from 'app-store';
import { isAuthAndPathValid, isAuth, handleInvalidPage } from 'utils/guards';

import {
  Overview,
  Login,
  PasswordReset,
  Invoices,
  BaseRoute,
  GuardedRoute,
  PublicRoute,
  AvailabilityViewOnly,
  AssetProperties,
  Invoice
} from 'features';
import SelectCompany from 'routes/select-company';
import HistoricalPerformance from 'routes/historical-performance';
import LivePerformance from 'routes/live-performance';
import Forecast from 'routes/forecast';
import FFRAvailability from 'routes/availability';
import DispatchAvailability from 'routes/dispatch-availability';
import Market from 'routes/market';
import Page404 from 'routes/404';
import { QueryClient, QueryClientProvider } from 'react-query';

// Used for React-Query (Invoices Page)
const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            {/* If adding a route please also update /utils/routes.js */}
            <GuardedRoute
              path="/:companyId/overview"
              component={Overview}
              guard={(companyId, path) =>
                isAuthAndPathValid(companyId, path, store)
              }
            />
            <GuardedRoute
              path="/:companyId/invoices"
              component={Invoices}
              guard={(companyId, path) =>
                isAuthAndPathValid(companyId, path, store)
              }
            />
            <GuardedRoute
              path="/:companyId/historical-performance"
              component={HistoricalPerformance}
              guard={(companyId, path) =>
                isAuthAndPathValid(companyId, path, store)
              }
            />
            <GuardedRoute
              path="/:companyId/live-performance"
              component={LivePerformance}
              guard={(companyId, path) =>
                isAuthAndPathValid(companyId, path, store)
              }
            />
            <GuardedRoute
              path="/:companyId/forecast"
              component={Forecast}
              guard={(companyId, path) =>
                isAuthAndPathValid(companyId, path, store)
              }
            />
            <GuardedRoute
              path="/:companyId/availability"
              component={FFRAvailability}
              guard={(companyId, path) =>
                isAuthAndPathValid(companyId, path, store)
              }
            />
            <GuardedRoute
              path="/:companyId/dispatch-availability"
              component={DispatchAvailability}
              guard={(companyId, path) =>
                isAuthAndPathValid(companyId, path, store)
              }
            />
            <GuardedRoute
              path="/:companyId/view-availability"
              component={AvailabilityViewOnly}
              guard={(companyId, path) =>
                isAuthAndPathValid(companyId, path, store)
              }
            />
            <GuardedRoute
              path="/:companyId/asset-properties"
              component={AssetProperties}
              guard={(companyId, path) =>
                isAuthAndPathValid(companyId, path, store)
              }
            />
            <GuardedRoute
              path="/:companyId/market"
              component={Market}
              guard={() => isAuth(store)}
            />
            <GuardedRoute
              path="/select"
              component={SelectCompany}
              guard={() => isAuth(store)}
            />
            <GuardedRoute
              path="/:companyId/invoice/:invoiceId"
              component={Invoice}
              guard={(companyId, path) =>
                isAuthAndPathValid(companyId, path, store)
              }
              isHeaderless
            />
            <PublicRoute
              path="/password-reset"
              component={PasswordReset}
              exact
            />
            <PublicRoute path="/" component={BaseRoute} exact />
            <PublicRoute path="/login" component={Login} exact />
            <GuardedRoute
              component={Page404}
              guard={() => handleInvalidPage(store)}
            />
          </Switch>
        </Router>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
