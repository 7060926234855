/**
 * The routes that correspond to pages that should allow navigation.
 * key is used to match against the API response
 */
export const routes = [
  {
    path: '/overview',
    label: 'Dashboard',
    key: 'dashboard',
    order: 0,
    shouldAppearInSidebar: true
  },
  {
    path: '/invoices',
    label: 'Invoices',
    key: 'invoices',
    order: 1,
    shouldAppearInSidebar: true
  },
  {
    path: '/invoice/:invoiceId',
    label: 'Invoices',
    key: 'invoices',
    order: 1,
    shouldAppearInSidebar: false
  },
  {
    path: '/live-performance',
    label: 'Live Performance',
    key: 'live_performance',
    order: 2,
    shouldAppearInSidebar: true
  },
  {
    path: '/historical-performance',
    label: 'Historical Performance',
    key: 'historical_performance',
    order: 3,
    shouldAppearInSidebar: true
  },
  {
    path: '/forecast',
    label: 'Forecast',
    key: 'forecast',
    order: 4,
    shouldAppearInSidebar: true
  },
  {
    path: '/availability',
    label: 'FFR Availability',
    key: 'availability',
    order: 5,
    shouldAppearInSidebar: true
  },
  {
    path: '/dispatch-availability',
    label: 'Dispatch Availability',
    key: 'dispatch_availability',
    order: 6,
    shouldAppearInSidebar: true
  },
  {
    path: '/view-availability',
    label: 'Asset Availability',
    iconName: 'availability',
    key: 'consolidated_availability',
    order: 7,
    shouldAppearInSidebar: true
  },
  {
    path: '/asset-properties',
    label: 'Asset Properties',
    iconName: 'asset_properties',
    key: 'asset_properties',
    order: 8,
    shouldAppearInSidebar:
      process.env.REACT_APP_USE_FEATURE_ASSET_PROPERTIES === 'true'
  },
  {
    path: '/market',
    label: 'Market',
    order: 9,
    shouldAppearInSidebar: true
  }
];

/**
 * This function transforms a company's can view into a set of named private routes that should be accessible by a user
 * @param {*} companyViews A company's `can_view` property.
 * @returns {Array} A set of named routes
 */
export function calculateRoutesFromCompanyRouteData(companyViews, routes) {
  return (
    routes
      .sort((a, b) => a.order - b.order)
      .filter(route => {
        // can_view: objects look like {live_performance: false}
        // Routes should default to appearing in the sidebar and being accessible, and should be removed here if the user isn't meant to see them
        // eslint-disable-next-line no-prototype-builtins
        if (!companyViews.hasOwnProperty(route.key)) {
          return true;
        }
        return companyViews[route.key];
      })
      // We don't need the order property or key property for checking access or displaying the sidebar
      .map(r => {
        return {
          iconName: r.iconName,
          path: r.path,
          label: r.label,
          shouldAppearInSidebar: r.shouldAppearInSidebar,
          additionalSidebarComponent: r.additionalSidebarComponent
        };
      })
  );
}
