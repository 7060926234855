import React from 'react';
import PropTypes from 'prop-types';

const Row = ({ children, modifier, className, style, tag: Element }) => {
  return (
    <Element className={`row ${modifier} ${className}`} style={style}>
      {children}
    </Element>
  );
};

Row.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  modifier: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  tag: PropTypes.string
};

Row.defaultProps = {
  modifier: '',
  className: '',
  style: {},
  tag: 'div'
};

export default Row;
