import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const DocumentTitle = ({ title }) => (
  <Helmet>
    <title>{`Limejump | ${title}`}</title>
  </Helmet>
);

DocumentTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default DocumentTitle;
