import React from 'react';
import PropTypes from 'prop-types';

const LinkButton = props => {
  const { text, link } = props;

  return (
    <a
      className="btn-link"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {text}
    </a>
  );
};

LinkButton.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default LinkButton;
