import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getNews } from 'reducers/overview/news';

import { Row, Col, Text } from 'components';
import NewsLoading from './loading';
import NewsError from './error';
import NewsArticle from './NewsArticle';

const News = ({ newsItems, loadingNews, newsError, getNews }) => {
  useEffect(() => {
    getNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingNews) return <NewsLoading />;

  if (newsError) return <NewsError />;

  return (
    <section className="overview__news">
      <Row tag="section">
        <Col modifier="col-xs-12">
          <Text tag="h3" content="News" className="overview__news--title" />
        </Col>
      </Row>
      <Row tag="section" className="overview__news--items">
        {newsItems.map(article => (
          <NewsArticle key={article.title} article={article} />
        ))}
      </Row>
    </section>
  );
};

const mapStateToProps = state => {
  const { newsItems, loadingNews, newsError } = state.news;
  return { newsItems, loadingNews, newsError };
};

const mapDispatchToProps = dispatch => {
  return {
    getNews: () => dispatch(getNews())
  };
};

News.propTypes = {
  newsItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired,
  loadingNews: PropTypes.bool.isRequired,
  getNews: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
