import axios from 'axios';
import { getAuthTokenCookie } from 'utils/storage';

function requestWrapper(promise) {
  return promise
    .then(data => ({
      error: null,
      response: data
    }))
    .catch(error => ({
      error,
      response: undefined
    }));
}

async function apiMethodWrapper(fn, url, ...arguments_) {
  let error = null;
  let response = null;

  response = await fn(url, ...arguments_).catch(error_ => {
    error = error_;
  });

  return { error, response };
}

function setFinalApi(url) {
  // for dev environments we either use the test server
  // or the original url
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_USE_FAST_TEST_DATA === 'true'
      ? `http://localhost:3001${url}`
      : url;
  }

  if (url.startsWith('/api')) {
    return `${process.env.REACT_APP_GATEWAY_BASE_URL}${url}`;
  }

  return `${process.env.REACT_APP_PORTAL_API_BASE_URL}${url}`;
}

export const API_ERROR_NAME = 'LJ API Exception';

function LJError(data) {
  this.name = API_ERROR_NAME;

  const defaultMessage = 'Something has gone wrong. Please try again.';
  this.message = data?.detail || data?.error || defaultMessage;
}

async function get(url) {
  const baseUrl = setFinalApi(url);
  const header = {
    headers: {
      Authorization: getAuthTokenCookie(),
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await axios.get(baseUrl, header);
    return response.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      // Has error.response.data, error.response.status, and error.response.headers
      throw new LJError(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received `error.request` is an instance of XMLHttpRequest
      throw new LJError(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new LJError(error.message);
    }
  }
}

// /* eslint-disable consistent-return */
async function getWithWrapper(url, options) {
  const baseUrl = setFinalApi(url);
  const header = {
    headers: {
      Authorization: options.auth ? options.auth : getAuthTokenCookie(),
      'Content-Type': 'application/json'
    }
  };

  const { error, response } = await requestWrapper(axios.get(baseUrl, header));

  if (error) {
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      // Has error.response.data, error.response.status, and error.response.headers
      throw new LJError(error.response.data);
    }

    if (error.request) {
      // The request was made but no response was received `error.request` is an instance of XMLHttpRequest
      throw new LJError(error.request);
    }

    throw new LJError(error.message);
  }

  return response.data;
}

async function post(url, data, _options = {}) {
  const baseUrl = setFinalApi(url);
  const options = {
    headers: _options.header
      ? _options.header
      : { 'Content-Type': 'application/json' }
  };

  try {
    const response = await axios.post(baseUrl, JSON.stringify(data), options);
    return response.data;
  } catch (error) {
    throw new LJError(error?.response?.data);
  }
}

async function postWithWrapper(url, data, _options = {}) {
  const baseUrl = setFinalApi(url);
  const options = {
    headers: _options.header
      ? _options.header
      : { 'Content-Type': 'application/json' }
  };

  const { error, response } = await requestWrapper(
    axios.post(baseUrl, JSON.stringify(data), options)
  );

  if (error) {
    if (error.response) {
      throw new LJError(error?.response?.data);
    }

    throw new LJError(error?.message);
  }

  return response.data;
}

/**
 * The withWrapper method takes advantage of this approach: https://blog.grossman.io/how-to-write-async-await-without-try-catch-blocks-in-javascript/
 * to replace the try/catch patter. We still need to roll this out across every
 * part of the app
 */
export default {
  getWithWrapper: (url, options = {}) =>
    apiMethodWrapper(getWithWrapper, url, options),
  postWithWrapper: (url, data, options) =>
    apiMethodWrapper(postWithWrapper, url, data, options),
  get,
  post
};
