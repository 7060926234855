/**
 * Returns the current company's id
 * @param {Object} state
 */
export const getCurrentCompanyId = state => state.auth.currentCompanyId;

/**
 * Returns the current user's id
 * @param {Object} state
 */
export const getCurrentUserId = state => state.auth.userId;

/**
 * Returns a company object if the company exists in the store with that id
 * @param {Number} companyId The company id to get the company for
 * @param {Object} state
 * @returns {object|null} Company
 */
export const getCompany = (companyId, state) => {
  if (!companyId) {
    console.error('getCompany selector called with missing company id');
    return null;
  }
  if (!state || Object.keys(state).length === 0) {
    console.error('getCompany selector called with missing state');
  }
  const company = state.auth.companies.find(c => c.id === companyId);
  return company || null;
};

/**
 * Extracts the current company object from the store if it exists and the current company id is set
 * @param {Object} state
 * @returns {object|null} Company
 */
export const getCurrentCompany = state => {
  const currentCompanyId = getCurrentCompanyId(state);
  if (!currentCompanyId) {
    return null;
  }
  return getCompany(currentCompanyId, state);
};

/**
 * Extracts the user's email from the store
 * @param {Object} state
 */
export const getEmail = state => state.auth.email;

// TODO: TEST
/**
 * Returns the name of the alert currently in the global reducer
 * @param {Object} state
 */
export const getCurrentAlertName = state => state.notifications.alertName;

// TODO: TEST
/**
 * Returns the site id of the currently selected site for availibility
 * @param {Object} state
 */
export const getCurrentAssetId = state => state.availability.selectedAsset.id;

// TODO: TEST
/**
 * Returns the currently selected period for availibility
 * @param {Object} state
 */
export const getCurrentPeriod = state =>
  state.availability.periods.currentPeriod;

/**
 * Fetches the current invoice id that's being viewed
 * @param {Object} state
 */
export const getCurrentInvoiceId = state => state.invoices.currentInvoiceId;

/**
 * Gets the current invoice to be displayed from all stored invoices
 * @param {Object} state
 */
export const getCurrentInvoice = state =>
  state.invoices.invoiceDetailData[getCurrentInvoiceId(state)];
