import React from 'react';

const Spinner = props =>
  props.variant === 'global' ? (
    <div className="outer-spinner">
      <div className="spinner" id={props.id} />
    </div>
  ) : (
    <div className="spinner" id={props.id} />
  );

export default Spinner;
