import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { setCurrentCompanyId } from 'reducers/auth';
import store from 'app-store';

import { Spinner } from 'components';
import PrivatePage from './PrivatePage';

const RedirectToLogin = () => <Redirect to="/login" />;

const RouteToRender = ({ component, isHeaderless, ...rest }) => {
  return (
    <Route
      exact
      {...rest}
      render={props => (
        <PrivatePage
          component={component}
          isHeaderless={isHeaderless}
          {...props}
        />
      )}
    />
  );
};

/**
 * This function is responsible for iterating through all the guards and running
 * the necessary checks. It loops through guard objects yielded by the provided
 * guard function and renders whichever page is necessary
 * @param {guard} - generator function which yields any number of guard objects
 * @param {pageToRender} - the page to render once all guard objects have been resolved
 * @param {setPageToRender} - `useState`updater function which sets the UI to be shown
 * @return {void}
 */
async function resolveAllGuards(
  guard,
  pageToRender,
  setPageToRender,
  companyIdForIntendedPath,
  intendedPath
) {
  const guardGenerator = guard(companyIdForIntendedPath, intendedPath);

  let errorPageToRender = <RedirectToLogin />;

  console.debug('Running guards for path', intendedPath);
  let guardsAreRunning = true;
  try {
    /**
     * We have not used a 'for loop' here because the only way to check if a generator
     * function has finished yielding is by checking the `done` property of its next
     * iterator object. So we have no way of creating a counter for the total number
     * of iterator objects it will produce
     */
    while (guardsAreRunning) {
      const { value, done } = guardGenerator.next();

      if (done) {
        guardsAreRunning = false;
        console.debug('Guards finished running');
      } else {
        const { shouldCheckProceed, sideEffect, errorComp, name } = value;
        console.debug(`Ran Guard: ${name}. Proceeding: ${shouldCheckProceed}`);
        errorPageToRender = errorComp;

        if (!shouldCheckProceed) {
          console.debug('Guards failed', value);
          throw new Error(`Guard ${name} stopped proceeding`);
        }

        if (sideEffect) {
          // eslint-disable-next-line no-await-in-loop
          await sideEffect();
        }
      }
    }

    // Successful Navigation
    if (companyIdForIntendedPath) {
      // This will _not_ work as a connected component (for obscure unknown reasons) so we use store.dispatch() instead
      store.dispatch(setCurrentCompanyId(companyIdForIntendedPath));
    }

    setPageToRender(pageToRender);
  } catch (error) {
    setPageToRender(errorPageToRender);
  }
}

const GuardedRoute = ({
  component: Component,
  guard,
  isHeaderless,
  ...rest
}) => {
  const [component, setComponentToRender] = useState(<Spinner />);
  const companyIdForIntendedPath = parseInt(
    rest.computedMatch.params.companyId,
    10
  );

  const intendedPath = rest.computedMatch.path.replace('/:companyId', '');

  useEffect(() => {
    resolveAllGuards(
      guard,

      <RouteToRender
        component={Component}
        isHeaderless={isHeaderless}
        {...rest}
      />,
      setComponentToRender,
      companyIdForIntendedPath,
      intendedPath
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.computedMatch]);

  return component;
};

export default GuardedRoute;
