import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'components';
import ICONS from 'utils/icons';

const Alert = ({ children, variant, close, floating }) => (
  <div className={`lj-alert ${floating ? 'lj-alert--floating' : ''}`}>
    <div
      data-testid="alert-container"
      className={`lj-alert__container lj-alert__container--${variant}`}
    >
      <div className="lj-alert__text">{children}</div>
      {close ? (
        <button type="button" className="lj-alert__button" onClick={close}>
          <Icon
            icon={ICONS.CLOSE_NOTIFICATION}
            strokeWidth={2}
            // currentColor uses the same color as the container color: https://css-tricks.com/cascading-svg-fill-color/
            color="currentColor"
          />
        </button>
      ) : null}
    </div>
  </div>
);

Alert.propTypes = {
  /** any child elements to render or the text  */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  /** the type of alert to display  */
  variant: PropTypes.oneOf(['info', 'success', 'warning', 'jazzy', 'caution']),
  /** the function which closes the alert  */
  close: PropTypes.func,
  floating: PropTypes.bool
};

Alert.defaultProps = {
  variant: 'info',
  close: null,
  floating: true
};

export default Alert;
