import React, { useState } from 'react';

import { Table, DropDown, Row, CenterWithMargin, Col, Label } from 'components';

import { createRange } from 'utils/helpers';
import { useSelector } from 'react-redux';

import { GeneralInvoicesError } from './error-components';

import { ALL_OPTION } from './constants';
import InvoicesTableHeader from './InvoicesTableHeader';

import { getInvoiceFilterOptions, filterVisible } from './invoice-dropdown';

const InvoicesTable = ({ error, isLoading, data }) => {
  const { currentCompanyId } = useSelector(state => state.auth);

  const [dropdownOption, setDropdownOption] = useState(ALL_OPTION);

  if (error) {
    return <GeneralInvoicesError message={error} />;
  }

  const invoiceTableHeader = InvoicesTableHeader(currentCompanyId);

  const groupedOptions = isLoading
    ? [ALL_OPTION]
    : getInvoiceFilterOptions(data.billingPeriods);

  const filteredData = isLoading
    ? createRange(8)
    : filterVisible(data.invoices, dropdownOption);

  const filterByMonths = option => {
    setDropdownOption(option);
  };

  // State 7
  return (
    <CenterWithMargin>
      <div className="invoices">
        <Row tag="section" className="invoices__months-dropdown-container">
          <Col modifier="col-xs-12 col-sm-3">
            <Label
              text="Billing Period"
              htmlFor="invoices-dropdown"
              id="invoices-dropdown"
            />
            <DropDown
              name="invoices-dropdown"
              options={groupedOptions}
              onChange={filterByMonths}
              defaultValue={ALL_OPTION}
              value={dropdownOption}
              isDisabled={isLoading || !data.billingPeriods?.length}
            />
          </Col>
        </Row>
        <Row tag="section" className="invoices__table-container">
          <Col modifier="col-xs-12 col-sm-12">
            <Table
              columns={invoiceTableHeader}
              data={filteredData}
              noDataMessage="No invoices found"
              isLoading={isLoading}
              sortByData={{
                id: 'billing_period.start',
                desc: true
              }}
            />
          </Col>
        </Row>
      </div>
    </CenterWithMargin>
  );
};

export default InvoicesTable;
