import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getInvoiceDetail } from 'services/invoices';
import { getCurrentCompanyId, getCurrentInvoice } from 'reducers/selectors';
import { createError } from 'reducers/error';

// Get basic info about each invoice
export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILURE = 'GET_INVOICES_FAILURE';

// Get detailed invoice info
export const SHOW_INVOICE = 'SHOW_INVOICE';
export const GET_INVOICE_REQUEST = 'GET_INVOICE_REQUEST';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const GET_INVOICE_FAILURE = 'GET_INVOICE_FAILURE';

/**
 * STATE
 */

const initialState = {
  isLoadingInvoiceDetail: false,
  isErrorFetchingInvoiceDetail: false,
  invoiceDetailData: {},
  currentInvoiceId: ''
};

export const invoices = (state = initialState, action) => {
  switch (action.type) {
    // ALL THE REDUCERS BELOW ARE RELATED TO FETCHING INVOICE DATA
    // THEY NEED REFACTORING

    case SHOW_INVOICE: {
      return {
        ...state,
        currentInvoiceId: action.invoiceId
      };
    }

    case GET_INVOICE_REQUEST: {
      return {
        ...state,
        isLoadingInvoiceDetail: true,
        isErrorFetchingInvoiceDetail: false
      };
    }

    case GET_INVOICE_SUCCESS: {
      return {
        ...state,
        isLoadingInvoiceDetail: false,
        isErrorFetchingInvoiceDetail: false,
        invoiceDetailData: {
          ...state.invoiceDetailData,
          [action.payload.invoice.id]: action.payload
        }
      };
    }

    case GET_INVOICE_FAILURE: {
      return {
        ...state,
        isLoadingInvoiceDetail: false,
        isErrorFetchingInvoiceDetail: true
      };
    }

    default:
      return state;
  }
};

/**
 * ACTIONS
 */

// ALL THE REDUX ACTIONS BELOW ARE RELATED TO FETCHING INVOICE DATA
// THEY NEED REFACTORING

export function showInvoice(invoiceId, invoiceType) {
  return {
    type: SHOW_INVOICE,
    invoiceId,
    invoiceType
  };
}

export function getInvoiceRequest() {
  return {
    type: GET_INVOICE_REQUEST
  };
}

// Exported for testing purposes only
export function getInvoiceSuccess(payload) {
  return {
    type: GET_INVOICE_SUCCESS,
    payload
  };
}

export function getInvoiceFailure(payload) {
  return {
    type: GET_INVOICE_FAILURE,
    payload
  };
}

// ALL THE FUNCTIONS BELOW ARE RELATED TO FETCHING INVOICE DATA
// IT NEEDS REFACTORING\
export function* showInvoiceSaga({ invoiceType, invoiceId }) {
  const currentCompanyId = yield select(getCurrentCompanyId);
  const currentInvoice = yield select(getCurrentInvoice);

  if (currentInvoice) {
    // It's already in memory no need to fetch more data
    return;
  }

  yield put(getInvoiceRequest());
  try {
    const response = yield call(
      getInvoiceDetail,
      currentCompanyId,
      invoiceId,
      invoiceType
    );

    const invoice = {
      company: {
        address: {
          lineOne: response.company_details.address.line_one,
          lineTwo: response.company_details.address.line_two,
          lineThree: response.company_details.address.line_three,
          province: response.company_details.address.province,
          postcode: response.company_details.address.postcode,
          town: response.company_details.address.town
        },
        name: response.company_details.company_name,
        vatNumber: response.company_details.vat_number
      },
      invoice: {
        id: response.invoice.invoice_id,
        number: response.invoice.invoice_number,
        title: response.invoice.title,
        subtitle: response.invoice.subtitle,
        grossTotal: response.invoice.gross_total,
        netTotal: response.invoice.net_total,
        vatTotal: response.invoice.vat_total,
        invoiceDate: response.invoice.invoice_date,
        taxPointDate: response.invoice.tax_point
      },
      headers: response.invoice_headers,
      rows: response.invoice_rows,
      limejumpCompany: {
        address: {
          email: response.lj_company_details.address.email,
          lineOne: response.lj_company_details.address.line_one,
          lineTwo: response.lj_company_details.address.line_two,
          lineThree: response.lj_company_details.address.line_three,
          phoneNumber: response.lj_company_details.address.phone_number,
          postcode: response.lj_company_details.address.postcode,
          town: response.lj_company_details.address.town
        },
        generatedBy: response.lj_company_details.generated_by,
        registeredNumber: response.lj_company_details.registered_number,
        vatNumber: response.lj_company_details.vat_number
      }
    };
    invoice.invoice.id = invoiceId;

    yield put(getInvoiceSuccess(invoice));
  } catch (error) {
    yield put(getInvoiceFailure({ error }));
    yield put(createError(error));
  }
}

export function* watchShowInvoice() {
  yield takeEvery(SHOW_INVOICE, showInvoiceSaga);
}
export default invoices;
