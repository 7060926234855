import * as Sentry from '@sentry/browser';
import { call, put, takeLatest, select } from 'redux-saga/effects';

import history from 'utils/history';

import { createError } from 'reducers/error';
import {
  authenticateUser,
  getUsers,
  getCompanyFeatures,
  getToken
} from 'services/auth';
import {
  setAuthTokenCookie,
  getUserCookie,
  setUserCookie,
  clearCookies
} from 'utils/storage';

import { calculateRoutesFromCompanyRouteData, routes } from 'utils/routes';

import {
  getCurrentCompanyId,
  getCurrentUserId,
  getCompany
} from 'reducers/selectors';

export const LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const UPDATE_LOGIN_USER_EMAIL = 'UPDATE_LOGIN_USER_EMAIL';
export const UPDATE_LOGIN_USER_PASSWORD = 'UPDATE_LOGIN_USER_PASSWORD';

export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_COMPANY_FEATURES_SUCCESS = 'GET_COMPANY_FEATURES_SUCCESS';

export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';
export const SET_CURRENT_COMPANY_ID = 'SET_CURRENT_COMPANY_ID';
export const UPDATE_CURRENT_COMPANY_ID = 'UPDATE_CURRENT_COMPANY_ID'; // Used internally to actually update the current company id on the state

/**
 * STATE
 */

const initialState = {
  email: '',
  password: '',
  userId: null,
  firstName: null,
  lastName: null,
  currentCompanyId: getUserCookie().currentCompanyId || null,
  companies: [],
  error: null
};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DATA_SUCCESS: {
      const {
        userId,
        password,
        lastName,
        firstName,
        companies,
        email
      } = action.payload;

      return {
        ...state,
        userId,
        password,
        firstName,
        lastName,
        companies,
        email
      };
    }

    case GET_USER_DATA_FAILURE: {
      return {
        ...state,
        ...{
          error: action.payload
        }
      };
    }

    case UPDATE_LOGIN_USER_EMAIL: {
      return {
        ...state,
        ...{
          email: action.payload
        }
      };
    }

    case UPDATE_LOGIN_USER_PASSWORD: {
      return {
        ...state,
        ...{
          password: action.payload
        }
      };
    }

    case LOGIN_FAILURE: {
      return {
        ...state,
        ...{
          error: action.payload
        }
      };
    }

    case UPDATE_CURRENT_COMPANY_ID: {
      return {
        ...state,
        currentCompanyId: action.payload
      };
    }

    case GET_COMPANY_FEATURES_SUCCESS: {
      const permittedRoutes = action.payload;

      return {
        ...state,
        // Find the current company and set its permitted routes
        companies: state.companies.map(company => {
          if (company.id === action.companyId) {
            return { ...company, permittedRoutes };
          }
          return company;
        })
      };
    }

    default:
      return state;
  }
};

/**
 * ACTIONS
 */

export const handleChangePassword = password => {
  return {
    type: UPDATE_LOGIN_USER_PASSWORD,
    payload: password
  };
};

export const handleChangeEmail = email => {
  return {
    type: UPDATE_LOGIN_USER_EMAIL,
    payload: email
  };
};

function failure(error) {
  return {
    type: LOGIN_FAILURE,
    payload: error
  };
}

export function login(email, password) {
  return {
    type: LOGIN_REQUEST,
    email,
    password
  };
}

function getUserDataSuccess(payload) {
  return {
    type: GET_USER_DATA_SUCCESS,
    payload
  };
}

function getUserDataFailure(payload) {
  return {
    type: GET_USER_DATA_FAILURE,
    payload
  };
}

export const getCompanyFeaturesSuccess = (payload, companyId) => ({
  type: GET_COMPANY_FEATURES_SUCCESS,
  payload,
  companyId
});

export function setCurrentCompanyId(companyId) {
  return {
    type: SET_CURRENT_COMPANY_ID,
    companyId
  };
}

/**
 * DO NOT USE THIS FUNCTION THIS IS ONLY EXPOSED FOR TESTING PURPOSES
 *
 */
export function updateCurrentCompanyId(newCompanyId) {
  return {
    type: UPDATE_CURRENT_COMPANY_ID,
    payload: newCompanyId
  };
}

export const getPermittedRoutes = async (store, companyId) => {
  const currentCompanyId = companyId;

  if (!currentCompanyId) {
    console.error('getPermittedRoute called with no companyId');
    return {};
  }
  const currentCompany = getCompany(currentCompanyId, store.getState());
  if (currentCompany?.permittedRoutes) return {};
  const response = await getCompanyFeatures(currentCompanyId);
  const permittedRoutes = calculateRoutesFromCompanyRouteData(
    response.can_view,
    routes
  );
  store.dispatch(getCompanyFeaturesSuccess(permittedRoutes, currentCompanyId));

  return { permittedRoutes, currentCompanyId };
};

/**

 * ASYNC ACTIONS
 */

/**
 * This function is used to perform side effects by one of the auth guards. It is async
 * because we need the blocking behaviour of the 'await' keyword to wait for the API calls
 * to resolve. We can achieve the same behaviour with redux-saga but it would require
 * refactoring work which at present we can do it without. The change would involve
 * turning each guard into a saga and then handing off side effects work to
 * other sagas and then taking it from there (see: https://redux-saga.js.org/docs/advanced/ComposingSagas.html)
 * @param {*} store Redux store
 */
export async function validateAuthAndGetUserData(store) {
  try {
    const token = await getToken();

    const state = store.getState();
    const currentUserId = getCurrentUserId(state);

    if (currentUserId) return;

    const {
      first_name: firstName,
      last_name: lastName,
      companies,
      id: userId,
      email
    } = await getUsers(token.id);

    store.dispatch(
      getUserDataSuccess({
        userId,
        email,
        firstName,
        lastName,
        companies
      })
    );
  } catch (error) {
    store.dispatch(getUserDataFailure(error.message));
    throw new Error('Failed to fetch user data from API');
  }
}

/**
 *
 * SAGAS
 *
 */

// This handles the action destructering which is added for readability
// eslint-disable-next-line no-undef
export function* loginRequest({ email, password } = action) {
  try {
    const { auth } = yield call(authenticateUser, [email, password]);

    if (!auth.user_id) {
      throw new Error('Please try logging in again.');
    }
    clearCookies();
    setAuthTokenCookie(auth.token);
    const currentCompanyId = yield select(getCurrentCompanyId);
    if (currentCompanyId) {
      history.push(`/${currentCompanyId}/overview`);
    } else {
      history.push('/select');
    }
  } catch (error) {
    yield put(failure(error.message));
    yield put(createError(error));
  }
}

// This handles the action destructering which is added for readability
// eslint-disable-next-line no-undef
export function* updateCompanyId({ companyId } = action) {
  const newCompanyId = companyId;
  const currentCompanyId = yield select(getCurrentCompanyId);
  if (newCompanyId === currentCompanyId || !newCompanyId) {
    return;
  }

  // Update the company id in the store first
  yield put(updateCurrentCompanyId(newCompanyId));

  yield call(setUserCookie, companyId);
  yield call(Sentry.setUser, { currentCompanyId: companyId });
}

/**
 * Side effects that should be run following a user's login
 * @param {object} action
 */
export function* postLogin(action) {
  const { email } = action.payload;
  try {
    yield call(email);
    const currentCompanyId = yield select(getCurrentCompanyId);
    yield call(Sentry.setUser, { email, currentCompanyId });
  } catch (error) {
    yield put(createError(error));
  }
}

//
// Watchers
//

export function* watchCurrentCompanyId() {
  yield takeLatest(SET_CURRENT_COMPANY_ID, updateCompanyId);
}

export function* watchLogin() {
  yield takeLatest(LOGIN_REQUEST, loginRequest);
}

export function* watchGetUserSuccess() {
  yield takeLatest(GET_USER_DATA_SUCCESS, postLogin);
}

export default auth;
