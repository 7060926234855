import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col, CenterWithMargin, PageTitle, PageHeader } from 'components';
import { hasUserSeenAlert, setAlertViewedStatus } from 'utils/storage';
import { Messages } from 'features';
import useDeterminePPA from 'hooks/useDeterminePPA';
import { getProducts } from 'reducers/overview';
import ActiveContracts from './contracts/ActiveContracts';
import OutageReporting from './outage/OutageReporting';
import OverviewBanner from './banner/OverviewBanner';
import Support from './support/Support';
import News from './news/News';

const OverviewPage = () => {
  const dispatch = useDispatch();
  const { currentCompanyId } = useSelector(state => state.auth);
  const { products } = useSelector(state => state.overview);

  const [isNotificationShowing, setisNotificationShowing] = useState(false);
  const Alert = Messages.VIEW_ONLY_OVERVIEW;

  const isPPA = useDeterminePPA(products);

  useEffect(() => {
    const fetchProducts = () => {
      dispatch(getProducts());
    };

    fetchProducts();
  }, [dispatch, currentCompanyId]);

  useEffect(() => {
    const shouldShowAlert = !hasUserSeenAlert(Alert.name);
    if (isNotificationShowing !== shouldShowAlert) {
      setisNotificationShowing(shouldShowAlert);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyId]);

  return (
    <>
      {isPPA ? (
        <OverviewBanner
          isNotificationShowing={isNotificationShowing}
          setAlertViewedStatus={setAlertViewedStatus}
          setisNotificationShowing={setisNotificationShowing}
          alert={Alert}
        />
      ) : null}
      <PageHeader>
        <PageTitle title="Dashboard" />
        {isPPA ? <OutageReporting /> : null}
      </PageHeader>
      <CenterWithMargin>
        <div className="overview">
          <Row tag="section" className="overview__contract-container">
            <Col modifier="col-xs-12 col-sm-7">
              <ActiveContracts />
            </Col>
            <Col modifier="col-xs-12 col-sm-5">
              <Support />
            </Col>
          </Row>
          <Row tag="section" modifier="between-sm">
            <Col modifier="col-xs-12">
              <News />
            </Col>
          </Row>
        </div>
      </CenterWithMargin>
    </>
  );
};

const Overview = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <OverviewPage />
    </Fragment>
  );
};

export default Overview;
