import React from 'react';
import PropTypes from 'prop-types';
import history from 'utils/history';
import { clearCookies } from 'utils/storage';

import ICONS from 'utils/icons';

import { Button, Text, Icon } from 'components';

export const errorData = {
  title: {
    '404-public': 'Sorry, this page doesn’t exist',
    '404': 'Sorry, this page doesn’t exist',
    '500': 'Sorry, something went wrong'
  },
  message: {
    '404-public': 'Please login to use the portal',
    '404': 'Go back to the dashboard or check the entered URL',
    '500': 'We are working on fixing the issue.'
  },
  messageTwo: {
    '500': 'Please try again later.'
  },
  code: {
    '404-public': '404',
    '404': '404',
    '500': '500'
  },
  buttonName: {
    '404-public': 'button-404-public',
    '404': 'button-404-page',
    '500': 'button-500-page'
  },
  buttonText: {
    '404-public': 'Login',
    '404': 'Go to dashboard',
    '500': 'Reload page'
  },
  onClick: {
    '404-public': () => history.push('/login'),
    '404': link => history.push(link),
    '500': () => window.location.reload()
  }
};

const ErrorPage = ({ type, redirectLink }) => {
  return (
    <div className="error-container">
      <div className="error-box">
        <div className="errorIcon">
          <Icon
            viewBox="0 0 83 61"
            height={61}
            width={83}
            icon={ICONS.PAGE_ERROR}
            color="#5E6E69"
            fill="#5E6E69"
            strokeWidth={0}
          />
        </div>
        <Text
          className="center errorTitle"
          tag="h3"
          content={errorData.title[type]}
        />
        <Text className="center" content={errorData.message[type]} />
        <Text className="center" content={errorData.messageTwo[type]} />
        <Text
          className="center errorCode"
          content={`Error code: ${errorData.code[type]}`}
        />
        <Button
          text={errorData.buttonText[type]}
          variant="secondary"
          name={errorData.buttonName[type]}
          onClick={() => {
            if (type === '404') {
              // TO DO: find out if users hits 404 page because an invalid company id or if an incorrect URL
              clearCookies();
              return errorData.onClick[type](redirectLink);
            }
            return errorData.onClick[type]();
          }}
        />
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  type: PropTypes.string,
  // eslint-disable-next-line consistent-return
  redirectLink(props, propertyName, componentName) {
    if (props.type === '404' && typeof props[propertyName] !== 'string') {
      return new Error(
        `Invalid prop ${props[propertyName]} supplied to ${componentName}. Please provide a valid prop`
      );
    }
  }
};

ErrorPage.defaultProps = {
  type: '500',
  redirectLink: null
};

export default ErrorPage;
