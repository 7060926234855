import { Messages } from 'features';
import OverviewBanner from 'features/overview/banner/OverviewBanner';
import useDeterminePPA from 'hooks/useDeterminePPA';
import React, { Fragment, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { getProducts } from 'reducers/overview';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getCurrentCompanyId } from 'reducers/selectors';
import { hasUserSeenAlert, setAlertViewedStatus } from 'utils/storage';

import Iframe from '../components/iframe';

const Forecast = props => {
  const dispatch = useDispatch();

  const [isNotificationShowing, setisNotificationShowing] = useState(false);
  const Alert = Messages.VIEW_ONLY_FORECAST;
  const { currentCompanyId } = useSelector(state => state.auth);
  const { products } = useSelector(state => state.overview);
  const isPPA = useDeterminePPA(products);

  useEffect(() => {
    const fetchProducts = () => {
      dispatch(getProducts());
    };

    fetchProducts();
  }, [dispatch, currentCompanyId]);

  useEffect(() => {
    const shouldShowAlert = !hasUserSeenAlert(Alert.name);
    if (isNotificationShowing !== shouldShowAlert) {
      setisNotificationShowing(shouldShowAlert);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyId]);
  return (
    <Fragment>
      <Helmet>
        <title>Forecast</title>
      </Helmet>
      {isPPA ? (
        <OverviewBanner
          isNotificationShowing={isNotificationShowing}
          setAlertViewedStatus={setAlertViewedStatus}
          setisNotificationShowing={setisNotificationShowing}
          alert={Alert}
          company={currentCompanyId}
        />
      ) : null}
      <Iframe
        url={`${process.env.REACT_APP_SIDEBARLESS_PORTAL_BASE_URL}/#/forecast/weekly?iframe=true`}
        title="Forecast"
        companyId={currentCompanyId}
      />
    </Fragment>
  );
};

const mapStateToProps = state => {
  const currentCompanyId = getCurrentCompanyId(state);
  return {
    currentCompanyId
  };
};

export default connect(mapStateToProps, null)(Forecast);
