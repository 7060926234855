import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Panel = ({ className, children, tag: Element }) => {
  const styles = classnames('lj-panel', className);

  return <Element className={styles}>{children}</Element>;
};

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element
  ]).isRequired,
  className: PropTypes.string,
  tag: PropTypes.string
};

Panel.defaultProps = {
  className: '',
  tag: 'div'
};

export default Panel;
