import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import store from 'app-store';

import { DropDown, Text, InterstitialError } from 'components';

import { getPermittedRoutes } from 'reducers/auth';

const renderSelectCompanyComp = (companies, history, options) => {
  switch (companies.length) {
    case 0:
      return (
        <InterstitialError
          message="Your account manager will contact you when your information is available."
          title="Sorry, there is nothing to show just yet"
        />
      );

    case 1:
      return (
        <OneCompanyRedirect
          getPermittedRoutes={getPermittedRoutes}
          companyId={companies[0].id}
        />
      );

    default:
      return (
        <div className="select-company">
          <Text content="Please select a company to continue" />
          <DropDown
            onChange={async ({ value }) => {
              await getPermittedRoutes(store, value);
              history.push(`/${value}/overview`);
            }}
            options={options}
            placeholder="Search company"
            id="companies"
            menuIsOpen
            isSearchable
            noOptionsText="Company not found"
            variant="primary"
          />
        </div>
      );
  }
};

const OneCompanyRedirect = ({ getPermittedRoutes, companyId }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const getData = async () => {
      await getPermittedRoutes(store, companyId);
      setIsLoading(false);
    };
    getData();
  }, [companyId, getPermittedRoutes]);

  if (isLoading) return null;
  return <Redirect to={`/${companyId}/overview`} />;
};

const SelectCompanyPage = props => {
  const { companies, history } = props;
  const options = companies.map(a => ({ value: a.id, label: a.name }));

  return renderSelectCompanyComp(companies, history, options);
};

const mapStateToProps = state => {
  const { companies } = state.auth;
  return { companies };
};

export default connect(mapStateToProps)(SelectCompanyPage);
