import React from 'react';

export default () => {
  return (
    <div className="loader-bar__container">
      <div className="loader-bar__1 loader-bar" />
      <div className="loader-bar__2 loader-bar" />
    </div>
  );
};
