import React from 'react';
import { Row, Text, RequestError } from 'components';

const ContractsError = () => (
  <div className="overview-error">
    <Row modifier="between-xs">
      <Text tag="h3" content="Your products" />
    </Row>
    <RequestError message="Couldn't fetch your products" />
  </div>
);

export default ContractsError;
