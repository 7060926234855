import React from 'react';
import { Route } from 'react-router-dom';
import { BgImage } from 'components';

const PublicRoute = ({ component: Component }) => {
  const style = {
    background: '#2A2F32',
    backgroundSize: 'cover',
    height: '100vh'
  };

  return (
    <Route exact>
      <BgImage style={style}>
        <Component />
      </BgImage>
    </Route>
  );
};

export default PublicRoute;
