import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../spinner';

class IframeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.keyCounter = 0;
  }

  shouldComponentUpdate(newProps) {
    return (
      this.props.url !== newProps.url ||
      this.props.companyId !== newProps.companyId
    );
  }

  render() {
    this.keyCounter += 1;
    const { title, url, companyId, ...props } = this.props;
    return (
      <Iframe
        title={title}
        url={url}
        companyId={companyId}
        key={this.keyCounter}
        {...props}
      />
    );
  }
}

const iframeUrlRegex = /https?:\/\/(www\.)?[-a-zA-Z\d@:%._+~#=]{1,256}\.[a-zA-Z\d()]{1,6}\b([-a-zA-Z\d()@:%_+.~#?&//=]*)(\?|&)iframe=true/;
IframeContainer.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props, consistent-return
  url: (props, propertyName, componentName) => {
    if (!iframeUrlRegex.test(props[propertyName])) {
      return new Error(
        `Invalid prop \`${propertyName}\` supplied to ${componentName}. Validation failed. Required to be a valid url ending in iframe=true`
      );
    }
  },
  companyId: PropTypes.number.isRequired
};

class Iframe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  shouldComponentUpdate(newProps, newState) {
    return (
      this.props.url !== newProps.url ||
      this.props.companyId !== newProps.companyId ||
      this.state.loading !== newState.loading
    );
  }

  loadingComplete = () => {
    this.setState({
      loading: false
    });
  };

  render() {
    const { title, url, companyId, ...props } = this.props;

    // In order to prevent iFrames being cached at a URL level we add a random parameter to the end of the URL.
    // This technique only works in combination with the content explicitly having HTTP headers with caching disabled.
    const cacheBustingUrl = `${url}&timestamp=${Date.now()}&companyid=${companyId}`;

    return (
      <div className="iframe-container">
        {this.state.loading ? <Spinner id="iframe-spinner" /> : null}
        <iframe
          {...props}
          src={cacheBustingUrl}
          title={title}
          onLoad={this.loadingComplete}
          // The company id passed to the iframe so when the company changes, the iframe component
          // is forced to rerender and the iframe reloads
          data-company-id={companyId}
        />
      </div>
    );
  }
}

export default IframeContainer;
