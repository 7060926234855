import { createStore, applyMiddleware, combineReducers } from 'redux';
// eslint disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleWare from 'redux-saga';
import * as Sentry from '@sentry/browser';

import {
  auth,
  reset,
  rootSaga,
  overview,
  news,
  invoices,
  notifications,
  availability
} from './reducers';

const developmentMiddlewares = [];
const sagaMiddleware = createSagaMiddleWare({
  onError(error) {
    Sentry.captureException(error);
  }
});

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line  global-require
  const { logger } = require('redux-logger');

  developmentMiddlewares.push(logger);
}

const reducers = combineReducers({
  auth,
  reset,
  overview,
  news,
  invoices,
  notifications,
  availability
});

const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(...developmentMiddlewares, sagaMiddleware)
  )
);

sagaMiddleware.run(rootSaga);

export default store;
