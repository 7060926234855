import { call, put, takeLatest } from 'redux-saga/effects';
import { emailToResetRequest, passwordToResetRequest } from 'services/reset';
import { createError } from 'reducers/error';

export const UPDATE_RESET_USER_EMAIL = 'UPDATE_RESET_USER_EMAIL';
export const UPDATE_RESET_USER_PASSWORD1 = ' UPDATE_RESET_USER_PASSWORD1';
export const UPDATE_RESET_USER_PASSWORD2 = ' UPDATE_RESET_USER_PASSWORD2';
export const SEND_EMAIL_TO_RESET = 'SEND_EMAIL_TO_RESET';
export const RESET_FAILURE = 'RESET_FAILURE';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const SEND_PASSWORD_TO_RESET = 'SEND_PASSWORD_TO_RESET';
export const PASSWORD_MATCH_ERROR = 'PASSWORD_MATCH_ERROR';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const SET_CURRENT_VIEW = 'SET_CURRENT_VIEW';
export const CLEAR_PASSWORD_RESET_EMAIL = 'CLEAR_PASSWORD_RESET_EMAIL';

/**
 * STATE
 */

const initialState = {
  email: '',
  password1: '',
  password2: '',
  error: '',
  currentView: ''
};

export const reset = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RESET_USER_EMAIL: {
      return {
        ...state,
        ...{
          email: action.payload
        }
      };
    }

    case RESET_FAILURE: {
      return {
        ...state,
        ...{
          error: action.payload
        }
      };
    }

    case RESET_SUCCESS: {
      const { email, currentView } = action.payload;

      return {
        ...state,
        ...{
          email,
          currentView
        }
      };
    }

    case UPDATE_RESET_USER_PASSWORD1: {
      return {
        ...state,
        ...{
          password1: action.payload
        }
      };
    }

    case UPDATE_RESET_USER_PASSWORD2: {
      return {
        ...state,
        ...{
          password2: action.payload
        }
      };
    }

    case PASSWORD_MATCH_ERROR: {
      return {
        ...state,
        ...{
          error: "Password don't match"
        }
      };
    }

    case SET_CURRENT_VIEW: {
      return {
        ...state,
        ...{
          currentView: action.payload
        }
      };
    }

    case CLEAR_PASSWORD_RESET_EMAIL: {
      return {
        ...state,
        ...{
          email: ''
        }
      };
    }

    case RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        error: action.payload
      };
    }

    case RESET_PASSWORD_SUCCESS: {
      const { currentView } = action.payload;

      return {
        ...state,
        currentView
      };
    }

    default:
      return state;
  }
};

/**
 * ACTIONS
 */

export const handleChangeResetEmail = email => {
  return {
    type: UPDATE_RESET_USER_EMAIL,
    payload: email
  };
};

export const handleChangePassword1 = password1 => {
  return {
    type: UPDATE_RESET_USER_PASSWORD1,
    payload: password1
  };
};

export const handleChangePassword2 = password2 => {
  return {
    type: UPDATE_RESET_USER_PASSWORD2,
    payload: password2
  };
};

export const setCurrentView = view => {
  return {
    type: SET_CURRENT_VIEW,
    payload: view
  };
};

export function sendResetemail(email) {
  return {
    type: SEND_EMAIL_TO_RESET,
    email
  };
}

export function resetPassword(password1, password2, uid, token) {
  return {
    type: SEND_PASSWORD_TO_RESET,
    password1,
    password2,
    uid,
    token
  };
}

export function passwordMatchError() {
  return {
    type: PASSWORD_MATCH_ERROR,
    error: "Password don't match"
  };
}

function success(response) {
  return {
    type: RESET_SUCCESS,
    payload: response
  };
}

function emailResetFailure(error) {
  return {
    type: RESET_FAILURE,
    payload: error
  };
}

function passwordResetSuccess(response) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: response
  };
}

export function passwordResetFailure(error) {
  return {
    type: RESET_PASSWORD_FAILURE,
    payload: error
  };
}

export function clearPasswordResetEmail() {
  return {
    type: CLEAR_PASSWORD_RESET_EMAIL
  };
}

/**

 * ASYNC ACTIONS
 */

/* eslint-disable no-undef */
export function* emailRequest({ email } = action) {
  try {
    yield call(emailToResetRequest, [email]);

    yield put(
      success({
        email,
        currentView: 'PASSWORD_REQUEST_SUCCESS'
      })
    );
  } catch (error) {
    yield put(emailResetFailure(error.message));
    yield put(createError(error));
  }
}

export function* passwordResetRequest({
  password1,
  password2,
  uid,
  token
} = action) {
  try {
    yield call(passwordToResetRequest, [password1, password2, uid, token]);

    yield put(
      passwordResetSuccess({
        currentView: 'PASSWORD_RESET_SUCCESS'
      })
    );
  } catch (error) {
    yield put(passwordResetFailure(error.message));
    yield put(createError(error));
  }
}

export function* watchPasswordReset() {
  yield takeLatest(SEND_PASSWORD_TO_RESET, passwordResetRequest);
}

export function* watchEmailReset() {
  yield takeLatest(SEND_EMAIL_TO_RESET, emailRequest);
}

export default reset;
