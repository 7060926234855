import React from 'react';

import {
  InterstitialError,
  Skeleton,
  Row,
  Col,
  Text,
  ErrorBoundary
} from 'components';

import { createRange } from 'utils/helpers';
import { InvoiceHeaderTitle } from './InvoiceHeader';

export const InvoiceErrorComponent = () => {
  return (
    <InterstitialError
      title="Sorry, something went wrong on our end"
      message="We couldn’t fetch your invoice."
      subMessage=" Please try again later"
      buttonText="Reload Page"
      buttonName="button-assets-list-unavailable"
      buttonOnClick={() => window.location.reload()}
    />
  );
};

export const InvoiceSkeleton = () => (
  <div className="invoice">
    <InvoiceHeaderLoading />
    <InvoiceTableLoading />
    <InvoiceFooterLoading />
  </div>
);

const InvoiceFooterLoading = () => {
  return (
    <section className="invoice_footer invoice_footer--loading">
      <Row className="invoice_footer-container-address" modifier="between-xs">
        <Col modifier="col-xs-6 col-sm-3">
          <Text className="footer-title" content="INVOICE GENERATED BY" />
          <Text className="footer-title" content="REGISTERED NUMBER" />
          <Text className="footer-title" content="VAT NUMBER" />
        </Col>
        <Col modifier="col-xs-6 col-sm-3">
          {createRange(3).map((item, index) => (
            <Skeleton
              className="invoice_header-content__address--loading"
              width="200px"
              height="19px"
              keyPrefix={`footeraddress1-${index}`}
            />
          ))}
        </Col>
        <Col modifier="col-xs-6 col-sm-3">
          <Text className="footer-title" content="REGISTERED ADDRESS" />
        </Col>
        <Col modifier="col-xs-6 col-sm-3">
          {createRange(3).map((item, index) => (
            <Skeleton
              className="invoice_header-content__address--loading"
              width="200px"
              height="19px"
              keyPrefix={`footeraddress2-${index}`}
            />
          ))}
        </Col>
      </Row>
      <Row className="invoice_footer-container-tax" modifier="between-xs">
        <Text content="The Tax shown is your output tax due to HRMC." />
      </Row>
      <Row className="invoice_footer-container-contact" modifier="between-xs">
        <Skeleton
          className="invoice_header-content__address--loading"
          width="250px"
          height="19px"
        />
      </Row>
    </section>
  );
};

const InvoiceHeaderLoading = () => (
  <section className="invoice_header invoice_header--loading">
    <InvoiceHeaderTitle />
    <Row className="invoice_header-content" modifier="between-xs">
      <div className="invoice_header-content__cards">
        <div>
          <span className="invoice_header-content__card-invoice" />
          <span className="invoice_header-content__card-amount" />
          <span className="invoice_header-content__card-tax" />
        </div>
      </div>
      <div className="invoice_header-content__address">
        {createRange(5).map((item, index) => (
          <Skeleton
            className="invoice_header-content__address--loading"
            width="249px"
            height="19px"
            keyPrefix={`invoiceheader-${index}`}
          />
        ))}
      </div>
    </Row>
  </section>
);

const InvoiceTableLoading = () => (
  <section className="invoice_table invoice_table--loading">
    <table className="lj-table">
      <thead className="lj-table__head invoice_table__head">
        <tr className="lj-table__head-row">
          {[
            {
              'name': 'Asset',
              'key': 'asset'
            },
            {
              'name': 'Site',
              'key': 'site'
            },
            {
              'name': 'Product',
              'key': 'product'
            },
            {
              'name': 'Time Period',
              'key': 'time-period'
            },
            {
              'name': 'Amount (£)',
              'key': 'amount'
            }
          ].map(h => (
            <th key={h.key} className="lj-table__head-cell">
              {h.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="lj-table__body invoice_table__body">
        {createRange(10).map((r, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={index} className="lj-table__body-row">
            {createRange(5).map((cell, index) => (
              <td
                // eslint-disable-next-line react/no-array-index-key
                key={`${cell.key}-${index}`}
                className="lj-table__body-cell"
              >
                <Skeleton width="72px" height="19px" />
              </td>
            ))}
          </tr>
        ))}
        <tr className="lj-table__body-row invoice_table__body--net">
          {createRange(3).map(() => (
            <td className="lj-table__body-cell invoice_table__cell-summary" />
          ))}
          <td className="lj-table__body-cell invoice_table__cell-summary-name">
            Net
          </td>
          <td className="lj-table__body-cell invoice_table__cell-summary-amount">
            <Skeleton width="72px" height="19px" />
          </td>
        </tr>

        <tr className="lj-table__body-row invoice_table__body--vat">
          {createRange(3).map(() => (
            <td className="lj-table__body-cell invoice_table__cell-summary" />
          ))}
          <td className="lj-table__body-cell invoice_table__cell-summary-name">
            VAT
          </td>
          <td className="lj-table__body-cell invoice_table__cell-summary-amount">
            <Skeleton width="72px" height="19px" />
          </td>
        </tr>

        <tr className="lj-table__body-row invoice_table__body--gross">
          {createRange(3).map(() => (
            <td className="lj-table__body-cell invoice_table__cell-summary" />
          ))}
          <td className="lj-table__body-cell invoice_table__cell-summary-name">
            Gross
          </td>
          <td className="lj-table__body-cell invoice_table__cell-summary-amount">
            <Skeleton width="72px" height="19px" />
          </td>
        </tr>
      </tbody>
    </table>
  </section>
);

export const InvoiceErrorBoundary = () => (
  <ErrorBoundary errorComponent={InvoiceErrorComponent} />
);
