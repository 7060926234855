// these constants are used to switch between different
// views on the invoices page

// The default option for the invoices dropdown
export const ALL_OPTION = { label: 'All', value: 'all' };

// Invoice types
export const PPA_INVOICE = 'ppaexport';

export const TRIAD_PRODUCT = 'Triad';

export const CREDIT_NOTE = 'CREDIT_NOTE';
