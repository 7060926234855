import { takeLatest, select, spawn } from 'redux-saga/effects';

import { getCurrentAlertName } from 'reducers/selectors';
import { setAlertViewedStatus } from 'utils/storage';
import { Messages } from 'features';

export const CLOSE_ALERT = 'CLOSE_ALERT';
export const UPDATE_ALERT_CONTENT = 'UPDATE_ALERT_CONTENT';

/**
 * STATE
 */

const initialState = {
  showAlert: false,
  alertName: ''
};

export const notifications = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_ALERT: {
      return {
        ...state,
        showAlert: false
      };
    }

    case UPDATE_ALERT_CONTENT: {
      return {
        ...state,
        alertName: action.payload,
        showAlert: true
      };
    }

    default:
      return state;
  }
};

/**
 * ACTIONS
 */

export const closeAlert = () => {
  return {
    type: CLOSE_ALERT
  };
};

export const updateAlertContent = payload => {
  return {
    type: UPDATE_ALERT_CONTENT,
    payload
  };
};

/**
 * SAGAS
 */
// eslint-disable-next-line no-undef
export function* closeAlertSaga() {
  const alertName = yield select(getCurrentAlertName);
  const alertViewType = Messages[alertName].viewType;

  if (alertViewType === 'once') yield spawn(setAlertSeen, alertName);
}

// in the future this will be sending messages to the BFF
// eslint-disable-next-line consistent-return
export function setAlertSeen(alertName) {
  setAlertViewedStatus(alertName, true);
}

//
// WATCHERS
//

export function* watchAlertClose() {
  yield takeLatest(CLOSE_ALERT, closeAlertSaga);
}

export default notifications;
