import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const InputLoading = () => {
  return (
    <div className="lj-input--loading">
      <div className="lj-input--loading__bar1 lj-input--loading__bar" />
      <div className="lj-input--loading__bar2 lj-input--loading__bar" />
    </div>
  );
};

const Input = props => {
  const {
    id,
    onChange,
    type,
    value,
    variant,
    isLoading,
    symbol,
    placeholder
  } = props;
  const variantClass = `lj-input--${variant}`;
  const className = classnames({
    'lj-input': true,
    [variantClass]: true
  });

  return (
    <div className="lj-input_container">
      {isLoading && <InputLoading />}
      <input
        className={className}
        onChange={onChange}
        type={type}
        id={id}
        value={value}
        placeholder={placeholder}
        {...props} // TO DO: how do we handle spreading non-html attributes on to the element?
      />
      {symbol && <span className="lj-input__symbol">{symbol}</span>}
    </div>
  );
};

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['email', 'password', 'text', 'number', 'checkbox']),
  variant: PropTypes.oneOf(['light', 'dark']),
  symbol: PropTypes.string,
  placeholder: PropTypes.string
};

Input.defaultProps = {
  type: 'text',
  value: '',
  variant: 'light',
  symbol: '',
  placeholder: ''
};

export default Input;
