import React from 'react';
import PropTypes from 'prop-types';

import ICONS from 'utils/icons';
import Text from '../text';
import Icon from '../icon';

const FormError = ({ error, variant }) => {
  return (
    <span className={`lj-form-error lj-form-error--${variant}`}>
      <Icon strokeWidth={1} icon={ICONS.ERROR} height={14} width={14} />
      <Text className="lj-form-error__text" content={error} />
    </span>
  );
};

FormError.propTypes = {
  error: PropTypes.string,
  variant: PropTypes.string
};
FormError.defaultProps = {
  error: '',
  variant: ''
};
export default FormError;
