import React, { useMemo } from 'react';
import { format, subMonths } from 'date-fns';

import { TableMenu, ExternalLink } from 'components';

export default ({ row }) => {
  const selectedRow = row.original;

  const finalBackingDataDisabled = !selectedRow.final_backing_data_url;
  const indicactiveBackingDataDisabled = !selectedRow.indicative_backing_data_url;

  const { isPPA, isTriad } = useMemo(() => {
    const isPPA = selectedRow.product.includes('PPA');
    const isTriad = selectedRow.product.includes('Triad');

    return { isPPA, isTriad };
  }, [selectedRow.product]);

  const month = new Date(selectedRow.billing_period.start);

  const invoiceUrl =
    selectedRow.invoice_pdf ||
    `invoice/${selectedRow.invoice_id}?type=${selectedRow.product_type}`;

  const backingDataText = useMemo(() => {
    const previousMonth = subMonths(
      new Date(selectedRow.billing_period.start),
      1
    );

    if (finalBackingDataDisabled) return 'Final Backing Data not yet available';

    if (isTriad) {
      const { start: startDate, end: endDate } = selectedRow.billing_period;

      const billingPeriodSeason =
        new Date(endDate).getMonth() === 2 ? 'Winter' : 'Summer';

      return `Download Final Backing Data for ${billingPeriodSeason} ${new Date(
        startDate
      ).getFullYear()}`;
    }

    if (isPPA) {
      return `Download Final Backing Data for ${format(previousMonth, 'MMMM')}`;
    }

    return 'Final Backing Data not found';
  }, [isPPA, isTriad, selectedRow.billing_period, finalBackingDataDisabled]);

  return (
    <TableMenu key={selectedRow.id}>
      <ul>
        <li>
          <ExternalLink url={invoiceUrl} variant="dropdown">
            View Document
          </ExternalLink>
        </li>
        {isPPA ? (
          <li>
            <ExternalLink
              variant="dropdown"
              url={`${selectedRow.indicative_backing_data_url}`}
              disabled={indicactiveBackingDataDisabled}
            >
              {indicactiveBackingDataDisabled
                ? 'Indicative Backing data not found'
                : `Download Indicative Backing Data for ${format(
                    month,
                    'MMMM'
                  )} `}
            </ExternalLink>
          </li>
        ) : null}

        {isPPA || isTriad ? (
          <li>
            <ExternalLink
              variant="dropdown"
              url={`${selectedRow.final_backing_data_url}`}
              disabled={finalBackingDataDisabled}
            >
              {backingDataText}
            </ExternalLink>
          </li>
        ) : null}
      </ul>
    </TableMenu>
  );
};
