import React from 'react';
import { connect } from 'react-redux';

import { getCurrentCompany } from 'reducers/selectors';
import { Text, DropDown } from 'components/';

const _SelectCompany = ({ companies, currentCompanyName, path, history }) => {
  const options = companies.map(c => ({ value: c.id, label: c.name }));

  // This check prevents the component from displaying when no company has been selected;
  // for example, on the select company page.
  // It assumes that all companies have a name.
  return (
    currentCompanyName && (
      <>
        <Text content={currentCompanyName} id="header-username" />
        {companies.length > 1 && (
          <DropDown
            onChange={option => {
              const newPath = path.replace(':companyId', option.value);
              console.debug('Pushing new path', newPath);
              history.push(newPath);
            }}
            options={options}
            value={{
              label: 'Switch Company',
              value: null
            }}
            isSearchable
          />
        )}
      </>
    )
  );
};

const mapStateToProps = state => {
  const { companies } = state.auth;
  const currentCompany = getCurrentCompany(state);
  const currentCompanyName = currentCompany ? currentCompany.name : '';
  return {
    companies,
    currentCompanyName
  };
};

export const SelectCompany = connect(mapStateToProps)(_SelectCompany);

const HeaderWithDropdown = props => {
  return (
    <header className="header">
      <div className="header-logo" />
      <div className="header-info">
        <SelectCompany history={props.history} path={props.match.path} />
      </div>
    </header>
  );
};

export const PlainHeader = () => (
  <header className="header">
    <div className="header-logo" />
  </header>
);

export default HeaderWithDropdown;
