import Cookies from 'js-cookie';
import store from 'store';

/** COOKIE STORAGE  * */

// names of all LJ cookies
export const USER_COOKIE_NAME = 'lj-portal-user';
export const AUTH_TOKEN_COOKIE_NAME = 'lj-portal-token';
export const PARENT_DOMAIN = '.limejump.com';

// Splits the iFrame domain on the first instance of '.'.
// i.e. portal.staging.limejump.io -> staging.limejump.io
const sharedDomain = process.env.REACT_APP_SIDEBARLESS_PORTAL_BASE_URL.slice(
  Math.max(
    0,
    process.env.REACT_APP_SIDEBARLESS_PORTAL_BASE_URL.indexOf('.') + 1
  )
);

const cookieOptions = {
  expires: 365,
  domain: sharedDomain,
  secure: true,
  samesite: 'none'
};

// The gateway JWT expiry is currently hardcoded at 12 hours
const authCookieOptions = {
  ...cookieOptions,
  expires: 0.5
};

// Ensure when developing locally a token is still set so that auth works.
// Note that this cookie will not be shared with iFrames, but that local React functionality will still work.
const setLocalhostCookie = (name, value) => {
  if (process.env.REACT_APP_IS_LOCAL_DEV !== 'true') {
    return;
  }

  Cookies.set(name, value, {
    expires: 365,
    domain: 'localhost'
  });
};

// Functions for setting and getting the different cookies
export const getUserCookie = () => {
  const storedValue = Cookies.get(USER_COOKIE_NAME);
  return storedValue ? JSON.parse(storedValue) : {};
};
export const setUserCookie = currentCompanyId => {
  const cookieValue = {
    currentCompanyId
  };
  Cookies.set(USER_COOKIE_NAME, cookieValue, cookieOptions);
  setLocalhostCookie(USER_COOKIE_NAME, cookieValue);
};

export const getAuthTokenCookie = () => Cookies.get(AUTH_TOKEN_COOKIE_NAME);
export const setAuthTokenCookie = value => {
  Cookies.set(AUTH_TOKEN_COOKIE_NAME, value, authCookieOptions);
  setLocalhostCookie(AUTH_TOKEN_COOKIE_NAME, value);
};

/**
 * Removes all localhost and non-localhost application cookies
 */
export const clearCookies = () => {
  const cookieTypes = [USER_COOKIE_NAME, AUTH_TOKEN_COOKIE_NAME];
  cookieTypes.forEach(t => Cookies.remove(t, cookieOptions));

  // We need to clear the user user/token stored at .limejump.com to stop it interfering with the .staging.limejump.com
  cookieTypes.forEach(t =>
    Cookies.remove(t, { ...cookieOptions, domain: PARENT_DOMAIN })
  );

  if (process.env.REACT_APP_IS_LOCAL_DEV === 'true') {
    cookieTypes.forEach(t => Cookies.remove(t));
  }
};

/** LOCAL STORAGE  * */

export const hasUserSeenAlert = alertName => store.get(alertName);
export const setAlertViewedStatus = (name, value) => store.set(name, value);
