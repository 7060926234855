import React from 'react';
import { Row, Text, Tooltip, RequestError } from 'components';

const ContractsTooltip = () => (
  <span className="overview-contracts__tooltip">
    <p>Expires soon. Contact</p> <p>accounts@limejump.com</p>{' '}
    <p>for further information.</p>
  </span>
);

const ContractsPage = ({ products }) => {
  if (products.length === 0) {
    return (
      <section className="overview-error">
        <Row modifier="between-xs">
          <Text tag="h3" content="Your products" />
        </Row>
        <RequestError message="No active contracts found" />
      </section>
    );
  }

  return (
    <section className="overview-contracts">
      <Row modifier="between-xs">
        <Text tag="h3" content="Your products" />
        <Text tag="h3" content="Nearest contract end date" />
      </Row>

      {products.map((product, i) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <Row key={i} modifier="between-xs">
          <Text key={product.type} content={product.type} />
          {product.isEndingSoon ? (
            <Tooltip content={<ContractsTooltip />} placement="left-start">
              <Text
                key={product.contractEndDate}
                content={product.contractEndDate}
                className="overview-contracts__date--ending"
              />
            </Tooltip>
          ) : (
            <Text
              key={product.contractEndDate}
              content={product.contractEndDate}
              className="overview-contracts__date"
            />
          )}
        </Row>
      ))}
    </section>
  );
};

export default ContractsPage;
