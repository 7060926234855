import React from 'react';
import { useTable, useExpanded, useFlexLayout, useSortBy } from 'react-table';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';

import { Text } from 'components';
import LoadingTable from './TableLoading';
import TableBody from './TableBody';
import TableHeader from './TableHeader';

const resetRows = rows => {
  rows.forEach(r => {
    r.toggleRowExpanded(false);
  });
};

const Table = ({
  columns,
  data,
  pageSize,
  isLoading,
  noDataMessage,
  sortByData
}) => {
  const initialSortBy = sortByData
    ? [sortByData]
    : [
        {
          id: 'product',
          desc: true
        }
      ];
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: initialSortBy
      }
    },
    useSortBy,
    useExpanded,
    useFlexLayout
  );

  if (isLoading) {
    return (
      <table className="lj-table">
        <TableHeader headerGroups={headerGroups} />
        <LoadingTable
          prepareRow={prepareRow}
          rows={rows}
          getTableBodyProps={getTableBodyProps}
        />
      </table>
    );
  }

  // we could use optional chaining here
  if (data && data.length === 0) {
    return (
      <table className="lj-table">
        <TableHeader headerGroups={headerGroups} />
        <tbody className="lj-table__body" {...getTableBodyProps()}>
          <Text className="lj-table__body__no-data" content={noDataMessage} />
        </tbody>
      </table>
    );
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        resetRows(rows);
      }}
    >
      <table className="lj-table" {...getTableProps()}>
        <TableHeader headerGroups={headerGroups} />
        <TableBody
          prepareRow={prepareRow}
          rows={rows}
          getTableBodyProps={getTableBodyProps}
          resetRows={resetRows}
        />
      </table>
    </OutsideClickHandler>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.string.isRequired,
      billing_period: PropTypes.objectOf(PropTypes.string).isRequired,
      final_backing_data_url: PropTypes.string.isRequired,
      indicative_backing_data_url: PropTypes.string.isRequired,
      invoice_id: PropTypes.number.isRequired,

      product_type: PropTypes.string.isRequired,
      issue_date: PropTypes.string.isRequired,
      mpans: PropTypes.arrayOf(PropTypes.string).isRequired,
      name: PropTypes.string.isRequired,
      product: PropTypes.string.isRequired,
      sites: PropTypes.arrayOf(PropTypes.string).isRequired,
      tax_point: PropTypes.string.isRequired
    })
  ),
  columns: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        Header: PropTypes.string.isRequired,
        accessor: PropTypes.string.isRequired,
        Cell: PropTypes.func
      })
    ])
  ).isRequired,
  pageSize: PropTypes.number,
  isLoading: PropTypes.bool,
  noDataMessage: PropTypes.string.isRequired,
  sortByData: PropTypes.shape({
    id: PropTypes.string,
    desc: PropTypes.bool
  })
};

Table.defaultProps = {
  data: [],
  isLoading: false,
  pageSize: null,
  sortByData: [
    {
      id: 'product',
      desc: true
    }
  ]
};

export default Table;
