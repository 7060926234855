import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import ICONS from 'utils/icons';
import LoadingBar from 'components/loader';
import { Icon } from 'components';

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon
      icon={ICONS.DROPDOWN_ARROW}
      strokeWidth={0}
      width={20}
      height={20}
      viewBox="0 0 20 20"
    />
  </components.DropdownIndicator>
);

const Option = props => {
  const { label, isSelected } = props;
  return (
    <components.Option {...props}>
      {label}
      {isSelected && (
        <Icon icon={ICONS.CHECKMARK} width={14} height={9} color="#1D2C27" />
      )}
    </components.Option>
  );
};

const DropDown = props => {
  const {
    options,
    value,
    onChange,
    isLoading,
    isDisabled,
    isSearchable,
    placeholder,
    defaultValue,
    id,
    name,
    menuIsOpen,
    variant,
    noOptionsText
  } = props;

  const formatGroupLabel = options => (
    <div>
      <span className="group-title">{options.title}</span>
    </div>
  );

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        {' '}
        {noOptionsText}{' '}
      </components.NoOptionsMessage>
    );
  };

  // If we pass in value without providing it {value: null, label: null} is passed in instead and defaultValue isn't fallen back on by react-select.
  // Passing undefined in that case ensures react-select uses the defaultValue
  const sanitizedValue =
    value.label || value.value || Array.isArray(value) ? value : undefined;

  return (
    <>
      {isLoading && <LoadingBar />}
      <Select
        value={sanitizedValue}
        onChange={onChange}
        options={options}
        isLoading={isLoading}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        placeholder={placeholder}
        className={`lj-dropdown lj-dropdown-${variant}`}
        classNamePrefix="lj-dropdown"
        defaultValue={defaultValue}
        inputId={id}
        name={name}
        formatGroupLabel={formatGroupLabel}
        menuIsOpen={menuIsOpen}
        components={{
          DropdownIndicator,
          NoOptionsMessage,
          Option
        }}
      />
    </>
  );
};

DropDown.propTypes = {
  // The current value of the dropdown.
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.oneOfType([
    PropTypes.exact({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({})
      ]),
      label: PropTypes.string
    }),
    PropTypes.arrayOf(
      PropTypes.exact({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.shape({})
        ]),
        label: PropTypes.string
      })
    )
  ]),
  /** subscribe to the onChange event */
  onChange: PropTypes.func.isRequired,
  /** the options the user can select from */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({})
      ]),
      label: PropTypes.string
    }) ||
      PropTypes.shape({
        title: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.shape({})
        ]),
        options: PropTypes.arrayOf(
          PropTypes.exact({
            value: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.number,
              PropTypes.shape({})
            ]),
            label: PropTypes.string
          })
        )
      })
  ).isRequired,
  /** allow the user to search for matching options */
  isSearchable: PropTypes.bool,
  /** show the loading indicator */
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  /** the text that is displayed when no option is selected */
  placeholder: PropTypes.string,
  /** the initial value of the control */
  defaultValue: PropTypes.oneOfType([
    PropTypes.exact({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({})
      ]),
      label: PropTypes.string
    }),
    PropTypes.arrayOf(
      PropTypes.exact({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.shape({})
        ]),
        label: PropTypes.string
      })
    )
  ]),
  /** the id of the input element */
  id: PropTypes.string,
  /** the name of the input element */
  name: PropTypes.string,
  /** the style of the dropdown */
  variant: PropTypes.oneOf(['primary', 'secondary']),
  /** text to display when no options are found */
  noOptionsText: PropTypes.string
};

// Even though 'value' and 'defaultValue' are not required we do not
// set default values because of how react-select works. If the value
// is not provided, react-select sets it internally. Likewise, if
// defaultValue is not provided, the placeholder is used.
DropDown.defaultProps = {
  value: { label: null, value: null },
  isSearchable: false,
  isLoading: false,
  isDisabled: false,
  defaultValue: null,
  id: '',
  name: '',
  placeholder: 'Please select an option',
  variant: 'primary',
  noOptionsText: 'No options found'
};

export default DropDown;
