import React, { Fragment } from 'react';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { getCurrentCompanyId } from 'reducers/selectors';

import Iframe from '../components/iframe';

const LivePerformance = ({ currentCompanyId }) => {
  return (
    <Fragment>
      <Helmet>
        <title>Live Performance</title>
      </Helmet>
      <Iframe
        url={`${process.env.REACT_APP_SIDEBARLESS_PORTAL_BASE_URL}/#/performance/live?iframe=true`}
        title="Live Performance"
        companyId={currentCompanyId}
      />
    </Fragment>
  );
};
const mapStateToProps = state => {
  const currentCompanyId = getCurrentCompanyId(state);
  return {
    currentCompanyId
  };
};

export default connect(mapStateToProps, null)(LivePerformance);
