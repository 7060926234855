import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { getCurrentCompanyId } from 'reducers/selectors';
import { getAuthTokenCookie } from 'utils/storage';

const BaseRoute = ({ currentCompanyId }) => {
  if (!currentCompanyId || !getAuthTokenCookie()) {
    return <Redirect to="/login" />;
  }

  return <Redirect to={`/${currentCompanyId}/overview`} />;
};

export default connect(state => {
  const currentCompanyId = getCurrentCompanyId(state);
  return { currentCompanyId };
}, null)(BaseRoute);
