import React from 'react';
import PropTypes from 'prop-types';

const Container = ({ className, fluid, children, style }) => {
  const classes = fluid
    ? `container-fluid ${className}`
    : `container ${className}`;

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  fluid: PropTypes.bool,
  style: PropTypes.shape({}),
  className: PropTypes.string
};

Container.defaultProps = {
  fluid: false,
  style: {},
  className: ''
};

export default Container;
