import React, { Fragment } from 'react';
import { format } from 'date-fns';

import { ExternalLink, Text, Tooltip, Icon, Button } from 'components';

import ICONS from 'utils/icons';

import renderInvoiceRowMenu from './InvoicesTableRowMenu';
import { TRIAD_PRODUCT, CREDIT_NOTE } from './constants';

const DATE_FORMAT = 'dd MMM yyyy';
const BILLING_DATE_FORMAT = 'MMM yyyy';

const renderMissing = () => {
  return (
    <Icon
      icon={ICONS.ERROR}
      width={14}
      height={14}
      strokeWidth={1}
      fill="#BDBDBD"
    />
  );
};

export default currentCompanyId => [
  {
    Header: 'Invoice #',
    accessor: 'invoices',
    width: 100,
    Cell: ({ row }) => {
      const selectedRow = row.original;
      if (!selectedRow.name) {
        return renderMissing();
      }
      const invoiceUrl =
        selectedRow.invoice_pdf ||
        `invoice/${selectedRow.invoice_id}?type=${selectedRow.product_type}`;

      return <ExternalLink url={invoiceUrl}>#{row.original.name}</ExternalLink>;
    }
  },
  {
    Header: 'Products',
    accessor: 'product',
    width: 100,

    Cell: ({ cell, row }) => {
      const { value } = cell;
      const newValue = value?.replace('-', '');
      const { product_type: productType } = row.original;
      if (!value) {
        return renderMissing();
      }

      const text = String(newValue);
      return productType?.includes(CREDIT_NOTE) ? (
        <div>
          <Text content={text} />
          <Text className="lj-label" content="CREDIT NOTE" />
        </div>
      ) : (
        text
      );
    }
  },
  {
    Header: 'Site',
    accessor: 'sites',
    width: 100,

    Cell: ({ cell }) => {
      const sites = cell.value;

      if (!sites || sites.length === 0) {
        return renderMissing();
      }

      if (sites.length === 1) {
        return sites[0];
      }
      const sitesList = sites.map(siteAsset => {
        return <Text content={siteAsset} key={siteAsset.toLowerCase()} />;
      });
      const sitesNumber = sites.length;
      return (
        // TO DO: this needs to be a component which is imported from the table file
        // it will be easier to achieve a uniform tooltip look across all table
        // instances

        <Tooltip content={<div>{sitesList}</div>} placement="left-start">
          <Text content={`${sitesNumber} Sites`} />
        </Tooltip>
      );
    }
  },
  {
    Header: 'MPAN',
    accessor: 'mpans',
    width: 150,

    Cell: ({ cell }) => {
      const mpans = cell.value;
      if (!mpans || mpans.length === 0) {
        return renderMissing();
      }

      if (mpans.length === 1) {
        return mpans[0];
      }
      const mpansList = mpans.map(mpan => {
        return <Text content={mpan} />;
      });
      const mpansNumber = mpans.length;
      return (
        // TO DO: this needs to be a component which is imported from the table file
        // it will be easier to achieve a uniform tooltip look across all table
        // instances
        <Tooltip
          content={<div>{mpansList}</div>}
          placement="left-start"
          animation={false}
          arrow={false}
          delay={0}
        >
          <Text content={`${mpansNumber} MPANs`} />
        </Tooltip>
      );
    }
  },
  {
    Header: 'Billing Period',
    accessor: 'billing_period.start',
    width: 100,

    Cell: ({ cell }) => {
      const {
        row: { original },
        value: startDate
      } = cell;

      if (!startDate) {
        return renderMissing();
      }

      if (original && original.product.includes(TRIAD_PRODUCT)) {
        const year = new Date(startDate).getFullYear();
        return `Winter ${year}`;
      }

      return format(new Date(startDate), BILLING_DATE_FORMAT);
    }
  },
  {
    Header: 'Issue Date',
    accessor: 'issue_date',
    width: 100,

    Cell: ({ cell }) => {
      const { value } = cell;
      if (!value) {
        return renderMissing();
      }
      return format(new Date(value), DATE_FORMAT);
    }
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    width: 80,

    Cell: ({ cell }) => {
      const { value } = cell;
      const makeNumber = Number(value);
      const commaValue = makeNumber.toLocaleString();
      if (!value) {
        return renderMissing();
      }
      return `£${commaValue}`;
    }
  },
  {
    id: 'expander',
    width: 40,
    Cell: ({ row }) => {
      return (
        <Fragment>
          <Button
            icon={ICONS.DOTS}
            iconWidth={4}
            iconHeight={20}
            iconLocation="left"
            className="invoices__row-menu"
            name="menu-trigger"
            variant="tertiary-icon"
            focused
          />
          {row.isExpanded
            ? renderInvoiceRowMenu({ row }, currentCompanyId)
            : null}
        </Fragment>
      );
    }
  }
];
