import React from 'react';
import PropTypes from 'prop-types';
import { Text, Row, Col, ExternalLink } from 'components';

const InvoiceFooter = ({
  address,
  vatNumber,
  generatedBy,
  registeredNumber
}) => {
  return (
    <section className="invoice_footer">
      <Row className="invoice_footer-container-address" modifier="between-xs">
        <Col modifier="col-xs-6 col-sm-3">
          <Text className="footer-title" content="INVOICE GENERATED BY" />
          <Text className="footer-title" content="REGISTERED NUMBER" />
          <Text className="footer-title" content="VAT NUMBER" />
        </Col>
        <Col modifier="col-xs-6 col-sm-3">
          <Text content={generatedBy} />
          <Text content={registeredNumber} />
          <Text content={vatNumber} />
        </Col>
        <Col modifier="col-xs-6 col-sm-3">
          <Text className="footer-title" content="REGISTERED ADDRESS" />
        </Col>
        <Col modifier="col-xs-6 col-sm-3">
          <Text content={address.lineOne} />
          <Text content={`${address.lineTwo} ${address.town}`} />
          <Text content={address.postcode} />
        </Col>
      </Row>
      <Row className="invoice_footer-container-tax" modifier="between-xs">
        <Text content="The tax shown is your output tax due to HRMC." />
      </Row>
      <Row className="invoice_footer-container-contact" modifier="center-xs">
        <ExternalLink url={`tel:${address.phoneNumber}`}>
          <Text tag="span" content={address.phoneNumber} />
        </ExternalLink>
        <Text tag="span" content="|" />
        <ExternalLink url={`mailto:${address.email}`}>
          <Text tag="span" content={address.email} />
        </ExternalLink>
      </Row>
    </section>
  );
};

InvoiceFooter.propTypes = {
  address: PropTypes.objectOf(
    PropTypes.shape({
      lineOne: PropTypes.string,
      lineTwo: PropTypes.string,
      town: PropTypes.string,
      postcode: PropTypes.string,
      phoneNumber: PropTypes.string,
      email: PropTypes.string
    })
  ).isRequired,
  vatNumber: PropTypes.string.isRequired,
  registeredNumber: PropTypes.string.isRequired,
  generatedBy: PropTypes.string.isRequired
};

export default InvoiceFooter;
