import React from 'react';
import PropTypes from 'prop-types';

import ICONS from 'utils/icons';

import { Text, Icon, Button, ExternalLink } from 'components';

const SubMessage = ({ subMessageType, subMessage, linkText, linkHref }) => {
  switch (subMessageType) {
    case 'link': {
      return <ExternalLink url={linkHref}>{linkText}</ExternalLink>;
    }

    case 'message': {
      return (
        <Text
          className="center interstitial-error-content"
          content={subMessage}
        />
      );
    }

    default:
      return null;
  }
};

const InterstitialError = ({
  title,
  message,
  subMessage,
  linkText,
  linkHref,
  buttonText,
  buttonName,
  buttonOnClick,
  subMessageType
}) => (
  <div className="interstitial-error-container">
    <div className="interstitial-error-box">
      <div className="interstitial-error-icon">
        <Icon
          viewBox="0 0 83 61"
          height={61}
          width={83}
          icon={ICONS.PAGE_ERROR}
          color="#5E6E69"
          fill="#5E6E69"
          strokeWidth={0}
        />
      </div>
      <Text
        className="center interstitial-error-title"
        tag="h3"
        content={title}
      />
      <Text className="center interstitial-error-content" content={message} />
      {subMessage ? (
        <SubMessage
          subMessageType={subMessageType}
          subMessage={subMessage}
          linkText={linkText}
          linkHref={linkHref}
        />
      ) : null}

      {buttonText ? (
        <Button
          text={buttonText}
          variant="secondary"
          name={buttonName}
          onClick={buttonOnClick}
        />
      ) : null}
    </div>
  </div>
);

InterstitialError.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  subMessage: PropTypes.string,
  /** Custom prop function to allow for disabled links to have null or undefined links. No consistent return expected by proptypes library. */
  // eslint-disable-next-line consistent-return
  linkText(props, propertyName, componentName) {
    if (
      typeof props[propertyName] !== 'string' ||
      typeof props.linkHref !== 'string'
    ) {
      return new Error(
        `Please check that the props ${propertyName} and ${props.linkHref}  passed to component ${componentName} are both strings.`
      );
    }
  },
  // eslint-disable-next-line consistent-return
  linkHref(props, propertyName, componentName) {
    if (
      typeof props[propertyName] !== 'string' ||
      typeof props.linkText !== 'string'
    ) {
      return new Error(
        `Please check that the props ${propertyName} and ${props.linkText}  passed to component ${componentName} are both strings.`
      );
    }
  },
  // eslint-disable-next-line consistent-return
  buttonText(props, propertyName, componentName) {
    if (
      typeof props[propertyName] !== 'string' ||
      (typeof props.buttonName !== 'string' &&
        typeof props.buttonOnClick !== 'function')
    ) {
      return new Error(
        `Please check that the props ${propertyName},  ${props.buttonName}  passed to component ${componentName} are both strings and ${props.buttonOnClick} is a function`
      );
    }
  },
  // eslint-disable-next-line consistent-return
  buttonName(props, propertyName, componentName) {
    if (
      typeof props[propertyName] !== 'string' ||
      (typeof props.buttonText !== 'string' &&
        typeof props.buttonOnClick !== 'function')
    ) {
      return new Error(
        `Please check that the props ${propertyName},  ${props.buttonText}  passed to component ${componentName} are both strings and ${props.buttonOnClick} is a function`
      );
    }
  },
  // eslint-disable-next-line consistent-return
  buttonOnClick(props, propertyName, componentName) {
    if (
      typeof props[propertyName] !== 'function' ||
      (typeof props.buttonText !== 'string' &&
        typeof props.buttonName !== 'string')
    ) {
      return new Error(
        `Please check that the props ${propertyName} is a function, ${props.buttonName} and ${props.buttonText}  passed to component ${componentName} are both strings.`
      );
    }
  },
  subMessageType: PropTypes.string
};

InterstitialError.defaultProps = {
  subMessage: '',
  buttonText: '',
  buttonName: '',
  linkText: '',
  linkHref: '',
  buttonOnClick: () => {},
  subMessageType: null
};

export default InterstitialError;
