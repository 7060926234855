import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import queryString from 'query-string';

import { showInvoice } from 'reducers/invoices';
import { getCurrentInvoice } from 'reducers/selectors';

import { InvoiceSkeleton, InvoiceErrorComponent } from './ui-states';
import InvoiceHeader from './InvoiceHeader';
import InvoiceFooter from './InvoiceFooter';
import InvoiceTable from './InvoiceTable';

// TODO: Make this lazy

const Invoice = props => {
  const {
    showInvoice,
    currentCompanyId,
    currentInvoice,
    isLoading,
    isError
  } = props;
  const { invoiceId } = props.match.params;

  const query = queryString.parse(props.history.location.search);
  const invoiceType = query.type;

  useEffect(() => {
    showInvoice(invoiceId, invoiceType);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyId, invoiceId, invoiceType]);

  if (isError) {
    return <InvoiceErrorComponent />;
  }

  if (isLoading) {
    return <InvoiceSkeleton />;
  }

  if (currentInvoice) {
    return (
      <InvoiceComponent
        invoiceData={currentInvoice}
        invoiceType={invoiceType}
      />
    );
  }
  // This prevents a flash of nothing before the loading state is set and the skeleton kicks in
  return null;
};

const InvoiceComponent = ({ invoiceData, invoiceType }) => {
  // TODO: Come up with a better way of naming invoice here
  const { company, headers, rows, limejumpCompany, invoice } = invoiceData;
  const DATE_FORMAT = 'd MMM yyyy';
  const invoiceDate = format(new Date(invoice.invoiceDate), DATE_FORMAT);
  const taxPointDate = format(new Date(invoice.taxPointDate), DATE_FORMAT);

  return (
    <div className="invoice">
      <InvoiceHeader
        invoiceNumber={invoice.number}
        taxDate={taxPointDate}
        amountDue={`£${invoice.grossTotal}`}
        invoiceDate={invoiceDate}
        companyName={company.name}
        vatNumber={company.vatNumber}
        address={company.address}
        subtitle={invoice.subtitle}
        title={invoice.title}
      />
      <InvoiceTable
        rows={rows}
        headers={headers}
        net={invoice.netTotal}
        gross={invoice.grossTotal}
        vat={invoice.vatTotal}
        invoiceType={invoiceType}
      />
      <InvoiceFooter
        address={limejumpCompany.address}
        vatNumber={limejumpCompany.vatNumber}
        registeredNumber={limejumpCompany.registeredNumber}
        generatedBy={limejumpCompany.generatedBy}
      />
    </div>
  );
};

const mapStateToProps = state => {
  const { currentCompanyId } = state.auth;
  const currentInvoice = getCurrentInvoice(state);
  const {
    isLoadingInvoiceDetail,
    isErrorFetchingInvoiceDetail
  } = state.invoices;

  return {
    currentCompanyId,
    currentInvoice,
    isLoading: isLoadingInvoiceDetail,
    isError: isErrorFetchingInvoiceDetail
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showInvoice: (invoiceId, invoiceType) =>
      dispatch(showInvoice(invoiceId, invoiceType))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
