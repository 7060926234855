import React from 'react';
import PropTypes from 'prop-types';

/* This is a temporary component which wraps the page content so it
becomes centered like the old customer portal. It should be used
as the top level parent on non-iframe pages. For example:

const OverviewPage = () => {
  return (
    <CenterWithMargin>
      <Component>
      <Component>
    </CenterWithMargin>
  );
};
*/

export const CenterWithMargin = ({ children }) => {
  return <section className="page-container__center">{children}</section>;
};

const PageContent = ({ children }) => {
  return <main className="page-container">{children}</main>;
};

PageContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

CenterWithMargin.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default PageContent;
