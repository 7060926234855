import React from 'react';

const LJLogo = props => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1267.9 232.73"
    {...props}
  >
    <style>
      {
        '.cls-1{fill:#76d69d;}.cls-2{fill:#00d959;}.cls-3{fill:#dc2026;fill-rule:evenodd;}.cls-4{fill:#fbce0b;}.cls-5{fill:none;stroke:#fff;stroke-miterlimit:40;stroke-width:4.42px;}'
      }
    </style>
    <path
      class="cls-5"
      d="m111.12,224.35h-62.63l-5.8-46.42-37.99-27.63c-1.66-8.12-2.49-16.39-2.49-24.68C2.21,57.5,57.5,2.21,125.62,2.21s123.42,55.29,123.42,123.41c0,8.29-.83,16.56-2.49,24.68l-37.98,27.63-5.8,46.42h-62.63l-3.61,2.63c-3.17,2.3-6.98,3.54-10.89,3.54s-7.72-1.24-10.89-3.54l-3.61-2.63Z"
    />
    <path
      class="cls-4"
      d="m111.12,224.35h-62.63l-5.8-46.42-37.99-27.63c-1.66-8.12-2.49-16.39-2.49-24.68C2.21,57.5,57.5,2.21,125.62,2.21s123.42,55.29,123.42,123.41c0,8.29-.83,16.56-2.49,24.68l-37.98,27.63-5.8,46.42h-62.63l-3.61,2.63c-3.17,2.3-6.98,3.54-10.89,3.54s-7.72-1.24-10.89-3.54l-3.61-2.63Z"
    />
    <path
      class="cls-3"
      d="m111.12,224.35h-62.63l-5.8-46.42-37.99-27.63c-1.66-8.12-2.49-16.39-2.49-24.68C2.21,57.5,57.5,2.21,125.62,2.21s123.42,55.29,123.42,123.41c0,8.29-.83,16.56-2.49,24.68l-37.98,27.63-5.8,46.42h-62.63l-3.61,2.63c-3.17,2.3-6.98,3.54-10.89,3.54s-7.72-1.24-10.89-3.54l-3.61-2.63Zm1.96-21.6h-45.52l-4.6-36.78-37.5-27.27c-1.1-4.27-1.65-8.66-1.65-13.08,0-7.37,1.55-14.65,4.55-21.38l66.39,67.12L30.21,91.69c2.54-10.33,8.16-19.65,16.12-26.71l57.85,99.85L52.92,55.51c6.63-8.83,15.88-15.33,26.42-18.59l35.37,123.94L88.87,31.81c7.86-4.44,16.74-6.77,25.76-6.77,1.91,0,3.82.1,5.72.31l5.26,134.2,5.27-134.2c1.89-.21,3.8-.31,5.72-.31,9.02,0,17.9,2.33,25.76,6.77l-25.84,129.05,35.38-123.94c10.54,3.26,19.79,9.76,26.42,18.59l-51.26,109.32,57.85-99.85c7.96,7.06,13.58,16.38,16.12,26.71l-64.54,79.67,66.39-67.12c3,6.73,4.55,14.01,4.55,21.38,0,4.41-.55,8.8-1.66,13.08l-37.49,27.27-4.6,36.78h-45.52l-6.73,4.9c-1.69,1.23-3.73,1.89-5.81,1.89s-4.12-.66-5.8-1.89l-6.74-4.9Z"
    />
    <path
      class="cls-2"
      d="m655.36,130.24h7.2V9.79h-19.33v108.44c0,6.63,5.43,12.01,12.14,12.01"
    />
    <rect class="cls-2" x="680.13" y="52.69" width="19.33" height="77.54" />
    <path
      class="cls-2"
      d="m825.68,62.03h0c-2.31-3.36-5.49-6.09-9.47-8.12-3.97-2.02-9.04-3.04-15.09-3.04-4.84,0-9.66,1.23-14.34,3.66-3.45,1.78-6.35,4.23-8.69,7.3-2.17-3.03-4.74-5.44-7.68-7.22-4.12-2.48-9.2-3.75-15.12-3.75-3.58,0-6.92.54-9.92,1.61-2.94,1.04-5.53,2.33-7.71,3.82-1.21.83-2.31,1.7-3.3,2.6v-6.2h-18.41v77.54h19.33v-40.14c0-3.74.47-7.01,1.39-9.7.89-2.63,2.11-4.79,3.6-6.44,1.48-1.64,3.15-2.83,5.09-3.64,1.96-.82,4-1.23,6.08-1.23,2.87,0,5.11.46,6.68,1.37,1.56.91,2.72,2.14,3.56,3.77.92,1.79,1.55,3.97,1.87,6.48.34,2.71.52,5.77.52,9.09v40.45h19.33v-42.12c0-5.79,1.28-10.54,3.81-14.11,2.34-3.31,6.29-4.93,12.05-4.93,2.88,0,5.16.47,6.78,1.39,1.64.93,2.87,2.13,3.76,3.64.94,1.62,1.58,3.54,1.89,5.71.34,2.4.51,4.94.51,7.53v42.88h19.33v-44.71c0-4.21-.41-8.35-1.21-12.32-.84-4.14-2.4-7.9-4.65-11.18"
    />
    <path
      class="cls-2"
      d="m882.75,67.25c3.11,0,5.83.47,8.1,1.39,2.23.91,4.1,2.16,5.56,3.74,1.47,1.59,2.6,3.49,3.35,5.65.51,1.48.87,3.08,1.09,4.78h-37.69c.23-1.19.61-2.39,1.13-3.59.94-2.18,2.29-4.19,4-5.97,1.69-1.76,3.81-3.22,6.29-4.34,2.45-1.1,5.2-1.66,8.17-1.66m36.31,46.84l-14.77-11.17-1.68,2.05c-2.46,3-5.18,5.44-8.09,7.25-2.72,1.69-6,2.55-9.76,2.55-2.86,0-5.66-.46-8.33-1.37-2.65-.91-5-2.18-6.99-3.8-1.96-1.58-3.56-3.53-4.77-5.78-.76-1.42-1.28-2.96-1.54-4.61h58.21v-7.59c0-4.88-.68-9.76-2.03-14.51-1.38-4.84-3.64-9.25-6.71-13.12-3.1-3.9-7.11-7.09-11.91-9.49-4.83-2.41-10.75-3.64-17.61-3.64-5.77,0-11.2,1.04-16.14,3.1-4.94,2.06-9.25,4.95-12.82,8.6-3.56,3.63-6.39,7.97-8.41,12.88-2.02,4.92-3.05,10.3-3.05,16.01s.94,11.05,2.79,15.93c1.86,4.93,4.56,9.28,8.03,12.93,3.48,3.67,7.78,6.57,12.78,8.64,4.98,2.05,10.59,3.1,16.67,3.1,14.67,0,26.27-5.32,34.46-15.81l1.69-2.16Z"
    />
    <path
      class="cls-2"
      d="m933.52,137.25c0,4.03-.61,7.06-1.82,8.99-.35.57-1.42,2.28-5.58,2.28-.69,0-1.45-.1-2.25-.3-.92-.23-1.74-.48-2.48-.75l-3.27-1.2-2.22,18.21,2.07.73c1.6.57,3.26.97,4.93,1.2,1.61.22,3.26.33,4.91.33,6.07,0,10.91-1.18,14.38-3.51,3.41-2.29,5.95-5.07,7.53-8.28,1.53-3.08,2.45-6.33,2.73-9.64.27-3.13.4-5.57.4-7.45V52.69h-19.33v84.56Z"
    />
    <path
      class="cls-2"
      d="m1020.08,92.53c0,3.84-.47,7.19-1.4,9.94-.9,2.66-2.15,4.86-3.72,6.55-1.56,1.68-3.33,2.86-5.4,3.63-3.7,1.38-8.35,1.68-12.39.35-1.61-.53-3.01-1.38-4.26-2.57-1.24-1.18-2.26-2.79-3.03-4.8-.8-2.06-1.2-4.63-1.2-7.62v-45.31h-19.33v49.11c0,5.1.79,9.62,2.35,13.42,1.58,3.85,3.75,7.07,6.44,9.55,2.68,2.49,5.83,4.35,9.38,5.54,3.44,1.15,7.15,1.74,11.03,1.74,6.08,0,11.55-1.32,16.25-3.92,2-1.11,3.77-2.38,5.29-3.79v5.89h19.33V52.69h-19.33v39.84Z"
    />
    <path
      class="cls-2"
      d="m1164.56,62.03h0c-2.31-3.36-5.49-6.09-9.47-8.12-3.97-2.02-9.04-3.04-15.09-3.04-4.84,0-9.66,1.23-14.34,3.66-3.45,1.78-6.35,4.23-8.69,7.3-2.17-3.03-4.74-5.44-7.68-7.22-4.12-2.48-9.2-3.75-15.12-3.75-3.58,0-6.92.54-9.92,1.61-2.94,1.04-5.53,2.33-7.71,3.82-1.21.83-2.31,1.7-3.3,2.6v-6.2h-18.41v77.54h19.33v-40.14c0-3.74.47-7.01,1.39-9.7.89-2.63,2.11-4.79,3.6-6.44,1.48-1.64,3.15-2.83,5.09-3.64,1.96-.82,4-1.23,6.08-1.23,2.87,0,5.11.46,6.68,1.37,1.56.91,2.72,2.14,3.56,3.77.92,1.79,1.55,3.97,1.87,6.48.34,2.71.52,5.77.52,9.09v40.45h19.33v-42.12c0-5.79,1.28-10.54,3.81-14.11,2.34-3.31,6.29-4.93,12.05-4.93,2.88,0,5.16.47,6.78,1.39,1.64.93,2.87,2.13,3.76,3.64.94,1.62,1.58,3.54,1.89,5.71.34,2.4.51,4.94.51,7.53v42.88h19.33v-44.71c0-4.21-.41-8.35-1.21-12.32-.84-4.14-2.4-7.9-4.65-11.18"
    />
    <path
      class="cls-2"
      d="m1226.4,69.08c3.29,0,6.29.57,8.9,1.68,2.61,1.11,4.83,2.64,6.61,4.53,1.78,1.9,3.2,4.25,4.2,6.98,1.01,2.79,1.53,5.88,1.53,9.2s-.52,6.41-1.53,9.2c-1,2.74-2.41,5.08-4.2,6.98-1.78,1.89-4,3.42-6.61,4.53-2.61,1.11-5.6,1.68-8.9,1.68s-6.3-.56-8.91-1.68c-2.61-1.11-4.83-2.63-6.61-4.53-1.79-1.9-3.2-4.25-4.19-6.98-1.01-2.78-1.53-5.87-1.53-9.19s.52-6.41,1.53-9.2c.99-2.73,2.4-5.08,4.2-6.98,1.78-1.89,4-3.41,6.61-4.53,2.62-1.12,5.61-1.68,8.91-1.68m38.7,6.6h0c-1.87-4.93-4.56-9.3-8.01-12.99-3.48-3.71-7.73-6.64-12.62-8.71-4.89-2.06-10.4-3.11-16.38-3.11-5.3,0-10.53,1.16-15.53,3.44-2.78,1.27-5.25,2.84-7.41,4.69v-6.31h-19.33v120.44h7.19c6.7,0,12.14-5.37,12.14-12.01v-37.09c2.02,1.74,4.36,3.24,6.99,4.49,4.96,2.35,10.33,3.54,15.95,3.54,5.98,0,11.49-1.04,16.38-3.11,4.89-2.07,9.13-4.97,12.61-8.62,3.46-3.64,6.16-7.99,8.03-12.93,1.85-4.91,2.79-10.26,2.79-15.93s-.94-10.88-2.79-15.78"
    />
    <path
      class="cls-2"
      d="m689.79,18.01c-3.17,0-5.94,1.15-8.22,3.4-2.28,2.26-3.44,4.99-3.44,8.14s1.16,5.88,3.44,8.13c2.28,2.26,5.05,3.4,8.22,3.4s5.94-1.15,8.22-3.4c2.28-2.26,3.44-5,3.44-8.13s-1.15-5.88-3.44-8.14c-2.28-2.26-5.05-3.4-8.22-3.4"
    />
    <path
      class="cls-2"
      d="m943.18,18.01c-3.17,0-5.94,1.15-8.22,3.4-2.28,2.26-3.44,4.99-3.44,8.14s1.15,5.88,3.44,8.13c2.28,2.26,5.05,3.4,8.22,3.4s5.94-1.15,8.22-3.4c2.28-2.26,3.44-5,3.44-8.13s-1.16-5.88-3.44-8.14c-2.28-2.26-5.05-3.4-8.22-3.4"
    />
    <path
      class="cls-2"
      d="m550.15,115.09c-18.16,17.96-40.18,27.07-65.44,27.07s-47.28-9.11-65.42-27.07c-9.45-9.34-16.46-19.71-21.03-31.04,4.57-11.33,11.58-21.71,21.03-31.05,18.14-17.96,40.16-27.06,65.42-27.06s47.28,9.11,65.44,27.06c9.44,9.35,16.46,19.73,21.02,31.05-4.57,11.32-11.58,21.69-21.02,31.04m27.34-66.86c-3.2-4.07-6.72-8.02-10.56-11.83-22.81-22.56-50.48-34-82.22-34s-59.4,11.44-82.19,34c-3.84,3.8-7.36,7.74-10.56,11.81-8.66,11-14.98,22.97-18.92,35.84,3.94,12.87,10.26,24.83,18.92,35.83,3.2,4.07,6.72,8.01,10.56,11.81,22.79,22.57,50.46,34.02,82.19,34.02s59.4-11.45,82.22-34.02c3.84-3.81,7.36-7.75,10.56-11.82,8.65-11,14.97-22.96,18.91-35.82-3.94-12.86-10.26-24.82-18.91-35.82"
    />
    <path
      class="cls-2"
      d="m511.35,127.94c-2.99,0-5.96-.66-8.8-2-13.61-6.37-19.1-22.61-24.41-38.32-2.44-7.22-5.55-16.44-9.05-21.65-1.43-2.13-4.54-2.26-6.18-.28-3.29,3.96-7.43,10.91-10.23,18.26l-21.03-7.85c.1-.27,2.59-6.74,6.62-13.83,6.23-10.95,12.8-17.67,20.06-20.55,5.62-2.22,11.43-2.05,16.79.5,13.56,6.45,19.04,22.67,24.34,38.36,2.44,7.23,5.56,16.45,9.03,21.63,1.43,2.13,4.56,2.23,6.2.25,3.42-4.14,7.62-11.43,10.25-18.29l21.03,7.86c-.1.27-2.58,6.72-6.6,13.8-6.22,10.92-12.76,17.64-20.01,20.54-2.65,1.06-5.33,1.59-8,1.59"
    />
    <path
      class="cls-1"
      d="m846.85,222.29h-3.88l11.86-25.47,11.45,25.47h-3.97l-2.51-5.82h-10.24l-2.7,5.82Zm4.23-9.22h7.25l-3.56-8.14-3.69,8.14Z"
    />
    <path
      class="cls-1"
      d="m890.16,215.13l8.52-18.6,4.55,25.76h-3.72l-2.29-14.44-7.09,15.55-6.9-15.58-2.58,14.47h-3.75l5.06-25.76,8.2,18.6Z"
    />
    <path
      class="cls-1"
      d="m911.05,218.52c.7.73,1.61,1.1,2.73,1.1.87,0,1.59-.21,2.18-.62.58-.41,1.22-1.18,1.92-2.31l2.93,1.65c-.4.7-.75,1.22-1.03,1.57s-.62.68-1,1c-.66.59-1.44,1.05-2.34,1.37-.9.32-1.83.48-2.78.48-1.48,0-2.79-.33-3.91-.99-1.12-.66-2-1.59-2.62-2.8-.63-1.21-.94-2.61-.94-4.2s.3-2.99.91-4.2c.6-1.21,1.46-2.15,2.58-2.81,1.11-.67,2.39-1,3.83-1,1.25,0,2.41.27,3.47.81,1.06.54,1.89,1.27,2.48,2.18.4.62.71,1.36.92,2.23.21.87.32,1.79.32,2.77v.83h-10.81c.08,1.23.48,2.21,1.18,2.94Zm4.77-7.87c-.62-.49-1.37-.73-2.26-.73s-1.59.25-2.23.76c-.64.51-1.06,1.19-1.27,2.03h7.03c-.23-.89-.66-1.58-1.27-2.07Z"
    />
    <path
      class="cls-1"
      d="m928,208.55c.66-.7,1.23-1.17,1.73-1.41.5-.24,1.13-.37,1.89-.37.85,0,1.62.19,2.3.57s1.27.93,1.73,1.65c1.1-1.48,2.59-2.23,4.45-2.23.95,0,1.83.21,2.64.64.8.42,1.39,1,1.75,1.72.49.98.73,2.25.73,3.82v9.35h-3.5v-8.39c0-1.44-.17-2.47-.52-3.08-.35-.61-.95-.92-1.8-.92-.98,0-1.68.37-2.11,1.1-.43.73-.65,1.9-.65,3.51v7.79h-3.5v-8.36c0-1.38-.19-2.4-.56-3.05-.37-.66-.96-.99-1.76-.99-.49,0-.95.13-1.38.38-.43.25-.76.58-.97.99-.32.64-.48,1.72-.48,3.24v7.79h-3.5v-15.13h3.5v1.4Z"
    />
    <path
      class="cls-1"
      d="m955.31,207.26c.74-.33,1.59-.49,2.54-.49,1.34,0,2.54.34,3.62,1.03s1.93,1.64,2.54,2.86c.61,1.22.92,2.57.92,4.05s-.31,2.9-.94,4.12c-.63,1.22-1.48,2.18-2.58,2.88-1.09.7-2.32,1.05-3.67,1.05-.89,0-1.7-.16-2.43-.49s-1.47-.85-2.21-1.57v1.59h-3.5v-26.2h3.5v12.85c.72-.78,1.45-1.34,2.19-1.67Zm-1.22,3.97c-.8.9-1.19,2.07-1.19,3.51s.39,2.61,1.18,3.51c.78.9,1.82,1.35,3.12,1.35,1.21,0,2.21-.46,3.02-1.37.8-.91,1.21-2.05,1.21-3.4s-.4-2.6-1.19-3.55c-.8-.94-1.8-1.41-3.01-1.41-1.29,0-2.34.45-3.13,1.35Z"
    />
    <path
      class="cls-1"
      d="m972.8,218.52c.7.73,1.61,1.1,2.73,1.1.87,0,1.59-.21,2.18-.62.58-.41,1.22-1.18,1.92-2.31l2.93,1.65c-.4.7-.75,1.22-1.03,1.57s-.62.68-1,1c-.66.59-1.44,1.05-2.34,1.37-.9.32-1.83.48-2.78.48-1.48,0-2.79-.33-3.91-.99-1.12-.66-2-1.59-2.62-2.8-.63-1.21-.94-2.61-.94-4.2s.3-2.99.91-4.2c.6-1.21,1.46-2.15,2.58-2.81,1.11-.67,2.39-1,3.83-1,1.25,0,2.41.27,3.47.81,1.06.54,1.89,1.27,2.48,2.18.4.62.71,1.36.92,2.23.21.87.32,1.79.32,2.77v.83h-10.81c.08,1.23.48,2.21,1.18,2.94Zm4.77-7.87c-.62-.49-1.37-.73-2.26-.73s-1.59.25-2.23.76c-.64.51-1.06,1.19-1.27,2.03h7.03c-.23-.89-.66-1.58-1.27-2.07Z"
    />
    <path
      class="cls-1"
      d="m989.74,208.52c.64-.68,1.2-1.14,1.7-1.4.5-.25,1.09-.38,1.76-.38.55,0,1.03.06,1.43.19.4.13.9.37,1.49.73l-1.62,3.21c-.4-.3-.74-.5-1.02-.6s-.58-.16-.92-.16c-.98,0-1.69.36-2.15,1.07-.46.71-.68,1.81-.68,3.29v7.82h-3.5v-15.13h3.5v1.37Z"
    />
    <path
      class="cls-1"
      d="m1018.57,207.82c1.24.7,2.21,1.66,2.93,2.88.71,1.22,1.07,2.57,1.07,4.05s-.36,2.84-1.07,4.05c-.71,1.22-1.69,2.18-2.94,2.89-1.25.71-2.63,1.07-4.13,1.07s-2.84-.35-4.05-1.07c-1.22-.71-2.18-1.69-2.89-2.94-.71-1.25-1.07-2.63-1.07-4.13,0-1.42.36-2.73,1.08-3.93.72-1.2,1.7-2.15,2.93-2.86,1.23-.71,2.58-1.07,4.04-1.07s2.88.35,4.12,1.05Zm-7.36,3.4c-.83.89-1.24,2.06-1.24,3.5s.41,2.67,1.22,3.56c.82.89,1.9,1.34,3.26,1.34s2.45-.45,3.29-1.34c.84-.89,1.26-2.06,1.26-3.5s-.41-2.67-1.24-3.56c-.83-.89-1.93-1.34-3.31-1.34s-2.42.45-3.24,1.34Z"
    />
    <path
      class="cls-1"
      d="m1026.34,222.29v-11.86h-1.24v-3.27h1.24v-5.82c0-1,.08-1.81.24-2.45.16-.64.42-1.16.78-1.56.4-.51.96-.91,1.69-1.21.72-.3,1.47-.45,2.26-.45.42,0,.8.04,1.13.11.33.07.74.22,1.22.43v3.53c-.66-.32-1.11-.52-1.37-.6s-.54-.13-.86-.13c-.62,0-1.03.22-1.26.65-.22.43-.33,1.28-.33,2.53v4.96h3.82v3.27h-3.82v11.86h-3.5Z"
    />
    <path
      class="cls-1"
      d="m1050.65,219.38c.33-.12.65-.31.97-.59v3.21c-.7.49-1.61.73-2.73.73-1.4,0-2.42-.39-3.05-1.18-.36-.4-.61-.93-.75-1.59s-.21-1.52-.21-2.58v-7.06h-1.46v-3.18h1.46v-5.53h3.5v5.53h3.12v3.18h-3.12v7.63c0,.51.08.9.25,1.18.17.28.55.41,1.14.41.25,0,.55-.06.87-.17Z"
    />
    <path
      class="cls-1"
      d="m1058.42,208.43c.66-.59,1.3-1.02,1.94-1.27s1.36-.38,2.16-.38c.89,0,1.73.19,2.51.56.78.37,1.41.86,1.88,1.48.38.51.65,1.08.8,1.7.15.63.22,1.46.22,2.5v9.28h-3.5v-8.93c0-1.21-.22-2.09-.65-2.64-.43-.55-1.12-.83-2.05-.83-.59,0-1.13.11-1.61.33-.48.22-.84.54-1.1.94-.4.59-.6,1.88-.6,3.85v7.28h-3.5v-26.2h3.5v12.34Z"
    />
    <path
      class="cls-1"
      d="m1076.48,218.52c.7.73,1.61,1.1,2.73,1.1.87,0,1.59-.21,2.18-.62.58-.41,1.22-1.18,1.92-2.31l2.93,1.65c-.4.7-.75,1.22-1.03,1.57s-.62.68-1,1c-.66.59-1.44,1.05-2.34,1.37-.9.32-1.83.48-2.78.48-1.48,0-2.79-.33-3.91-.99-1.12-.66-2-1.59-2.62-2.8-.63-1.21-.94-2.61-.94-4.2s.3-2.99.91-4.2c.6-1.21,1.46-2.15,2.58-2.81,1.11-.67,2.39-1,3.83-1,1.25,0,2.41.27,3.47.81,1.06.54,1.89,1.27,2.48,2.18.4.62.71,1.36.92,2.23.21.87.32,1.79.32,2.77v.83h-10.81c.08,1.23.48,2.21,1.18,2.94Zm4.77-7.87c-.62-.49-1.37-.73-2.26-.73s-1.59.25-2.23.76c-.64.51-1.06,1.19-1.27,2.03h7.03c-.23-.89-.66-1.58-1.27-2.07Z"
    />
    <path
      class="cls-1"
      d="m1108.66,201.78c-.51-.34-1.18-.51-2-.51-1,0-1.83.29-2.5.86s-1,1.28-1,2.13c0,.78.38,1.45,1.14,2,.36.23.78.46,1.27.68.49.22,1.35.58,2.58,1.07,1.95.81,3.38,1.78,4.28,2.91.9,1.13,1.35,2.53,1.35,4.18,0,1.44-.33,2.75-1,3.91-.67,1.17-1.59,2.08-2.75,2.75-1.17.67-2.48,1-3.94,1-1.31,0-2.51-.28-3.59-.83s-1.97-1.35-2.67-2.38c-.4-.57-.71-1.14-.91-1.7-.2-.56-.37-1.29-.49-2.18l3.66-.83c.08.7.18,1.24.29,1.61.11.37.24.72.41,1.03.3.57.76,1.02,1.38,1.35.62.33,1.31.49,2.05.49,1.15,0,2.08-.38,2.81-1.13s1.1-1.7,1.1-2.85c0-1.34-.54-2.4-1.62-3.18-.4-.25-.87-.5-1.4-.73-.53-.23-1.42-.61-2.67-1.14-3.29-1.38-4.93-3.4-4.93-6.07,0-1.21.31-2.29.94-3.26.63-.96,1.48-1.72,2.58-2.27,1.09-.55,2.32-.83,3.67-.83,1.44,0,2.68.32,3.72.95s1.95,1.65,2.73,3.05l-2.89,1.78c-.55-.91-1.08-1.54-1.59-1.88Z"
    />
    <path
      class="cls-1"
      d="m1121.44,208.43c.66-.59,1.3-1.02,1.94-1.27s1.36-.38,2.16-.38c.89,0,1.73.19,2.51.56.78.37,1.41.86,1.88,1.48.38.51.65,1.08.8,1.7.15.63.22,1.46.22,2.5v9.28h-3.5v-8.93c0-1.21-.22-2.09-.65-2.64-.43-.55-1.12-.83-2.05-.83-.59,0-1.13.11-1.61.33-.48.22-.84.54-1.1.94-.4.59-.6,1.88-.6,3.85v7.28h-3.5v-26.2h3.5v12.34Z"
    />
    <path
      class="cls-1"
      d="m1139.5,218.52c.7.73,1.61,1.1,2.73,1.1.87,0,1.59-.21,2.18-.62.58-.41,1.22-1.18,1.92-2.31l2.93,1.65c-.4.7-.75,1.22-1.03,1.57s-.62.68-1,1c-.66.59-1.44,1.05-2.34,1.37-.9.32-1.83.48-2.78.48-1.48,0-2.79-.33-3.91-.99-1.12-.66-2-1.59-2.62-2.8-.63-1.21-.94-2.61-.94-4.2s.3-2.99.91-4.2c.6-1.21,1.46-2.15,2.58-2.81,1.11-.67,2.39-1,3.83-1,1.25,0,2.41.27,3.47.81,1.06.54,1.89,1.27,2.48,2.18.4.62.71,1.36.92,2.23.21.87.32,1.79.32,2.77v.83h-10.81c.08,1.23.48,2.21,1.18,2.94Zm4.77-7.87c-.62-.49-1.37-.73-2.26-.73s-1.59.25-2.23.76c-.64.51-1.06,1.19-1.27,2.03h7.03c-.23-.89-.66-1.58-1.27-2.07Z"
    />
    <path class="cls-1" d="m1153.05,222.29v-26.2h3.5v26.2h-3.5Z" />
    <path class="cls-1" d="m1160.97,222.29v-26.2h3.5v26.2h-3.5Z" />
    <path
      class="cls-1"
      d="m1200.81,210.94c0,1.55-.17,2.9-.51,4.05s-.88,2.23-1.62,3.23c-1.04,1.42-2.36,2.53-3.97,3.34-1.61.81-3.32,1.21-5.12,1.21-2.21,0-4.24-.56-6.1-1.67-1.87-1.11-3.35-2.62-4.45-4.52-1.1-1.9-1.65-3.97-1.65-6.22s.56-4.39,1.67-6.3c1.11-1.91,2.62-3.42,4.53-4.53s4.01-1.67,6.3-1.67c1.31,0,2.59.2,3.82.6,1.23.4,2.35.98,3.37,1.72.45.32.86.66,1.24,1.02.38.36.86.87,1.43,1.53l-2.58,2.48c-1.04-1.36-2.14-2.35-3.29-2.99-1.16-.64-2.45-.95-3.9-.95-1.68,0-3.2.4-4.56,1.19s-2.44,1.89-3.23,3.27c-.78,1.39-1.18,2.93-1.18,4.63,0,2.69.96,4.89,2.89,6.58.85.76,1.77,1.35,2.78,1.76,1.01.41,2.02.62,3.04.62,1.19,0,2.3-.28,3.35-.83,1.05-.55,1.92-1.29,2.61-2.23.69-.93,1.09-1.95,1.19-3.05h-5.98v-3.4h9.92v1.11Z"
    />
    <path
      class="cls-1"
      d="m1208.15,208.52c.64-.68,1.2-1.14,1.7-1.4.5-.25,1.09-.38,1.76-.38.55,0,1.03.06,1.43.19.4.13.9.37,1.49.73l-1.62,3.21c-.4-.3-.74-.5-1.02-.6s-.58-.16-.92-.16c-.98,0-1.69.36-2.15,1.07-.46.71-.68,1.81-.68,3.29v7.82h-3.5v-15.13h3.5v1.37Z"
    />
    <path
      class="cls-1"
      d="m1227.6,207.82c1.24.7,2.21,1.66,2.93,2.88.71,1.22,1.06,2.57,1.06,4.05s-.35,2.84-1.06,4.05c-.71,1.22-1.69,2.18-2.94,2.89-1.25.71-2.63,1.07-4.13,1.07s-2.84-.35-4.05-1.07c-1.22-.71-2.18-1.69-2.89-2.94-.71-1.25-1.07-2.63-1.07-4.13,0-1.42.36-2.73,1.08-3.93.72-1.2,1.7-2.15,2.93-2.86s2.58-1.07,4.04-1.07,2.88.35,4.12,1.05Zm-7.36,3.4c-.83.89-1.24,2.06-1.24,3.5s.41,2.67,1.22,3.56c.81.89,1.9,1.34,3.26,1.34s2.45-.45,3.29-1.34c.84-.89,1.26-2.06,1.26-3.5s-.41-2.67-1.24-3.56c-.83-.89-1.93-1.34-3.31-1.34s-2.42.45-3.24,1.34Z"
    />
    <path
      class="cls-1"
      d="m1248.15,207.15v15.13h-3.5v-1.3c-.51.57-1.11,1.01-1.8,1.32-.69.31-1.41.46-2.18.46-1.84,0-3.27-.6-4.26-1.81-.47-.59-.78-1.27-.94-2.02-.16-.75-.24-1.75-.24-3.01v-8.78h3.5v8.87c0,1.31.22,2.24.67,2.78.44.54,1.19.81,2.23.81s1.78-.31,2.27-.92c.5-.61.75-1.56.75-2.83v-8.71h3.5Z"
    />
    <path
      class="cls-1"
      d="m1252.57,207.15h3.5v1.65c.74-.72,1.47-1.24,2.19-1.56.72-.32,1.54-.48,2.45-.48,1.36,0,2.58.35,3.67,1.05s1.95,1.66,2.58,2.88c.62,1.22.94,2.59.94,4.12s-.31,2.83-.92,4.04c-.61,1.21-1.46,2.16-2.54,2.86-1.08.7-2.29,1.05-3.62,1.05-.93,0-1.77-.17-2.51-.51-.74-.34-1.48-.89-2.23-1.65v9.92h-3.5v-23.37Zm4.47,4.09c-.79.9-1.19,2.07-1.19,3.51s.4,2.61,1.19,3.51c.79.9,1.83,1.35,3.1,1.35s2.21-.46,3.02-1.37c.8-.91,1.21-2.05,1.21-3.4,0-1.44-.39-2.63-1.18-3.56-.78-.93-1.79-1.4-3.02-1.4-1.29,0-2.34.45-3.13,1.35Z"
    />
  </svg>
);

export default LJLogo;
