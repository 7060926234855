import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getProducts } from 'reducers/overview';

import ActiveContractsLoading from './loading';
import ContractsError from './error';
import ContractsPage from './page';

const ActiveContracts = ({
  loadingContracts,
  currentCompanyId,
  products,
  contractsError,
  getProducts
}) => {
  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyId]);

  if (loadingContracts) return <ActiveContractsLoading />;
  if (contractsError) return <ContractsError />;
  return <ContractsPage products={products} />;
};

const mapStateToProps = state => {
  const { currentCompanyId } = state.auth;
  const { products, loadingContracts, contractsError } = state.overview;
  return { currentCompanyId, products, loadingContracts, contractsError };
};

const mapDispatchToProps = dispatch => {
  return {
    getProducts: () => dispatch(getProducts())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveContracts);
