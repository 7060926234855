export const developmentSettings = {
  REACT_APP_SALESFORCE_DOMAIN: 'localhost',
  REACT_APP_SF_DOMAIN: 'https://limejump--KitsuneDev.my.salesforce.com',
  REACT_APP_SF_SETUP_URL:
    'https://kitsunedev-limejumpkitsunedevchat.cs109.force.com/liveAgentSetupFlow',
  REACT_APP_SF_ID: '00D0Q0000000Mgp',
  REACT_APP_SF_BASE_URL: 'https://service.force.com',
  REACT_APP_LIVE_AGENT_URL:
    'https://c.la1-c2cs-fra.salesforceliveagent.com/content',
  REACT_APP_DEPLOYMENT_ID: '5720Q0000008Odc',
  REACT_APP_BUTTON_ID: '5730Q0000008OgH',
  REACT_APP_BASE_LIVE_AGENT_URL:
    'https://d.la1-c2cs-fra.salesforceliveagent.com/chat',
  REACT_APP_DEV_NAME: 'Portal_Chat'
};

export const productionSettings = {
  REACT_APP_SALESFORCE_DOMAIN: 'limejump.com',
  REACT_APP_SF_DOMAIN: 'https://limejump.my.salesforce.com',
  REACT_APP_SF_SETUP_URL:
    'https://limejumpportalchat.secure.force.com/liveAgentSetupFlow',
  REACT_APP_SF_ID: '00D20000000oqEu',
  REACT_APP_SF_BASE_URL: 'https://service.force.com',
  REACT_APP_LIVE_AGENT_URL:
    'https://c.la1-c2-cdg.salesforceliveagent.com/content',
  REACT_APP_DEPLOYMENT_ID: '5723Y0000004GdX',
  REACT_APP_BUTTON_ID: '5733Y0000004GtE',
  REACT_APP_BASE_LIVE_AGENT_URL:
    'https://d.la1-c2-cdg.salesforceliveagent.com/chat',
  REACT_APP_DEV_NAME: 'Customer_Chat'
};
