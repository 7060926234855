import React from 'react';
import PropTypes from 'prop-types';

const TableMenu = ({ children }) => {
  return (
    <div className="lj-table__body-menu">
      <div className="lj-table__body-menu-container">{children}</div>
    </div>
  );
};

TableMenu.propTypes = {
  /** any child elements to render */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
};

export default TableMenu;
