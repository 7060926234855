import api from 'utils/api';

export const getAssets = companyId => {
  return api.get(`/v1/availability/dropdown?company=${companyId}`);
};

export const getAssetAvailability = (assetId, isoStartDate) => {
  return api.get(
    `/v1/availability/timeline?asset_id=${assetId}&iso_start_date=${isoStartDate}`
  );
};
