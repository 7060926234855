import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import {
  handleChangeResetEmail,
  sendResetemail,
  setCurrentView,
  handleChangePassword1,
  handleChangePassword2,
  resetPassword,
  passwordMatchError,
  clearPasswordResetEmail
} from 'reducers/reset';

import { Text } from 'components';
import SendEmail from './SendEmail';
import ChooseNewPassword from './ChooseNewPassword';
import PasswordRequestSuccess from './PasswordRequestSuccess';
import PasswordResetConfirm from './PasswordResetConfirm';

const Wrapper = ({ children }) => (
  <div className="login-container password-reset">
    {children}
    <Text
      className="credit"
      content="&copy; 2019 Limejump Ltd. All rights reserved."
    />
    <div className="contact">
      <a
        className="contact-btn"
        href="mailto:support@limejump.com?Subject=Portal%20Query"
      >
        Contact Us
      </a>
    </div>
  </div>
);

const _PasswordResetPage = props => {
  const query = queryString.parse(props.history.location.search);

  useEffect(() => {
    if (query.uid && query.token) {
      props.setCurrentView('CHOOSE_NEW_PASSWORD');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    handleChangePassword1,
    handleChangePassword2,
    handleChangeResetEmail,
    sendResetemail,
    email,
    currentView,
    setCurrentView,
    password1,
    password2,
    resetPassword,
    passwordMatchError,
    error,
    clearPasswordResetEmail
  } = props;

  switch (currentView) {
    case 'CHOOSE_NEW_PASSWORD':
      return (
        <Wrapper>
          <ChooseNewPassword
            handleChangePassword1={handleChangePassword1}
            handleChangePassword2={handleChangePassword2}
            password1={password1}
            password2={password2}
            resetPassword={resetPassword}
            passwordMatchError={passwordMatchError}
            error={error}
            uid={query.uid}
            token={query.token}
            setCurrentView={setCurrentView}
          />
        </Wrapper>
      );

    case 'PASSWORD_REQUEST_SUCCESS':
      return (
        <Wrapper>
          <PasswordRequestSuccess
            setCurrentView={setCurrentView}
            email={email}
            clearEmail={clearPasswordResetEmail}
          />
        </Wrapper>
      );

    case 'PASSWORD_RESET_SUCCESS':
      return (
        <Wrapper>
          <PasswordResetConfirm
            setCurrentView={setCurrentView}
            clearEmail={clearPasswordResetEmail}
          />
        </Wrapper>
      );

    default:
      return (
        <Wrapper>
          <SendEmail
            handleChangeResetEmail={handleChangeResetEmail}
            sendResetemail={sendResetemail}
            email={email}
          />
        </Wrapper>
      );
  }
};

const mapStateToProps = state => {
  const { email, password1, password2, error, currentView } = state.reset;
  return {
    email,
    password1,
    password2,
    error,
    currentView
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleChangeResetEmail: email => dispatch(handleChangeResetEmail(email)),
    sendResetemail: email => dispatch(sendResetemail(email)),
    setCurrentView: view => dispatch(setCurrentView(view)),
    handleChangePassword1: password1 =>
      dispatch(handleChangePassword1(password1)),
    handleChangePassword2: password2 =>
      dispatch(handleChangePassword2(password2)),
    resetPassword: (password1, password2, uid, token) =>
      dispatch(resetPassword(password1, password2, uid, token)),
    passwordMatchError: () => dispatch(passwordMatchError()),
    clearPasswordResetEmail: () => dispatch(clearPasswordResetEmail())
  };
};

const PasswordResetPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(_PasswordResetPage));

function PasswordReset() {
  return (
    <Fragment>
      <Helmet>
        <title>Password Reset</title>
      </Helmet>
      <PasswordResetPage />;
    </Fragment>
  );
}

export default PasswordReset;
