import React from 'react';
import PropTypes from 'prop-types';

const FormGroup = ({ children }) => (
  <div className="lj-form-group">{children}</div>
);

FormGroup.propTypes = {
  /** any child elements to render  */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
};

export default FormGroup;
