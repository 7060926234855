import React from 'react';
import history from 'utils/history';

import {
  Button,
  Row,
  Label,
  Form,
  FormError,
  Input,
  Text,
  LJLogo,
  Col,
  FormGroup,
  LinkButton
} from 'components';

const LoginForm = props => {
  const {
    handleChangeEmail,
    handleChangePassword,
    login,
    email,
    password,
    error
  } = props;

  const handleSubmit = event => {
    event.preventDefault();
    login(email, password);
  };

  return (
    <div className="login-container">
      <Form className="center-xs" onSubmit={handleSubmit}>
        <Row className="login-container__welcome">
          <LJLogo className="logo" height="44px" />
          <Text
            className="login-container__welcome-msg"
            content="Welcome back, please log in below."
          />
          {error && <FormError variant="dark" error={error} />}
        </Row>
        <FormGroup>
          <Label htmlFor="email" text="Email" id="email-label" variant="dark" />
          <Input
            id="email"
            value={email}
            type="email"
            onChange={event => handleChangeEmail(event.target.value)}
            variant="dark"
            required
          />
        </FormGroup>
        <FormGroup>
          <Label
            htmlFor="password"
            text="Password"
            id="password-label"
            variant="dark"
          />
          <Input
            id="password"
            value={password}
            type="password"
            onChange={event => handleChangePassword(event.target.value)}
            variant="dark"
            required
          />
        </FormGroup>
        <Row
          modifier="between-xs middle-xs"
          className="login-container__footer"
        >
          <Col modifier="col-xs-6">
            <Button
              text="Forgot Password?"
              variant="tertiary-light"
              name="login-forgot-password"
              onClick={() => history.push('password-reset')}
            />
          </Col>

          <Col modifier="col-xs-6">
            <Button
              variant="primary-bright"
              text="Log in"
              type="submit"
              name="login-button"
              full
            />
          </Col>
        </Row>
      </Form>
      <LinkButton
        className="privacy"
        text="Privacy & Cookies"
        link="https://www.limejump.com/privacy-cookies-policy/"
      />
      <Text
        className="credit"
        content="&copy; 2022 Limejump Ltd. All rights reserved."
      />
      <div className="contact">
        <a
          className="contact-btn"
          href="mailto:support@limejump.com?Subject=Portal%20Query"
        >
          Contact Us
        </a>
      </div>
    </div>
  );
};
export default LoginForm;
