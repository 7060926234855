import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { login, handleChangePassword, handleChangeEmail } from 'reducers/auth';
import { getCurrentCompanyId } from 'reducers/selectors';
import LoginForm from './LoginForm';

const Login = props => {
  const {
    handleChangePassword,
    handleChangeEmail,
    login,
    email,
    password,
    error
  } = props;

  return (
    <Fragment>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <LoginForm
        handleChangePassword={handleChangePassword}
        handleChangeEmail={handleChangeEmail}
        login={login}
        email={email}
        password={password}
        error={error}
      />
    </Fragment>
  );
};

const mapStateToProps = state => {
  const { email, password, error } = state.auth;
  const currentCompanyId = getCurrentCompanyId(state);
  return { email, password, error, currentCompanyId };
};

const mapDispatchToProps = dispatch => {
  return {
    handleChangePassword: password => dispatch(handleChangePassword(password)),
    handleChangeEmail: email => dispatch(handleChangeEmail(email)),
    login: (email, password) => {
      dispatch(login(email, password));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
