import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

// we use this component to handle code splitting and also show an error
// if the network request to fetch more code fails
export const LazyComponent = ({
  errorBoundary: ErrorBoundary,
  fallbackComp: FallbackComp,
  children
}) => (
  <ErrorBoundary>
    <Suspense fallback={<FallbackComp />}>{children}</Suspense>
  </ErrorBoundary>
);

LazyComponent.propTypes = {
  errorBoundary: PropTypes.func.isRequired,
  fallbackComp: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.element
  ]).isRequired
};
