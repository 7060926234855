import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { ErrorPage } from 'components';

class AppErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorPage type="500" />;
    }

    return this.props.children;
  }
}

export default AppErrorBoundary;
