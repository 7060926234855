import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Checkbox = ({ id, checked, onChange, labelText, showError }) => {
  const className = classnames({
    'lj-checkbox': true,
    'lj-checkbox--error': showError
  });

  return (
    <label htmlFor={id} className={className}>
      <span className="lj-checkbox__text">{labelText}</span>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        className="lj-checkbox__input"
        aria-label="input-checkbox"
      />
      <span className="lj-checkbox__checkmark" />
    </label>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  labelText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  showError: PropTypes.bool
};

Checkbox.defaultProps = {
  showError: false
};

export default Checkbox;
