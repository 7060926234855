import { format, parseISO } from 'date-fns';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getCurrentCompanyId } from 'reducers/selectors';
import { createError } from 'reducers/error';
import { getContractedProducts, getRevenueSummary } from 'services/overview';
import { formatCurrency } from 'utils/helpers';

export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';
const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
const GET_REVENUE_REQUEST = 'GET_REVENUE_REQUEST';
export const GET_REVENUE_SUCCESS = 'GET_REVENUE_SUCCESS';
export const GET_REVENUE_FAILURE = 'GET_REVENUE_FAILURE';

/**
 * STATE
 */

const initialState = {
  products: [],
  loadingContracts: false,
  contractsError: '',
  loadingRevenue: false,
  revenueError: '',
  revenueSummary: ''
};

export const overview = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        ...{
          products: action.payload.products,
          loadingContracts: false
        }
      };
    }

    case GET_PRODUCTS_FAILURE: {
      return {
        ...state,
        ...{
          loadingContracts: false,
          contractsError: action.payload
        }
      };
    }

    case GET_PRODUCTS_REQUEST: {
      return {
        ...state,
        ...{
          loadingContracts: true,
          contractsError: ''
        }
      };
    }

    case GET_REVENUE_REQUEST: {
      return {
        ...state,
        ...{
          loadingRevenue: true,
          revenueError: ''
        }
      };
    }

    case GET_REVENUE_SUCCESS: {
      return {
        ...state,
        ...{
          loadingRevenue: false,
          revenueSummary: action.payload.revenueSummary
        }
      };
    }

    case GET_REVENUE_FAILURE: {
      return {
        ...state,
        ...{
          loadingRevenue: false,
          revenueError: action.payload.error.message
        }
      };
    }

    default:
      return state;
  }
};

/**
 * ACTIONS
 */

export function getProducts() {
  return {
    type: GET_PRODUCTS_REQUEST
  };
}

export function getProductsSuccess(response) {
  return {
    type: GET_PRODUCTS_SUCCESS,
    payload: response
  };
}

function getProductsFailure(response) {
  return {
    type: GET_PRODUCTS_FAILURE,
    payload: response
  };
}

export function getRevenue() {
  return {
    type: GET_REVENUE_REQUEST
  };
}

export function getRevenueSuccess(response) {
  return {
    type: GET_REVENUE_SUCCESS,
    payload: response
  };
}

function getRevenueFailure(response) {
  return {
    type: GET_REVENUE_FAILURE,
    payload: response
  };
}

/**
 * ASYNC ACTIONS
 */

export function* getProductsRequest() {
  const currentCompanyId = yield select(getCurrentCompanyId);

  const { error, response } = yield call(() =>
    getContractedProducts(currentCompanyId)
  );

  if (error) {
    yield put(getProductsFailure(error));
    return;
  }

  const formattedProducts = response.map(product => {
    return {
      type: product.product_name,
      contractEndDate: format(
        parseISO(product.next_contract_end_date),
        'dd/MM/yy'
      ),
      isEndingSoon: product.next_contract_expires_soon
    };
  });

  yield put(
    getProductsSuccess({
      products: formattedProducts
    })
  );
}

export function* getRevenueRequest() {
  try {
    const revenueSummary = yield call(getRevenueSummary);
    const revenueSummaryAmount = formatCurrency(revenueSummary.total);

    yield put(
      getRevenueSuccess({
        revenueSummary: revenueSummaryAmount
      })
    );
  } catch (error) {
    yield put(
      getRevenueFailure({
        error
      })
    );
    yield put(createError(error));
  }
}

export function* watchGetProducts() {
  yield takeEvery(GET_PRODUCTS_REQUEST, getProductsRequest);
}

export function* watchGetRevenue() {
  yield takeEvery(GET_REVENUE_REQUEST, getRevenueRequest);
}

export default overview;
