import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const TableBody = ({ prepareRow, rows, getTableBodyProps, resetRows }) => (
  <Fragment>
    <tbody className="lj-table__body" {...getTableBodyProps()}>
      {rows.map(row => {
        prepareRow(row);
        return (
          <tr
            {...row.getRowProps()}
            className={`${classnames({
              'lj-table__body-row': true,
              'lj-table__body-row--active': row.isExpanded,
              'lj-table__body-row:hover': true
            })}`}
            onClick={() => {
              resetRows(rows);
              row.toggleRowExpanded(!row.isExpanded);
              /* toggleExpanded: Function(?isExpanded: Bool) => void
                This function will toggle the expanded state of a row between true and false or, if an isExpanded boolean is passed to the function, it will be set as the new isExpanded value. Rows with a hard-coded manualExpandedKey (defaults to expanded) set to true are not affected by this function or the internal expanded state. */
            }}
          >
            {row.cells.map(cell => {
              return (
                <td className="lj-table__body-cell" {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  </Fragment>
);

TableBody.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  resetRows: PropTypes.func.isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired
};

export default TableBody;
