import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Skeleton = ({
  count,
  duration,
  width,
  height,
  circle,
  baseColor,
  highlightColor,
  className,
  keyPrefix
}) => {
  const elements = [];
  const style = {
    backgroundColor: baseColor,
    backgroundImage: `linear-gradient(90deg, ${baseColor}, ${highlightColor}, ${baseColor})`,
    borderRadius: circle ? '50%' : '0.3125rem',
    animation: `skeletonFrame ${duration}s ease-in-out infinite`
  };
  const styles = classnames('lj-skeleton', className);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < count; i++) {
    elements.push(
      <span
        key={`${keyPrefix}${i}`}
        className="lj-skeleton__element"
        style={style}
      />
    );
  }

  return (
    <span className={styles} style={{ height, width }}>
      {' '}
      {elements}{' '}
    </span>
  );
};

Skeleton.propTypes = {
  /** the background colour for the component */
  baseColor: PropTypes.string,
  /** the animation colour for the component */
  highlightColor: PropTypes.string,
  /** the number of skeletons you want to display */
  count: PropTypes.number,
  /** the duration of the animation */
  duration: PropTypes.number,
  /** the type of skeleton to dispay. Rectangles are the default shape */
  circle: PropTypes.bool,
  /** the width of the skeleton */
  width: PropTypes.string,
  /** the height of the skeleton */
  height: PropTypes.string,
  /** the classname for the component */
  className: PropTypes.string,
  /** The prefix on the key to prevent key collisions */
  keyPrefix: PropTypes.string
};

Skeleton.defaultProps = {
  baseColor: '#eee',
  highlightColor: '#f5f5f5',
  count: 1,
  duration: 1.2,
  circle: false,
  width: null,
  height: null,
  className: '',
  keyPrefix: ''
};

export default Skeleton;
