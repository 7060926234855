import React from 'react';
import PropTypes from 'prop-types';
import { Text, Col, ExternalLink } from 'components';

const NewsArticle = ({ article }) => (
  <Col modifier="col-xs-6 col-sm-3">
    <article className="overview__news__item">
      <ExternalLink url={article.url} className="overview__news__item-title">
        {article.title}
      </ExternalLink>
      <Text
        tag="h3"
        className="overview__news__item-date"
        content={article.date}
      />
    </article>
  </Col>
);

NewsArticle.propTypes = {
  article: PropTypes.shape({
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
};

export default NewsArticle;
