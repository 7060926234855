import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './service-worker';

import { injectSalesforce } from './utils/salesforce';

import commitSha from './commit-sha.js';

import 'styles/index.scss';

if (process.env.REACT_APP_IS_LOCAL_DEV !== 'true') {
  Sentry.init({
    dsn: 'https://b3f1c43223264ee0be90eb28267af5d6@sentry.io/1529394',
    environment: process.env.REACT_APP_DEPLOYMENT_ENV,
    release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
  });
  console.log(`Using Version: ${process.env.REACT_APP_VERSION}@${commitSha}`);
}

ReactDOM.render(<App />, document.getElementById('root'));

injectSalesforce();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
