import React from 'react';
import PropTypes from 'prop-types';

const Fieldset = ({ children, disabled }) => (
  <fieldset disabled={disabled}>{children}</fieldset>
);

Fieldset.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  disabled: PropTypes.bool
};

Fieldset.defaultProps = {
  disabled: false
};

export default Fieldset;
