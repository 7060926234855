import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';

const Tooltip = ({
  children,
  content,
  delay,
  animation,
  arrow,
  placement,
  theme
}) => {
  return (
    <Tippy
      content={content}
      delay={delay}
      animation={animation}
      arrow={arrow}
      placement={placement}
      theme={theme}
    >
      {children}
    </Tippy>
  );
};

// the extensive list of props for with this library can be found here: https://atomiks.github.io/tippyjs/v6/all-props
Tooltip.propTypes = {
  /** delay in ms before tooltip is shown or hidden */
  delay: PropTypes.number,
  /** tooltip position */
  placement: PropTypes.oneOf([
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
    'bottom-start',
    'bottom-end',
    'bottom',
    'top',
    'top-start',
    'top-end',
    // if you want to choose side with the most space
    'auto',
    'auto-start',
    'auto-end'
  ]),
  /** the content to be rendered in the tooltip */
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element
  ]).isRequired,
  /** the element(s) being wrapped by the tooltip */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  /** the animation type. See https://atomiks.github.io/tippyjs/v6/animations */
  animation: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /** the arrow to be displayed */
  arrow: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.element,
    PropTypes.node
  ]),
  /** the general style for the tooltip */
  theme: PropTypes.string
};

Tooltip.defaultProps = {
  placement: 'top',
  theme: 'dark',
  delay: 0,
  animation: false,
  arrow: false
};

export default Tooltip;
