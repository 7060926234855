import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';

import { getAssets, getAssetAvailability } from 'services/availability';
import { createError } from 'reducers/error';
import {
  getCurrentCompanyId,
  getCurrentAssetId,
  getCurrentPeriod
} from 'reducers/selectors';

// ACTIONS
export const GET_ASSETS_REQUEST = 'GET_ASSETS_REQUEST';
export const GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS';
export const GET_ASSETS_FAILURE = 'GET_ASSETS_FAILURE';

export const GET_ASSET_AVAILABILITY_SUCCESS = 'GET_ASSET_AVAILABILITY_SUCCESS';
export const GET_ASSET_AVAILABILITY_FAILURE = 'GET_ASSET_AVAILABILITY_FAILURE';

export const SET_CURRENT_ASSET = 'SET_CURRENT_SITE';
export const SET_CURRENT_PERIOD = 'SET_CURRENT_PERIOD';

/**
 * STATE
 */

const initialState = {
  errorFetchingAssetList: false,
  errorFetchingTimelineData: false,
  sites: [],
  periods: {},
  selectedAsset: {},
  isLoadingTimelineData: false,
  isLoadingAssetList: false,
  days: []
};

export const availability = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSETS_REQUEST: {
      return {
        ...state,
        ...{
          errorFetchingAssetList: false,
          isLoadingTimelineData: true,
          isLoadingAssetList: true
        }
      };
    }

    case SET_CURRENT_ASSET: {
      return {
        ...state,
        selectedAsset: action.payload,
        isLoadingTimelineData: true
      };
    }

    case SET_CURRENT_PERIOD: {
      return {
        ...state,
        periods: {
          ...state.periods,
          currentPeriod: action.payload
        },
        isLoadingTimelineData: true
      };
    }

    case GET_ASSETS_SUCCESS: {
      const {
        sites,
        currentPeriod,
        nextPeriod,
        previousPeriod
      } = action.payload;
      return {
        ...state,

        isLoadingAssetList: false,
        periods: {
          thisWeek: currentPeriod,
          currentPeriod,
          nextPeriod,
          previousPeriod
        },
        selectedAsset: {
          name: sites[0].assets[0].name,
          id: sites[0].assets[0].id,
          displayValue: `${sites[0].name} - ${sites[0].assets[0].name}`
        },
        sites
      };
    }

    case GET_ASSETS_FAILURE: {
      return {
        ...state,
        ...{
          errorFetchingAssetList: true,
          isLoadingTimelineData: false,
          isLoadingAssetList: false
        }
      };
    }

    case GET_ASSET_AVAILABILITY_FAILURE: {
      return {
        ...state,
        ...{
          errorFetchingTimelineData: true,
          isLoadingTimelineData: false
        }
      };
    }

    case GET_ASSET_AVAILABILITY_SUCCESS: {
      const {
        days,
        currentPeriod,
        nextPeriod,
        previousPeriod
      } = action.payload;
      return {
        ...state,
        errorFetchingTimelineData: false,
        isLoadingTimelineData: false,
        periods: {
          ...state.periods,
          currentPeriod,
          nextPeriod,
          previousPeriod
        },
        days
      };
    }

    default:
      return state;
  }
};

/**
 * ACTIONS
 */

export function getAssetsRequest(companyId) {
  return {
    type: GET_ASSETS_REQUEST,
    payload: companyId
  };
}

export function getAssetsSuccess(payload) {
  return {
    type: GET_ASSETS_SUCCESS,
    payload
  };
}

export function getAssetsFailure(payload) {
  return {
    type: GET_ASSETS_FAILURE,
    payload
  };
}

export function getAssetAvailabilitySuccess(payload) {
  return {
    type: GET_ASSET_AVAILABILITY_SUCCESS,
    payload
  };
}

export function getAssetAvailabilityFailure(payload) {
  return {
    type: GET_ASSET_AVAILABILITY_FAILURE,
    payload
  };
}

export function setCurrentAsset(payload) {
  return {
    type: SET_CURRENT_ASSET,
    payload
  };
}

export function setViewPeriod(payload) {
  return {
    type: SET_CURRENT_PERIOD,
    payload
  };
}

/**
 * ASYNC ACTIONS
 */
export function* getAssetsSaga() {
  const currentCompanyId = yield select(getCurrentCompanyId);

  try {
    const response = yield call(getAssets, currentCompanyId);
    yield put(
      getAssetsSuccess({
        sites: response.sites,
        currentPeriod: response.current_period,
        nextPeriod: response.next_period,
        previousPeriod: response.previous_period
      })
    );
  } catch (error) {
    yield put(getAssetsFailure(true));
    yield put(createError(error));
  }
}

export function* getAssetAvailabilitySaga() {
  const currentAsset = yield select(getCurrentAssetId);

  const currentPeriod = yield select(getCurrentPeriod);
  try {
    const response = yield call(
      getAssetAvailability,
      currentAsset,
      currentPeriod.iso_start_date
    );

    yield put(
      getAssetAvailabilitySuccess({
        days: response.days,
        currentPeriod: response.current_period,
        nextPeriod: response.next_period,
        previousPeriod: response.previous_period
      })
    );
  } catch (error) {
    yield put(getAssetAvailabilityFailure(true));
    yield put(createError(error));
  }
}

// WATCHERS
export function* watchGetAssets() {
  yield takeEvery(GET_ASSETS_REQUEST, getAssetsSaga);
}

export function* watchSetCurrentAsset() {
  yield takeEvery(SET_CURRENT_ASSET, getAssetAvailabilitySaga);
}

export function* watchSetView() {
  yield takeLatest(SET_CURRENT_PERIOD, getAssetAvailabilitySaga);
}

export function* watchGetAssetsSuccess() {
  yield takeEvery(GET_ASSETS_SUCCESS, getAssetAvailabilitySaga);
}

export default availability;
