import React from 'react';
import PropTypes from 'prop-types';

const Label = props => {
  const { text, htmlFor, id, variant } = props;

  return (
    <label
      className={`lj-label lj-label--${variant}`}
      htmlFor={htmlFor}
      id={id}
    >
      {text}
    </label>
  );
};

Label.propTypes = {
  text: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  variant: PropTypes.string
};

Label.defaultProps = {
  variant: 'light'
};

export default Label;
