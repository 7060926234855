import api from '../utils/api';

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded'
};

export const emailToResetRequest = ([emailReset]) =>
  api.post('/v1/password-reset', { email: emailReset }, { headers });

export const passwordToResetRequest = ([password1, password2, uid, token]) =>
  api.post(
    '/api/2.0/password-reset-confirm',
    {
      uid,
      password_reset_token: token,
      new_password1: password1,
      new_password2: password2
    },
    { headers }
  );
