import { developmentSettings, productionSettings } from './salesforce-settings';

export function injectSalesforce() {
  try {
    const settings =
      process.env.REACT_APP_IS_LOCAL_DEV === 'true'
        ? developmentSettings
        : productionSettings;

    const domain = settings.REACT_APP_SALESFORCE_DOMAIN;
    const sfDomain = settings.REACT_APP_SF_DOMAIN;
    const sfSetupURL = settings.REACT_APP_SF_SETUP_URL;
    const salesforceID = settings.REACT_APP_SF_ID;
    const baseURL = settings.REACT_APP_SF_BASE_URL;
    const baseLiveAgentContentURL = settings.REACT_APP_LIVE_AGENT_URL;
    const deploymentId = settings.REACT_APP_DEPLOYMENT_ID;
    const buttonId = settings.REACT_APP_BUTTON_ID;
    const baseLiveAgentURL = settings.REACT_APP_BASE_LIVE_AGENT_URL;
    const developmentName = settings.REACT_APP_DEV_NAME;

    /* eslint-disable no-param-reassign */
    const initESW = (gslbBaseURL, chat) => {
      chat.settings.displayHelpButton = false; // Or false
      chat.settings.language = 'en';
      chat.settings.defaultMinimizedText = 'Chat with us';
      chat.settings.storageDomain = domain; // (Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)
      chat.settings.enabledFeatures = ['LiveAgent'];
      chat.settings.entryFeature = 'LiveAgent';

      chat.init(
        sfDomain,
        sfSetupURL,
        gslbBaseURL,
        salesforceID,
        developmentName,
        {
          baseLiveAgentContentURL,
          deploymentId,
          buttonId,
          baseLiveAgentURL,
          eswLiveAgentDevName: developmentName,
          isOfflineSupportEnabled: true
        }
      );
    };
    /* eslint-enable no-param-reassign */
    const s = document.createElement('script');
    s.setAttribute('async', true);
    s.setAttribute(
      'src',
      'https://limejump.my.salesforce.com/embeddedservice/5.0/esw.min.js'
    );
    s.addEventListener('load', () => {
      initESW(baseURL, window.embedded_svc);
    });
    document.body.appendChild(s);
  } catch (error) {
    console.error(error);
  }
}
