import React, { Fragment } from 'react';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Iframe } from 'components';
import { getCurrentCompanyId } from 'reducers/selectors';

const DispatchAvailability = props => {
  const { currentCompanyId } = props;
  return (
    <Fragment>
      <Helmet>
        <title>Dispatch Availability</title>
      </Helmet>
      <Iframe
        url={`${process.env.REACT_APP_SIDEBARLESS_PORTAL_BASE_URL}/#/dispatch/availability?iframe=true`}
        title="Dispatch Availability"
        companyId={currentCompanyId}
      />
    </Fragment>
  );
};

const mapStateToProps = state => {
  const currentCompanyId = getCurrentCompanyId(state);
  return { currentCompanyId };
};

export default connect(mapStateToProps)(DispatchAvailability);
