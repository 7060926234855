import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import ICONS from 'utils/icons';

import { Icon, Text } from 'components';

import { logout } from 'utils/auth';
import { getCurrentCompany } from 'reducers/selectors';

const SidebarLink = ({ path, name, location, children, iconName }) => {
  const locationParts = location.pathname.split('/').filter(s => s !== '');
  const pathParts = path.split('/').filter(s => s !== '');

  // It should be active if the root of the route matches the top level page linked in the sidebar
  // (i.e. /466/invoices in the sidebar matches: /466/invoices/deeply/nested
  const isActive =
    locationParts.length > 1 &&
    pathParts.length > 1 &&
    locationParts[1] === pathParts[1];

  const icon = (iconName || name).toUpperCase();
  return (
    <li>
      <Link to={path} className={isActive ? 'active' : ''}>
        <Icon icon={ICONS[icon]} color={isActive ? '#000' : '#5D6365'} />
        <Text content={name} />
        {children}
      </Link>
    </li>
  );
};

const Sidebar = props => {
  const { currentCompany, location } = props;
  if (location.pathname === '/select') return null;
  return (
    <nav className="nav-side ">
      <ul>
        {currentCompany &&
          currentCompany.permittedRoutes
            ?.filter(r => r.shouldAppearInSidebar)
            .map(route => (
              <SidebarLink
                key={route.path}
                location={location}
                path={`/${currentCompany.id}${route.path}`}
                name={route.label}
                iconName={route.iconName}
              >
                {route.additionalSidebarComponent}
              </SidebarLink>
            ))}

        <li className="nav-side__btn-logout">
          {/* TODO: Replace with button */}
          <Link
            onClick={() => logout()}
            text="Logout"
            name="logout"
            className="logout"
            to="/login"
          >
            <Icon icon={ICONS.CLOSE_CIRCLE} />
            <Text content="Logout" />
          </Link>
        </li>
      </ul>
    </nav>
  );
};

const mapStateToProps = state => {
  const currentCompany = getCurrentCompany(state);
  return { currentCompany };
};

export default connect(mapStateToProps)(Sidebar);
