/** CURRENCY FORMATTING  * */

/**
 * Formats currency to a given locale and currency
 * @param {Number} value in units (or cents) i.e. 1 is £0.01p or $0.01 USD
 * @param {String} locale
 * @param {String} currency
 */
export function formatCurrency(value = 0, locale = 'en-GB', currency = 'GBP') {
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(
    value / 100 // For now this is fine but this may not be valid for all cultures?
  );
}

// Generate an array with a random number of elements
// mainly for use in creating skeleton screens
export function createRange(number) {
  return [...new Array(number).keys()];
}
