import React from 'react';
import history from 'utils/history';

import {
  Button,
  Row,
  Label,
  Input,
  Text,
  Form,
  LJLogo,
  Col,
  FormGroup
} from 'components/';

const SendEmail = props => {
  const { handleChangeResetEmail, sendResetemail, email } = props;

  return (
    <Form
      className=" center-xs"
      onSubmit={event => {
        event.preventDefault();
        sendResetemail(email);
      }}
    >
      <Row className="password-reset__sendEmail-msg-container">
        <LJLogo className="logo" height="44px" />
        <Text
          content=" Resetting your Password"
          className="password-reset__sendEmail-msg"
        />
        <Text content="Enter your email to receive your reset link" />
      </Row>
      <FormGroup>
        <Label
          htmlFor="email-reset"
          text="Email"
          id="email-label"
          variant="dark"
        />
        <Input
          id="email-reset"
          value={email}
          required
          type="email"
          onChange={event => handleChangeResetEmail(event.target.value)}
          variant="dark"
        />
      </FormGroup>
      <Row
        modifier="between-xs middle-xs"
        className="password-reset__sendEmail-footer"
      >
        <Col modifier="col-xs-6">
          <Button
            text="Back to Login"
            name="back-to-login"
            onClick={() => {
              history.push('login');
            }}
            variant="tertiary-light"
          />
        </Col>
        <Col modifier="col-xs-6">
          <Button
            text="Reset Password"
            name="send-email"
            type="submit"
            variant="primary-bright"
            full
          />
        </Col>
      </Row>
    </Form>
  );
};

export default SendEmail;
