import React from 'react';
import PropTypes from 'prop-types';

const Icon = React.forwardRef(
  (
    { icon, height, width, color, fill, strokeWidth, viewBox, ...props },
    reference
  ) => {
    const { path, pathStyles, svgStyles } = icon;

    // This prevents an undefined access error for icons without a pathStyles property.
    const defaultStroke =
      pathStyles && pathStyles.stroke ? pathStyles.stroke : null;
    const stroke = color || defaultStroke;

    const styles = {
      path: {
        ...pathStyles, // This should be at the top to prevent overriding path style passed as props with defaults.
        stroke,
        strokeWidth
      }
    };

    return (
      <svg
        ref={reference}
        style={svgStyles}
        width={`${width}px`}
        height={`${height}px`}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        {...props}
      >
        <path style={styles.path} d={path} />
      </svg>
    );
  }
);

Icon.propTypes = {
  /** the icon to display */
  icon: PropTypes.shape({
    path: PropTypes.string.isRequired,
    pathStyles: PropTypes.shape({})
  }).isRequired,
  /** set the height of the icon */
  height: PropTypes.number,
  /** set the width of the icon */
  width: PropTypes.number,
  /** set the colour of the icon */
  color: PropTypes.string,
  /** set the fill colour of the icon */
  fill: PropTypes.string,
  /** set the thickness of the icon outline */
  strokeWidth: PropTypes.number,
  /** set the viewbox of the SVG element */
  viewBox: PropTypes.string
};

Icon.defaultProps = {
  width: 24,
  height: 24,
  color: '',
  fill: 'none',
  strokeWidth: 2,
  viewBox: null
};

export default Icon;
