const { useEffect, useState } = require('react');

const useDeterminePPA = products => {
  const [isPPA, setIsPPA] = useState(false);

  useEffect(() => {
    if (!products || !products.length) setIsPPA(false);
    setIsPPA(products.some(product => product.type.includes('PPA')));
  }, [products]);

  return isPPA;
};

export default useDeterminePPA;
