import React, { useEffect } from 'react';

import store from 'app-store';
import { updateAlertContent } from 'reducers/notifications';
import { hasUserSeenAlert } from 'utils/storage';

import { Header, Sidebar, GlobalAlert } from 'features';
import { Container, Row, PageContent, ErrorGeneral } from 'components';

// eslint-disable-next-line no-unused-vars
const showAlert = alertName => {
  if (!hasUserSeenAlert(alertName)) {
    store.dispatch(updateAlertContent(alertName));
  }
};

const PrivatePage = ({ component: Component, isHeaderless, ...props }) => {
  // Whenever the app runs, we might want to show an application wide alert (non-public pages).
  useEffect(() => {
    // This is commented out as there is no global alert but we want to keep the example boiler plate code for next time there is one.
    // showAlert(Messages.INVOICES_LAUNCH.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isHeaderless) {
    return <Component {...props} />;
  }

  return (
    <Container className="container-app" fluid>
      <div className="contact">
        <a
          className="contact-btn"
          href="mailto:support@limejump.com?Subject=Portal%20Query"
        >
          Contact Us
        </a>
      </div>

      <Row className="row-header">
        <Header {...props} />
      </Row>

      <Row className="row-body">
        <Sidebar {...props} />
        <PageContent>
          <ErrorGeneral>
            <GlobalAlert />
            <Component {...props} />
          </ErrorGeneral>
        </PageContent>
      </Row>
    </Container>
  );
};

export default PrivatePage;
