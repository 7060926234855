import React from 'react';
import { Text } from 'components';

const ErrorComp = ({ message }) => (
  <div>
    <Text tag="h3" className="requestError" content={message} />
  </div>
);

export default ErrorComp;
