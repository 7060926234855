import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row, FormError } from 'components';
import Input from './index';

const DateInputWrapper = ({ children }) => {
  return <div className="date-input__wrapper">{children}</div>;
};

const DateInput = props => {
  const {
    inputVariant,
    dayId,
    monthId,
    yearId,
    dayValue,
    monthValue,
    yearValue,
    handleChangeDay,
    handleChangeMonth,
    handleChangeYear,
    errorMsg,
    isRequired
  } = props;

  return (
    <div className="date-input__container">
      <Row>
        <Col modifier="col-xs-12" className="date-input__col">
          <DateInputWrapper>
            <Input
              onChange={handleChangeDay}
              type="text"
              id={dayId}
              value={dayValue}
              maxLength={2}
              pattern="[0-9]*"
              title="Please use numbers only"
              inputmode="numeric"
              variant={inputVariant}
              placeholder="DD"
              required={isRequired}
            />
          </DateInputWrapper>
          <DateInputWrapper>
            <Input
              onChange={handleChangeMonth}
              type="text"
              id={monthId}
              value={monthValue}
              maxLength={2}
              pattern="[0-9]*"
              title="Please use numbers only"
              inputmode="numeric"
              variant={inputVariant}
              placeholder="MM"
              required={isRequired}
            />
          </DateInputWrapper>
          <DateInputWrapper>
            <Input
              onChange={handleChangeYear}
              type="text"
              id={yearId}
              value={yearValue}
              maxLength={4}
              pattern="[0-9]*"
              title="Please use numbers only"
              inputmode="numeric"
              variant={inputVariant}
              placeholder="YYYY"
              required={isRequired}
            />
          </DateInputWrapper>
        </Col>
        <Col modifier="col-xs-12">
          {errorMsg && <FormError variant="dark" error={errorMsg} />}
        </Col>
      </Row>
    </div>
  );
};

DateInput.propTypes = {
  handleChangeDay: PropTypes.func.isRequired,
  handleChangeMonth: PropTypes.func.isRequired,
  handleChangeYear: PropTypes.func.isRequired,
  dayId: PropTypes.string.isRequired,
  dayValue: PropTypes.oneOfType([PropTypes.string]).isRequired,
  monthValue: PropTypes.oneOfType([PropTypes.string]).isRequired,
  yearValue: PropTypes.oneOfType([PropTypes.string]).isRequired,
  inputVariant: PropTypes.oneOf(['light', 'dark']),
  errorMsg: PropTypes.string,
  isRequired: PropTypes.bool
};

DateInput.defaultProps = {
  inputVariant: 'light',
  errorMsg: '',
  isRequired: false
};

export default DateInput;
