import api from '../utils/api';

export const authenticateUser = ([email, password]) =>
  api.post(
    '/api/2.0/auth',
    { email, password },
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
  );

export const getUsers = id => api.get(`/v1/users/${id}`);

export const getToken = () => api.get('/v1/token');

export const getCompanyFeatures = id => api.get(`/v1/companies/${id}/features`);
