import React from 'react';
import PropTypes from 'prop-types';

const BgImage = ({ style, children }) => {
  return <div style={style}>{children}</div>;
};

BgImage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  style: PropTypes.shape({}).isRequired
};

export default BgImage;
