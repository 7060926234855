import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { PPA_INVOICE } from '../constants';

const InvoiceTable = ({ rows, headers, vat, net, gross, invoiceType }) => {
  // we need to make the first cell in the ppa table wider than the other cells
  const tableHeadCellClass = classnames('lj-table__head-cell', {
    'lj-table__head-cell__ppa': invoiceType === PPA_INVOICE
  });
  const tableBodyCellClass = classnames('lj-table__body-cell', {
    'lj-table__body-cell__ppa': invoiceType === PPA_INVOICE
  });

  const additionalTds = [];
  const additionalTdCount = Math.max(headers.length - 2, 0);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < additionalTdCount; i++) {
    const className = classnames(
      'lj-table__body-cell invoice_table__cell-summary',
      {
        'invoice_table__cell-summary__ppa': invoiceType === PPA_INVOICE
      }
    );

    additionalTds.push(<td className={className} />);
  }

  return (
    <section className="invoice_table">
      <table className="lj-table">
        <thead className="lj-table__head invoice_table__head">
          <tr className="lj-table__head-row">
            {headers.map(h => (
              <th key={h.key} className={tableHeadCellClass}>
                {h.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="lj-table__body invoice_table__body">
          {rows.map((r, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={index} className="lj-table__body-row">
              {r.map((cell, index) => (
                <td
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${cell.key}-${index}`}
                  className={tableBodyCellClass}
                >
                  {cell.value}
                </td>
              ))}
            </tr>
          ))}
          <tr className="lj-table__body-row invoice_table__body--net">
            {additionalTds.map(r => r)}
            <td className="lj-table__body-cell invoice_table__cell-summary-name">
              Net
            </td>
            <td className="lj-table__body-cell invoice_table__cell-summary-amount">
              {net}
            </td>
          </tr>

          <tr className="lj-table__body-row invoice_table__body--vat">
            {additionalTds.map(r => r)}
            <td className="lj-table__body-cell invoice_table__cell-summary-name">
              VAT
            </td>
            <td className="lj-table__body-cell invoice_table__cell-summary-amount">
              {vat}
            </td>
          </tr>

          <tr className="lj-table__body-row invoice_table__body--gross">
            {additionalTds.map(r => r)}
            <td className="lj-table__body-cell invoice_table__cell-summary-name">
              Gross
            </td>

            <td className="lj-table__body-cell invoice_table__cell-summary-amount">
              {gross}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

InvoiceTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string
    })
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string
      })
    )
  ).isRequired
};

export default InvoiceTable;
