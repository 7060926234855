import React from 'react';
import PropTypes from 'prop-types';

import { Text, Row, Icon, Button } from 'components';
import ICONS from 'utils/icons';

export const InvoiceHeaderTitle = () => (
  <Row className="invoice_header-title" modifier="between-xs">
    <Icon
      icon={ICONS.LOGO}
      color="#2A2F32"
      fill="#2A2F32"
      width={300}
      height={40}
      viewBox="0 0 350 40"
      strokeWidth={0}
    />
    <Text content="Self-Billing Invoice" />
  </Row>
);

const InvoiceHeader = ({
  invoiceNumber,
  amountDue,
  taxDate,
  companyName,
  vatNumber,
  invoiceDate,
  address,
  subtitle,
  title
}) => {
  return (
    <section className="invoice_header">
      <Button
        text="Print this invoice"
        variant="primary"
        name="print-invoice-button"
        onClick={() => {
          window.print();
        }}
      />
      <InvoiceHeaderTitle />
      <Row className="invoice_header-content" modifier="between-xs">
        <div className="invoice_header-content__cards">
          {subtitle && (
            <div>
              <Text className="invoice_header-content__title" content={title} />
              <Text
                className="invoice_header-content__subtitle"
                content={subtitle}
              />
            </div>
          )}
          <div>
            <span className="invoice_header-content__card-invoice">
              <Text
                className="invoice_header-content__card-key"
                content="Invoice #"
              />
              <Text
                className="invoice_header-content__card-value"
                content={invoiceNumber}
              />
            </span>
            <span className="invoice_header-content__card-amount">
              <Text
                className="invoice_header-content__card-key"
                content="Amount Due"
              />
              <Text
                className="invoice_header-content__card-value"
                content={amountDue}
              />
            </span>
            <span className="invoice_header-content__card-tax">
              <Text
                className="invoice_header-content__card-key"
                content="Tax Point Date"
              />
              <Text
                className="invoice_header-content__card-value"
                content={taxDate}
              />
            </span>
          </div>
        </div>
        <div className="invoice_header-content__address">
          <Row
            className="invoice_header-content__address-invoice"
            modifier="between-xs"
          >
            <Text
              className="invoice_header-content__address-key"
              content="Invoice To"
            />
            <Text
              className="invoice_header-content__address-value"
              content={companyName}
            />
          </Row>
          <Row modifier="between-xs">
            <Text
              className="invoice_header-content__address-key"
              content="Address"
            />
            <Text
              className="invoice_header-content__address-value invoice_header-content__address-line"
              content={address.lineOne}
            />
          </Row>
          {address.lineTwo ? (
            <Row modifier="between-xs">
              <Text
                className="invoice_header-content__address-key"
                content=""
              />
              <Text
                className="invoice_header-content__address-value invoice_header-content__address-line"
                content={address.lineTwo}
              />
            </Row>
          ) : null}
          {address.lineThree ? (
            <Row modifier="between-xs">
              <Text
                className="invoice_header-content__address-key"
                content=""
              />
              <Text
                className="invoice_header-content__address-value invoice_header-content__address-line"
                content={address.lineThree}
              />
            </Row>
          ) : null}
          {address.province ? (
            <Row modifier="between-xs">
              <Text
                className="invoice_header-content__address-key"
                content=""
              />
              <Text
                className="invoice_header-content__address-value invoice_header-content__address-line"
                content={address.province}
              />
            </Row>
          ) : null}
          <Row modifier="between-xs">
            <Text className="invoice_header-content__address-key" content="" />
            <Text
              className="invoice_header-content__address-value invoice_header-content__address-line"
              content={address.town}
            />
          </Row>
          <Row modifier="between-xs">
            <Text className="invoice_header-content__address-key" content="" />
            <Text
              className="invoice_header-content__address-value invoice_header-content__address-line"
              content={address.postcode}
            />
          </Row>

          <Row
            className="invoice_header-content__address-vat"
            modifier="between-xs"
          >
            <Text
              className="invoice_header-content__address-key"
              content="Vat Number"
            />
            <Text
              className="invoice_header-content__address-value"
              content={vatNumber}
            />
          </Row>
          <Row
            className="invoice_header-content__address-date"
            modifier="between-xs"
          >
            <Text
              className="invoice_header-content__address-key"
              content="Invoice Date"
            />
            <Text
              className="invoice_header-content__address-value"
              content={invoiceDate}
            />
          </Row>
        </div>
      </Row>
    </section>
  );
};

InvoiceHeader.propTypes = {
  invoiceNumber: PropTypes.string.isRequired,
  amountDue: PropTypes.string.isRequired,
  taxDate: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  vatNumber: PropTypes.string.isRequired,
  invoiceDate: PropTypes.string.isRequired,
  address: PropTypes.shape({}).isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string
};

InvoiceHeader.defaultProps = {
  subtitle: '',
  title: ''
};

export default InvoiceHeader;
