import React, { Fragment } from 'react';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import { getCurrentCompanyId } from 'reducers/selectors';
import { Iframe } from 'components';

const Performance = ({ currentCompanyId }) => {
  return (
    <Fragment>
      <Helmet>
        <title>Historical Performance</title>
      </Helmet>
      <Iframe
        url={`${process.env.REACT_APP_SIDEBARLESS_PORTAL_BASE_URL}/#/performance/overview?iframe=true`}
        title="Historical Performance"
        companyId={currentCompanyId}
      />
    </Fragment>
  );
};

const mapStateToProps = state => {
  const currentCompanyId = getCurrentCompanyId(state);
  return {
    currentCompanyId
  };
};

export default connect(mapStateToProps, null)(Performance);
